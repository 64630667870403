import {
    USER_SCREEN_VALIDATE_SUCCESS,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    MAIN_SCREEN_GET_FORM,
    MAIN_SCREEN_GET_ORDER,
} from '../types';
import { validUserType, validEnergyTypes } from '../../utils/helpers';

export const initialState = {
    userType: '',
    energyTypes: [],
};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case MAIN_SCREEN_GET_ORDER: {
            return {
                ...state,
                ...initialState,
            };
        }
        case USER_SCREEN_VALIDATE_SUCCESS:
            return {
                ...state,
                userType: action.payload.userType,
                energyTypes: action.payload.energyTypes,
            };
        case MAIN_SCREEN_GET_ORDER_SUCCESS:
            const { order } = action.payload;
            return {
                ...state,
                userType: order.customer.type,
                energyTypes: order.contracts.map(a => a.energy),
            };
        case MAIN_SCREEN_GET_FORM: {
            const { customerType, energyTypes } = action.payload;
            if (
                !validUserType(customerType) ||
                !validEnergyTypes(energyTypes)
            ) {
                return {
                    ...state,
                };
            }
            return {
                ...state,
                userType: customerType || state.userType,
                energyTypes:
                    energyTypes && energyTypes.length > 0
                        ? energyTypes
                        : state.energyTypes,
            };
        }

        default:
            return state;
    }
}

export default userReducer;
