import React from 'react';
import Typography from '../../components/Typography/Typography';

const style = (isChecked, width) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        border: `4px solid ${isChecked ? '#FFF523' : '#FFF0'}`,
        borderRadius: '12px',
        gap: '8px',
    },
    image: {
        width,
    },
});

function SurveySelectorStyled({ imageSrc, text, isChecked, width = '130px' }) {
    const styles = style(isChecked, width);
    return (
        <div style={styles.container}>
            <div />
            <img style={styles.image} alt={text} src={imageSrc} />
            <Typography>{text}</Typography>
        </div>
    );
}

export default SurveySelectorStyled;
