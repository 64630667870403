import React, { Component } from 'react';
import { connect } from 'react-redux';

import Screen from '../screen';

import WordingData from '../../utils/wording.json';
import PaymentModal from '../../containers/PaymentModal/PaymentModal';

import * as actionsCB from '../../containers/PaymentModal/PaymentCBModal.actions';
import resources from '../../utils/resources';
import { CustomButton } from '../../components';
import { TagLogger, log } from '../../utils/logger';
import SectionLayout from '../../components/SectionLayout/SectionLayout';

const Wording = WordingData.FailureCB;

class FailureCBScreen extends Component {
    static navigationOptions = {
        previousScreen: null,
        buttonNextTitle: '',
        buttonPreviousTitle: '',
        title: Wording.title,
        screenId: Screen.FAILURE_CB,
        showSaveButton: false,
        step: 4,
        validate: () => true,
    };

    componentDidMount = () => {
        log({
            tag: TagLogger.RETRY_PAYMENT,
            returnCode: '200',
            logMessage: null,
            serviceCalled: null,
            statusAction: 'SUCCESS',
            triggerButton: 'auto-loading',
        });
    };

    handleFinalized = () => {
        // const { redirectFinalScreenDispatch } = this.props;
        // redirectFinalScreenDispatch();
        const { setStepById } = this.props;
        setStepById('FINAL_SCREEN');
    };

    handleRecallCB = () => {
        const {
            paymentState: { reference, currentOrder, externalReference },
            recallPaymentCBDispatch,
        } = this.props;

        const prepareOrder = {
            ...currentOrder,
            externalReference,
            orderReference: reference,
        };
        recallPaymentCBDispatch({ order: { ...prepareOrder } });
    };

    render() {
        const {
            paymentState: { failureCBMessage },
            mainState: { loading },
            setStepById,
        } = this.props;
        return (
            <SectionLayout>
                <div className="failure-cb-screen">
                    <div>
                        <img src={resources['ic-erreur']} alt="ic-erreur" />
                        <div className="failure-cb-screen__failure">
                            <span>{Wording.CB_failure}</span>
                        </div>
                        {failureCBMessage && (
                            <div className="failure-message-container">
                                <p className="failure-message">
                                    {failureCBMessage}
                                </p>
                            </div>
                        )}{' '}
                        <div className="failure-cb-screen__att">
                            {Wording.attention}
                        </div>
                    </div>

                    <div className="btn">
                        <CustomButton
                            color="orange"
                            title={Wording.btn_reload}
                            loading={loading}
                            onClick={this.handleRecallCB}
                        />
                        <div>
                            <CustomButton
                                color="gray"
                                title={Wording.btn_finalized}
                                onClick={this.handleFinalized}
                            />
                        </div>
                    </div>
                    <PaymentModal setStepById={setStepById} />
                </div>

                <div className="failure-cb-screen__footer" />
            </SectionLayout>
        );
    }
}

const mapStateToProps = state => ({
    paymentState: state.paymentReducer,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    recallPaymentCBDispatch: payload =>
        dispatch(actionsCB.recallPaymentCB(payload)),
    redirectFinalScreenDispatch: () =>
        dispatch(actionsCB.redirectFinalScreen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FailureCBScreen);
