import React from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import WordingOption from '../../utils/wording.json';

const Wording = WordingOption.FinalSummaryScreen;

class EnergyGauge extends React.Component {
    constructor(props) {
        super(props);

        const { initial } = props;
        this.state = {
            progress: initial,
        };
    }

    renderProgressBar(title, idx) {
        const idxConso = idx + 2;
        const { progress } = this.state;
        const { ranges } = this.props;
        const height = ranges[progress][idx];
        const conso = ranges[progress][idxConso];
        // const style = {
        //     height: `${((maxValue - height) * 100) / maxValue}%`,
        // };
        return (
            <div className="progress">
                <div className="progress__bar" />
                <div className="progress__label">
                    <span className="progress__label__value">{title}</span>
                    <span className="progress__label__value">{conso}</span>
                    <span className="progress__label__value">kWh</span>
                    <span style={{ fontSize: '11px', marginTop: '10px' }}>
                        Prépaiement de
                    </span>
                    <span className="progress__label__value">
                        {height} €/mois
                    </span>
                </div>
            </div>
        );
    }

    renderRangeSlider() {
        const { progress } = this.state;
        const { ranges, onChange } = this.props;
        return (
            <InputRange
                formatLabel={() => ''}
                maxValue={ranges.length - 1}
                minValue={0}
                step={1}
                value={progress}
                onChange={value => {
                    onChange(ranges[value]);
                    this.setState({
                        progress: value,
                    });
                }}
            />
        );
    }

    render() {
        const { ranges } = this.props;
        if (!ranges || ranges.length < 2) {
            return <div className="energy-gauge" />;
        }
        return (
            <div className="energy-gauge">
                <div className="energy-gauge__progress">
                    {this.renderProgressBar(Wording.plan.gauge.summer, 0)}
                    <div className="energy-gauge__container">
                        {this.renderRangeSlider()}
                        <p className="energy-gauge__subtitle">
                            Bouge le curseur pour moduler comme tu le souhaites
                            !
                        </p>
                    </div>
                    {this.renderProgressBar(Wording.plan.gauge.winter, 1)}
                </div>
            </div>
        );
    }
}

export default EnergyGauge;
