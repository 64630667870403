import React, { createRef } from 'react';
import { connect } from 'react-redux';

import SearchService from '../../utils/search';

import SurveyContainer from '../../containers/SurveyContainer';
import * as actions from './SurveyScreen.actions';
import Screen from '../screen';
import { areListEqual } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';
import {
    TagLogger /* , TriggerButtonLogger */,
    TriggerButtonLogger,
} from '../../utils/logger';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import { scrollToRefDelay } from '../../utils/scroll';
import { CustomButton } from '../../components';

const Wording = WordingConstant.SurveyScreen;

export class SurveyScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.PACKAGE,
        previousScreen: Screen.CODE,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.SURVEY,
        showSaveButton: false,
        step: 3,
        validate: () => {},
        disabled: () => {},
    };

    constructor(props) {
        super(props);

        const {
            surveyState,
            dataState: {
                common: { firstAddress },
            },
            summaryState: { contracts },
        } = props;

        this.myRef = createRef();
        this.state = {
            fields: {
                ...surveyState,
                city:
                    surveyState.city === ''
                        ? firstAddress.city
                        : surveyState.city,
                postalCode:
                    surveyState.postalCode === ''
                        ? firstAddress.postalCode
                        : surveyState.postalCode,
            },
            setting: {
                postalCodes: [],
                codeText: '',
                showError: false,
                currentContract: contracts.find(c => c.energy === 'EL'),
            },
        };

        this.searchService = new SearchService(TagLogger.SURVEY);
    }

    componentDidMount() {
        this.searchService.getResults().subscribe(res => {
            const { setting } = this.state;
            if (res.length > 0) {
                setting.postalCodes = res.map(dt => ({
                    id: dt.code,
                    label: `${dt.code} - - - ${dt.city}`,
                    value: `${dt.code}__${dt.city}`,
                    codeValue: dt.code,
                    cityValue: dt.city,
                    netAreaValue: dt.netArea,
                }));
            } else {
                setting.postalCodes = [];
            }
            setting.isLoadingPC = false;
            this.setState({ setting });
        });
        SurveyScreen.navigationOptions.validate = () => {
            const {
                fields,
                setting: { currentContract },
            } = this.state;

            if (
                currentContract &&
                Object.prototype.hasOwnProperty.call(
                    currentContract,
                    'rateOption'
                )
            ) {
                fields.priceOption = currentContract.rateOption;
            }
        };

        // SurveyScreen.navigationOptions.disabled = () => {
        //     const { setting } = this.state;
        //     setting.showError = true;
        //     this.setState({ setting });
        // };

        const { surveyState, isCurrentScreen } = this.props;
        this.handleCondition(surveyState);
        if (isCurrentScreen) scrollToRefDelay(this.myRef, 100);
    }

    componentDidUpdate() {
        const { fields } = this.state;
        this.handleCondition(fields);
    }

    componentWillUnmount() {
        this.searchService.unsubscribe();
    }

    handleCondition = ({
        postalCode,
        city,
        housingType,
        residenceType,
        heatingType,
        priceOption,
        area,
        inhabitantsNumber,
    }) => {
        let condition =
            postalCode !== '' &&
            city !== '' &&
            housingType !== '' &&
            residenceType !== '' &&
            heatingType !== '' &&
            area !== '' &&
            inhabitantsNumber !== '';
        const {
            setCanGoNext,
            userState: { energyTypes },
        } = this.props;

        const {
            setting: { currentContract },
        } = this.state;

        if (
            !areListEqual(energyTypes, ['NG']) &&
            currentContract &&
            !Object.prototype.hasOwnProperty.call(currentContract, 'rateOption')
        ) {
            condition = condition && priceOption !== '';
        }

        if (setCanGoNext) {
            if (condition) setCanGoNext(true);
            else setCanGoNext(false);
        }

        return condition;
    };

    handleInputChangeAddressContainer = value => {
        const { setting } = this.state;
        setting.codeText = value.replace(/[^\d ]/g, '');
        setting.isLoadingPC = true;
        const val = value.length > 5 ? value.substring(0, 4) : value;
        this.searchService.search(val);
        this.setState({ setting });
    };

    onFocusDropdown = () => {
        const { setting, fields } = this.state;
        if (fields.postalCode !== undefined) {
            setting.codeText = fields.postalCode.toString();
            this.setState({ setting });
        }
    };

    handleChangeDropdownSelect = object => {
        const { fields, setting } = this.state;
        const { codeValue, netAreaValue, cityValue } = object;
        fields.city = cityValue;
        fields.postalCode = codeValue;
        fields.netArea = netAreaValue;
        if (codeValue !== undefined) setting.codeText = codeValue.toString();
        setting.isLoadingPC = false;
        setting.postalCodes = [];
        this.setState({ fields, setting });
    };

    handleInputCity = e => {
        const { fields } = this.state;
        const { value } = e.target;
        fields.city = value;
        this.setState({ fields });
    };

    handleToggle = (value, type) => {
        const { fields } = this.state;
        if (fields[type] !== value) {
            fields[type] = value;
            this.setState({ fields });
        }
    };

    handleSelect = (selectedOption, type) => {
        const { fields } = this.state;
        if (selectedOption && fields[type] !== selectedOption.value) {
            fields[type] = selectedOption.value;
            this.setState({ fields });
        }
    };

    validate = () => {
        const {
            nextScreen,
            userState,
            validateSurveyScreenDispatch,
            packageState,
        } = this.props;
        const { fields } = this.state;
        validateSurveyScreenDispatch({
            ...fields,
            ...userState,
            missingEnergy: packageState.missingEnergy,
            logger: {
                tag: TagLogger.SURVEY,
                triggerButton: TriggerButtonLogger.VALIDATE_BTN,
            },
        });
        nextScreen();
    };

    render() {
        const { fields, setting } = this.state;
        const {
            userState: { energyTypes },
            canGoNext,
            loading,
            isCurrentScreen,
        } = this.props;
        const showPriceOption =
            areListEqual(energyTypes, ['NG']) ||
            (setting.currentContract &&
                Object.prototype.hasOwnProperty.call(
                    setting.currentContract,
                    'rateOption'
                ));
        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout ref={this.myRef} step="Simulation">
                    <div className="survey-screen">
                        <SurveyContainer
                            setting={setting}
                            fields={fields}
                            condition={this.handleCondition}
                            handleInputCity={this.handleInputCity}
                            handleToggle={this.handleToggle}
                            handleSelect={this.handleSelect}
                            handleChangeDropdownSelect={
                                this.handleChangeDropdownSelect
                            }
                            handleInputChangeAddressContainer={
                                this.handleInputChangeAddressContainer
                            }
                            onFocusDropdown={this.onFocusDropdown}
                            showPriceOption={!showPriceOption}
                        />
                    </div>
                    {isCurrentScreen && (
                        <div className="validate-button-top-space">
                            <CustomButton
                                disabledBackgroundColor="#f3f3f3"
                                title="Valider"
                                color="orange"
                                disabled={!canGoNext}
                                onClick={this.validate}
                                loading={loading}
                            />
                        </div>
                    )}
                </SectionLayout>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    surveyState: state.surveyReducer,
    packageState: state.packageReducer,
    summaryState: state.summaryReducer,
    dataState: state.dataReducer,
});

const mapDispatchToProps = dispatch => ({
    validateSurveyScreenDispatch: payload =>
        dispatch(actions.validateSurveyScreen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyScreen);
