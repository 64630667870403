import React, { useEffect, useState } from 'react';
import icArrowDown from '../../assets/icons/ic-arrow-down.svg';
import Typography from '../Typography/Typography';
import { FontWeightVariant } from '../../constants/enums';
import HelpIcon from '../../assets/icons/ic-help.svg';
import './_CustomAccordion.scss';

function CustomAccordion({
    defaultValue = false,
    title,
    children,
    value,
    onChange,
    disabled = false,
}) {
    const [isAccordionOpen, setIsAccordionOpen] = useState(defaultValue);

    const handleChangeState = () => {
        if (value !== undefined && onChange) onChange(!value);
        else setIsAccordionOpen(old => !old);
    };

    useEffect(() => {
        setIsAccordionOpen(value);
    }, [value]);

    const controlledValue = value === undefined ? isAccordionOpen : value;
    return (
        <div
            className={`accordion-root ${
                disabled ? 'accordion-root-disabled' : ''
            }`}
        >
            <button
                className="accordion-root__summary"
                onClick={handleChangeState}
                type="button"
                disabled={disabled}
            >
                <div className="accordion-root__summary__container">
                    <img
                        src={HelpIcon}
                        alt="Help indicator"
                        className="accordion-root__summary__container__image"
                    />
                    <Typography
                        className="accordion-root__summary__container__title"
                        fontWeight={FontWeightVariant[500]}
                    >
                        {title}
                    </Typography>
                </div>
                <img
                    alt="Accordion arrow indicator"
                    className={`accordion-root__summary__arrow-${
                        controlledValue ? 'open' : 'close'
                    }`}
                    src={icArrowDown}
                />
            </button>
            <div
                className={`accordion-root__details-${
                    controlledValue ? 'open' : 'close'
                }`}
            >
                {children}
            </div>
        </div>
    );
}

export default CustomAccordion;
