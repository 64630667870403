import React, { useEffect, useState } from 'react';
import CheckIcon from '../../assets/icons/ic-check.svg';

export const CheckBoxVariant = Object.freeze({
    V1: 'V1',
    V2: 'V2',
});

const CustomCheckbox = ({ variant, ...rest }) => {
    switch (variant) {
        case CheckBoxVariant.V1:
            return <CustomCheckboxV1 {...rest} />;
        case CheckBoxVariant.V2:
            return <CustomCheckboxV2 {...rest} />;
        default:
            return <CustomCheckboxV1 {...rest} />;
    }
};

const CustomCheckboxV1 = ({
    text,
    id,
    checked,
    handleCheck,
    imageSrc,
    link,
    img,
}) => (
    <label
        htmlFor={id}
        className={`custom-checkbox ${
            imageSrc ? 'custom-checkbox__label' : ''
        }`}
    >
        <input
            type="checkbox"
            id={id}
            checked={checked}
            onChange={handleCheck}
            value={text}
        />

        <div className="custom-checkbox__label__content">
            <p className="custom-checkbox__label__content__text">{text}</p>
            <div>
                <a
                    className="custom-checkbox__label__content__link"
                    href="https://www.wekiwi.fr/tarifs-et-cgv-wekiwi"
                    target="_blanc"
                >
                    {img}
                    {link}
                </a>
            </div>
        </div>
    </label>
);

const CustomCheckboxV2 = ({
    children,
    onChange,
    id,
    defaultValue = false,
    setValueOnMount = false,
}) => {
    const [isChecked, setIsChecked] = useState(defaultValue);

    const handleChange = () => {
        setIsChecked(!isChecked);
        if (onChange) onChange(!isChecked);
    };

    useEffect(() => {
        if (setValueOnMount) onChange(isChecked);
    }, []);

    return (
        <div className="custom-checkbox-v2">
            <button
                onClick={handleChange}
                id={`input-custom-${id}`}
                type="button"
                className={`custom-checkbox-v2__input ${
                    isChecked ? 'checked-input' : ''
                }`}
            >
                <img alt="check icon" src={CheckIcon} />
            </button>
            <label htmlFor={`input-custom-${id}`}>{children}</label>
        </div>
    );
};

export default CustomCheckbox;
