import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import config from './config';
import { log as consoleLog } from './helpers';
import Wording from './wording.json';

const controlData = (dt, slDt) => {
    if (dt === null) return undefined;
    if (dt !== undefined) return dt;
    if (slDt !== undefined) return slDt;

    return undefined;
};

// lol // TODO

const TagLogger = Object.freeze({
    USER: 'UserScreen',
    CODE: 'CodeScreen',
    SURVEY: 'SurveyScreen',
    PACKAGE: 'PackageScreen',
    SUMMARY: 'SummaryScreen',
    DATA: 'DataScreen',
    FINALSUMMARY: 'FinalSummaryScreen',
    FINALCODE: 'FinalCodeScreen',
    SAVE: 'SaveScreen',
    SIGNING: 'SigningScreen',
    PAYMENT: 'PaymentScreen',
    CONVERSION: 'ConversionScreen',
    RETRY_PAYMENT: 'RetryPaymentScreen',
});

const TriggerButtonLogger = Object.freeze({
    VALIDATE_BTN: 'validate-button',
    AFTER_SIGN: 'after-sign-order',
    AUTO_LOAD: 'auto-load',
    FIXED_PRICE: 'fixed-price',
    SAVE_BTN: 'save-button',
    RETRY_PAYMENT_BTN: 'retry-payment-button',
});

const prepareObject = props => {
    const {
        tag,
        customerType,
        triggerButton,
        serviceCalled,
        statusAction,
        logMessage,
        returnCode,
        energyList,
        customerIdentificationKey,
    } = props;
    const dataLS = localStorage.getItem('log_streaming');
    const localStorageData = dataLS ? JSON.parse(dataLS) : {};

    const prepareData = {
        tag: controlData(tag, localStorageData.tag),
        customerType: controlData(customerType, localStorageData.customerType),
        triggerButton: controlData(
            triggerButton,
            localStorageData.triggerButton
        ),
        serviceCalled: controlData(
            serviceCalled,
            localStorageData.serviceCalled
        ),
        logMessage: controlData(logMessage, localStorageData.logMessage),
        returnCode: controlData(returnCode, localStorageData.returnCode),
        statusAction: controlData(statusAction, localStorageData.statusAction),

        energyList: controlData(energyList, localStorageData.energyList),

        customerIdentificationKey: controlData(
            customerIdentificationKey,
            localStorageData.customerIdentificationKey
        ),

        environnement: config.ENV,
        timestamp: Date.now().toString(),
        app: 'SUBSCRIPTION',
        channel: 'WEB',
    };

    const channel = localStorage.getItem('channel');
    if (channel) {
        prepareData.channel = channel;
    }

    return prepareData;
};

const log = ({
    tag,
    customerType,
    triggerButton,
    serviceCalled,
    statusAction,
    logMessage,
    returnCode,
    energyList,
    customerIdentificationKey,
}) => {
    const prepareData = prepareObject({
        tag,
        customerType,
        triggerButton,
        serviceCalled,
        statusAction,
        logMessage,
        returnCode,
        energyList,
        customerIdentificationKey,
    });

    const payload = {
        jsonLog: JSON.stringify(prepareData),
    };

    localStorage.setItem('log_streaming', payload.jsonLog);

    const settings = {
        method: 'POST',
        url: `${config.URL}/getLogsStreaming`,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': config.ApiKey,
        },
        responseType: 'json',
        crossDomain: true,
        body: payload,
    };

    // consoleLog(`===> Prepare Object /getLogsStreaming  => `, prepareData);
    // consoleLog(`FO Data: POST /getLogsStreaming ====> `, payload);

    const call = ajax(settings).pipe(
        catchError(({ message, response, status }) => {
            const error = {
                ajaxError: message,
                code: status,
                message: Wording.Common.errorAjax,
            };
            if (response) {
                error.code = response.code;
                error.message = response.message;
            }

            return throwError(error);
        }),
        map(
            ({ response }) =>
                // consoleLog(`BO Response: POST /getLogsStreaming ====> `, response);
                response
        )
    );

    const subscribe = call.subscribe(
        err =>
            err && consoleLog(`Error from API /getLogsStreaming ====> `, err),
        res =>
            res && consoleLog(`BO Response: POST /getLogsStreaming ====> `, res)
    );

    return subscribe;
};

export { log, TagLogger, TriggerButtonLogger };
