import React from 'react';

import DashLaneIcon from '../../assets/icons/ic-dash-lane.svg';
import Typography from '../Typography/Typography';
import { FontSizeVariant } from '../../constants/enums';

import './_DividerDashOr.scss';

function DividerDashOr() {
    return (
        <div className="ordivider">
            <img
                className="ordivider__dashlane-img"
                src={DashLaneIcon}
                alt="dash lane"
            />
            <Typography fontSize={FontSizeVariant.TITLE}>ou</Typography>
            <img
                className="ordivider__dashlane-img"
                src={DashLaneIcon}
                alt="dash lane"
            />
        </div>
    );
}

export default DividerDashOr;
