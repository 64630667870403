import React from 'react';

import resources from '../../utils/resources';
import CustomToggleV2 from '../CustomToggle/CustomToggleV2';

import WordingConstant from '../../utils/wording.json';

const CommonWording = WordingConstant.Common;

const OptionElement = ({
    options,
    currentAdditionalOptions,
    handleToggleAdditionalOptions,
    idToggle,
}) => {
    const getIconByCode = code => {
        switch (code) {
            case 'SAVE_TREE':
                return resources['ic-tree'];
            case 'GREEN':
                return resources['ic-green-energy'];
            case 'SOCIAL_PLASTIC':
                return resources['ic-world'];

            default:
                return '';
        }
    };
    return (
        <div className="option-element">
            <div className="option-element__element">
                <div className="option-element__content">
                    {options &&
                        options.map((option, index) => (
                            <div
                                key={index}
                                className={`option-element__content__item ${currentAdditionalOptions &&
                                    currentAdditionalOptions.includes(
                                        option.label
                                    ) &&
                                    'option-element__content__item--active'}`}
                            >
                                <div className="option-element__content__item__title">
                                    {option.label}
                                </div>

                                <div className="option-element__content__item__body">
                                    {option.description && (
                                        <p className="option-element__content__item__description">
                                            {option.description}
                                        </p>
                                    )}
                                </div>

                                <div className="option-element__content__item__footer">
                                    <div className="option-element__content__item__image">
                                        <img
                                            src={getIconByCode(option.code)}
                                            alt="elec"
                                        />
                                    </div>
                                    <div className="option-element__content__item__price">
                                        {option.detail.price}{' '}
                                        {CommonWording.unit[option.detail.unit]}
                                    </div>
                                    <div className="option-element__content__item__toggle">
                                        <CustomToggleV2
                                            key={index}
                                            id={`idOption__${idToggle ||
                                                ''}__${index}`}
                                            text=""
                                            value={option.label}
                                            checked={
                                                currentAdditionalOptions &&
                                                currentAdditionalOptions.includes(
                                                    option.label
                                                )
                                            }
                                            handleCheck={
                                                handleToggleAdditionalOptions
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default OptionElement;
