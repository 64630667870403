import {
    DATA_SCREEN_VALIDATE_SUCCESS,
    DATA_SCREEN_VALIDATE_SUCCESS_FINAL,
    FETCH_CALENDAR_APPOINTMENT,
    FETCH_CALENDAR_APPOINTMENT_SUCCESS,
    CODE_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    DATA_SCREEN_SAVE_COMMON,
    SURVEY_SCREEN_LOAD,
    MAIN_SCREEN_GET_FORM,
    REDIRECT_TO_DATA_SCREEN_START_DATE,
    MAIN_SCREEN_GET_ORDER,
    MINIMAL_DATA_FORM,
    MINIMAL_DATA_VALIDATE_EMAIL,
    MINIMAL_DATA_RESET_EMAIL,
    SEND_EMAIL_CODE_REQUEST_FAIL,
    SEND_EMAIL_CODE_REQUEST_SUCCESS,
    SEND_EMAIL_CODE_REQUEST,
    VERIFY_EMAIL_CODE_REQUEST,
    VERIFY_EMAIL_CODE_REQUEST_FAIL,
    VERIFY_EMAIL_CODE_REQUEST_SUCCESS,
    LOG_USER_INFORMATION_REQUEST,
    LOG_USER_INFORMATION_FAIL,
    LOG_USER_INFORMATION_SUCCESS,
    GET_LOGGED_USER_INFO_REQUEST,
    GET_LOGGED_USER_INFO_REQUEST_FAIL,
    GET_LOGGED_USER_INFO_REQUEST_SUCCESS,
} from '../types';
import * as helpers from '../../utils/helpers';
import WordingConstant from '../../utils/wording';

const Wording = WordingConstant.DataScreen;
const UserWording = WordingConstant.UserScreen;

export const initialState = {
    common: {
        situation: '',
        civility: 'MR',
        name: '',
        surname: '',
        birthdate: '',
        email: '',
        tel: '',
        firstAddress: {
            postalCode: '',
            city: '',
            netArea: '',
        },
        address: {
            address: '',
            postalCode: '',
            city: '',
            netArea: '',
        },
        birthTown: {
            number: '',
            street: '',
            netArea: '',
            postalCode: '',
            townName: '',
            country: '',
        },
        newAddress: {
            address: null,
            postalCode: null,
            city: null,
            netArea: null,
        },
        newBillingAddress: {
            civility: 'MR',
            name: '',
            surname: '',
            address: '',
            postalCode: '',
            city: '',
            netArea: '',
        },
        dateContract: {
            EL: {
                date: null,
                month: null,
                year: null,
                express: false,
                firstPaymentCB: false,
                timeslot: '',
                timeslots: [],
                dateEffectiveStartDate: null,
                monthEffectiveStartDate: null,
                yearEffectiveStartDate: null,
            },
            NG: {
                date: null,
                month: null,
                firstPaymentCB: false,
                year: null,
                express: false,
                timeslot: '',
                timeslots: [],
                dateEffectiveStartDate: null,
                monthEffectiveStartDate: null,
                yearEffectiveStartDate: null,
            },
            switch: null,
        },
    },
    part: {
        coOwner: {
            civility: 'MR',
            firstName: '',
            lastName: '',
        },
    },
    pro: {
        socialReason: '',
        legalForm: '',
        APECode: '',
        SIRET: '',
    },
    calendarAppointments: [],
    modifiedAddress: false,
    firstPaymentCB: false,
    finance: undefined,
    emailValid: {
        isValid: false,
        lastEmailValidate: '',
        error: '',
        isCodeSent: undefined,
    },
    logingInformation: {
        accessToken: '',
        email: '',
        token: '',
        user: '',
        validated: false,
        isUserLogged: false,
        error: '',
        isUserExist: undefined,
    },
};

const recoverState = (state, order) => {
    const {
        customer: { contact, finance },
        contracts,
    } = order;
    // Remove +33 and add 0

    const tel = helpers.displayPhone(contact.phone);
    const recoverBirthdate = new Date(contact.birthdate).toDateString();
    const common = {
        tel,
        civility: contact.civility || '',
        name: contact.firstName || '',
        surname: contact.lastName || '',
        birthdate: recoverBirthdate || '',
        email: contact.email || '',
        birthTown: contact.birthTown || '',
    };
    const part = {
        coOwner: {
            civility: 'MR',
            firstName: '',
            lastName: '',
        },
    };

    const { billingContact } = finance;
    if (billingContact) {
        let billingAddress = billingContact.address.street || '';
        if (billingContact.address.number) {
            billingAddress = `${billingContact.address.number ||
                ''} ${billingAddress}`;
        }
        common.newBillingAddress = {
            civility: billingContact.civility || '',
            name: billingContact.firstName || '',
            surname: billingContact.lastName || '',
            address: billingAddress || '',
            postalCode: billingContact.address.postalCode || '',
            city: billingContact.address.townName || '',
            netArea: billingContact.address.netArea || '',
        };
    }
    if (contracts.length > 0) {
        common.situation = contracts[0].deliveryPoint.process.type || '';
        const thirdParty =
            (contracts[0].thirdParties &&
                contracts[0].thirdParties.find(tp => tp.role === 'COHOLDER')) ||
            {};
        part.coOwner = {
            civility: thirdParty.civility || 'MR',
            firstName: thirdParty.firstName || '',
            lastName: thirdParty.lastName || '',
        };
    }
    if (contact.address) {
        let address = contact.address.street || '';
        if (contact.address.number) {
            address = `${contact.address.number} ${address}`;
        }
        const modifiedAddress = {
            address,
            postalCode: contact.address.postalCode || '',
            netArea: contact.address.netArea || '',
            city: contact.address.townName || '',
        };
        common.address = modifiedAddress;
    }
    const pro = {
        socialReason: contact.companyName || '',
        legalForm: contact.companyType || '',
        APECode: contact.activityCode || '',
        SIRET: contact.identificationNumber || '',
    };
    if (contracts) {
        common.dateContract = { EL: {}, NG: {} };
        contracts.forEach(contract => {
            const effectiveStartDate = new Date(
                contract.effectiveStartDate
            )
            const date = new Date(
                contract.dueDate
                    ? contract.dueDate
                    : contract.effectiveStartDate
            );
            const timeslot =
                contract.deliveryPoint.process.appointmentTimeslot || '';
            const express = contract.deliveryPoint.process.express || false;
            const firstPaymentCB = contract.firstPaymentCB || false;
            common.dateContract[contract.energy] = {
                timeslot,
                firstPaymentCB,
                express,
                date: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
                dateEffectiveStartDate: effectiveStartDate.getDate(),
                monthEffectiveStartDate: effectiveStartDate.getMonth(),
                yearEffectiveStartDate: effectiveStartDate.getFullYear(),
            };
            if (
                common.situation ===
                Wording.situation.values.changeSupplier.value
            ) {
                common.dateContract.switch = Wording.contractDate.dropdownValues.find(
                    v => v.value === contract.effectiveStartRange
                );
            }
        });
    }
    const firstPaymentCB = ['EL', 'NG'].reduce((acc, current) => {
        const paymentCB = common.dateContract[current].firstPaymentCB;
        return acc || paymentCB;
    }, false);
    return {
        ...state,
        common: {
            ...state.common,
            ...common,
        },
        part,
        firstPaymentCB,
        pro,
        finance,
    };
};

const updateAddress = (state, address) => {
    if (!address) {
        return {
            ...state,
        };
    }
    let fullAddress = `${address.street || ''}`;
    if (address.number) {
        fullAddress = `${address.number} ${fullAddress}`;
    }
    const modifiedAddress = {
        address: fullAddress,
        postalCode: address.postalCode || '',
        city: address.townName || '',
        netArea: address.netArea,
    };

    return {
        ...state,
        common: {
            ...state.common,
            address: modifiedAddress,
        },
    };
};

function dataReducer(state = initialState, action) {
    switch (action.type) {
        case MAIN_SCREEN_GET_ORDER: {
            return {
                ...state,
                ...initialState,
            };
        }

        case MINIMAL_DATA_FORM:
            const data = action.payload;
            return {
                ...state,
                common: {
                    ...state.common,
                    ...data,
                },
            };

        case MINIMAL_DATA_RESET_EMAIL:
            return {
                ...state,
                emailValid: initialState.emailValid,
                logingInformation: initialState.logingInformation,
            };

        case SEND_EMAIL_CODE_REQUEST:
            return {
                ...state,
                emailValid: {
                    ...state.emailValid,
                    isCodeSent: undefined,
                    isValid: false,
                },
            };
        case SEND_EMAIL_CODE_REQUEST_FAIL:
        case SEND_EMAIL_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                emailValid: {
                    ...state.emailValid,
                    ...action.payload,
                },
            };

        case GET_LOGGED_USER_INFO_REQUEST:
        case GET_LOGGED_USER_INFO_REQUEST_FAIL:
            return {
                ...state,
            };
        case GET_LOGGED_USER_INFO_REQUEST_SUCCESS:
            return {
                ...state,
                common: {
                    ...state.common,
                    civility: action.payload.contact.customerFullName.civility,
                    name: action.payload.contact.customerFullName.firstName,
                    surname: action.payload.contact.customerFullName.lastName,
                    tel: action.payload.contact.phone,
                },
                pro: {
                    socialReason: action.payload.contact.companyName,
                    legalForm: action.payload.contact.companyType,
                    APECode: action.payload.contact.activityCode,
                    SIRET: action.payload.contact.identificationNumber,
                },
            };

        case VERIFY_EMAIL_CODE_REQUEST:
            return {
                ...state,
                emailValid: {
                    ...state.emailValid,
                    ...action.payload,
                    isValid: false,
                    error: '',
                },
            };
        case VERIFY_EMAIL_CODE_REQUEST_FAIL:
        case VERIFY_EMAIL_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                emailValid: {
                    ...state.emailValid,
                    ...action.payload,
                },
            };

        case MINIMAL_DATA_VALIDATE_EMAIL:
            return {
                ...state,
                emailValid: {
                    ...initialState.emailValid,
                },
            };

        case LOG_USER_INFORMATION_REQUEST:
            return {
                ...state,
                logingInformation: initialState.logingInformation,
            };
        case LOG_USER_INFORMATION_SUCCESS:
        case LOG_USER_INFORMATION_FAIL:
            return {
                ...state,
                logingInformation: {
                    ...state.logingInformation,
                    ...action.payload,
                },
            };

        case DATA_SCREEN_VALIDATE_SUCCESS:
        case DATA_SCREEN_VALIDATE_SUCCESS_FINAL:
            // const { dateContract } = action.payload.common;
            const firstPaymentCB = false;

            return {
                ...state,
                firstPaymentCB,
                common: action.payload.common,
                part: action.payload.part,
                pro: action.payload.pro,
            };
        case FETCH_CALENDAR_APPOINTMENT:
            return {
                ...state,
            };
        case FETCH_CALENDAR_APPOINTMENT_SUCCESS:
            return {
                ...state,
                calendarAppointments: [...action.payload],
            };
        case SURVEY_SCREEN_LOAD: {
            const { contracts } = action.payload;
            const { address } = contracts[0].deliveryPoint;
            return updateAddress(
                {
                    ...state,
                    modifiedAddress: true,
                },
                address
            );
        }

        case REDIRECT_TO_DATA_SCREEN_START_DATE: {
            return {
                ...state,
                common: {
                    ...state.common,
                    dateContract: initialState.common.dateContract,
                },
            };
        }

        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            const { contracts } = action.payload;
            const { address } = contracts[0].deliveryPoint;
            if (state.modifiedAddress) {
                return {
                    ...state,
                    modifiedAddress: false,
                };
            }
            return updateAddress(state, address);
        }
        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS:
        case CODE_SCREEN_VALIDATE_SUCCESS: {
            const { contracts } = action.payload;
            const { address } = contracts[0].deliveryPoint;
            return updateAddress(state, address);
        }
        case MAIN_SCREEN_GET_ORDER_SUCCESS: {
            const { order } = action.payload;
            return recoverState(state, order);
        }
        case DATA_SCREEN_SAVE_COMMON: {
            return {
                ...state,
                common: {
                    ...state.common,
                    ...action.payload,
                },
            };
        }
        case MAIN_SCREEN_GET_FORM: {
            const {
                customerType,
                civility,
                firstName,
                lastName,
                email,
                phone,
                birthdate,
                companyName,
                companyType,
                identificationNumber,
                activityCode,
                coholderCivility,
                coholderFirstName,
                coholderLastName,
            } = action.payload;
            const correctCivility = helpers.validCivility(civility)
                ? civility
                : state.common.civility;
            const common = {
                ...state.common,
                civility: correctCivility,
                name: firstName || state.common.name,
                surname: lastName || state.common.surname,
                email: email || state.common.email,
                tel: phone || state.common.tel,
                birthdate: birthdate || state.common.birthdate,
            };
            if (customerType === UserWording.userType.individual) {
                const correctCoholderCivility = helpers.validCivility(
                    coholderCivility
                )
                    ? coholderCivility
                    : state.part.coOwner.civility;
                const part = {
                    ...state.part,
                    coOwner: {
                        civility: correctCoholderCivility,
                        firstName:
                            coholderFirstName || state.part.coOwner.firstName,
                        lastName:
                            coholderLastName || state.part.coOwner.lastName,
                    },
                };
                return {
                    ...state,
                    common,
                    part,
                };
            }
            if (customerType === UserWording.userType.professional) {
                const legalForm = helpers.validCompanyType(companyType)
                    ? companyType
                    : state.pro.legalForm;
                const pro = {
                    ...state.pro,
                    legalForm,
                    socialReason: companyName || state.pro.socialReason,
                    SIRET: identificationNumber || state.pro.APECode,
                    APECode: activityCode || state.pro.SIRET,
                };

                return {
                    ...state,
                    common,
                    pro,
                };
            }
            return {
                ...state,
                common,
            };
        }
        default:
            return state;
    }
}

export default dataReducer;
