const Screen = Object.freeze({
    USER: 'UserScreen',
    CODE: 'CodeScreen',
    SURVEY: 'SurveyScreen',
    PACKAGE: 'PackageScreen',
    SUMMARY: 'SummaryScreen',
    DATA: 'DataScreen',
    DATA_MIN: 'MinimalUserData',
    FINALSUMMARY: 'FinalSummaryScreen',
    VALIDATION: 'ValidationDialogScreen',
    SITUATION: 'SituationScreen',
    FAILURE_CB: 'FailureCBScreen',
    CANCEL_SLIMCOLLECT: 'CancelSCScreen',
    FAILURE_SLIMCOLLECT: 'FailureSCScreen',
    FINAL: 'FinalScreen',
    FINALPAYMENT: 'FinalPaymentScreen',
    SAVE: 'SaveScreen',
});

export default Screen;
