import React from 'react';
import { connect } from 'react-redux';

import accents from 'remove-accents';
import Screen from '../screen';
import SearchService from '../../utils/search';
import * as actions from './SituationScreen.actions';
import * as helpers from '../../utils/helpers';
import Config from '../../utils/config';
import { searchSiret, searchAppointmentTimeslot } from '../../utils/apiRequest';

import WordingConstant from '../../utils/wording.json';
import { renderValueDate } from '../../components/CustomDate/CustomDate';
import { TagLogger, TriggerButtonLogger } from '../../utils/logger';
import { logScreen } from '../../utils/firebase';
import { SituationTypes, BillingMode } from '../../constants/enums';
import SituationContainer from '../../containers/SituationContainer';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';
import CustomSelector from '../../components/CustomSelector/CustomSelector';
import { scrollToRefDelay } from '../../utils/scroll';
import { CustomButton } from '../../components';
import { addDays } from 'date-fns';


const Wording = WordingConstant.DataScreen;
const UserScreenWording = WordingConstant.UserScreen;

export class SituationScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.FINALSUMMARY,
        previousScreen: Screen.SUMMARY,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.DATA,
        step: 7,
        validate: () => {},
        disabled: () => {},
        returnButton: () => {},
        saveData: () => {},
    };

    constructor(props) {
        super(props);
        const {
            dataState,
            summaryState: { contracts },
        } = props;
        const { address } = contracts[0].deliveryPoint;
        let currentAddress;
        const { common, part } = dataState;
        if (address && address.address) {
            let fullAddress = `${address.street || ''}`;
            if (address.number) {
                fullAddress = `${address.number} ${fullAddress}`;
            }
            currentAddress = {
                address: fullAddress,
                postalCode: address.postalCode || '',
                city: address.townName || '',
                netArea: address.netArea,
            };
        } else {
            currentAddress = common.address;
        }
        const checkedNewAddress =
            common.newAddress &&
            (common.newAddress.address ||
                common.newAddress.postalCode ||
                common.newAddress.city);
        const checkedAddCoOwner =
            part.coOwner &&
            (part.coOwner.firstName !== '' || part.coOwner.lastName !== '');
        const checkedNewBillingAddress =
            common.newBillingAddress &&
            (common.newBillingAddress.name ||
                common.newBillingAddress.surname ||
                common.newBillingAddress.address ||
                common.newBillingAddress.postalCode ||
                common.newBillingAddress.city);

        this.state = {
            fields: {
                ...dataState,
                common: {
                    ...dataState.common,
                    address: currentAddress,
                },
            },
            setting: {
                checkedAddCoOwner,
                checkedNewAddress: checkedNewAddress !== null,
                checkedNewBillingAddress: checkedNewBillingAddress !== null,
                postalCodes: [],
                isLoadingPC: false,
                codeText: '',
                codeNewAddressText: '',
                codeNewBillingAddressText: '',
                codeBirthTown: '',
                showError: false,
                showEmailError: false,
                showBottomText: false,
                validSiret: null,
                isGoingNext: false,
            },
            getSwitchLater: false,
        };

        this.searchService = new SearchService(TagLogger.DATA);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        logScreen(TagLogger.DATA);

        this.initBirthCountry();

        const { setting, fields } = this.state;

        const {
            validateDataScreenDispatch,
            validateDataScreenFinalDispatch,
            userState: { energyTypes },
            codeState: { PDL, PCE },
            dataState,
            isCurrentScreen,
        } = this.props;
        this.searchService.getResults().subscribe(res => {
            if (res.length > 0) {
                setting.postalCodes = res.map(dt => ({
                    id: dt.code,
                    label: `${dt.code} - - - ${dt.city}`,
                    value: `${dt.code}__${dt.city}`,
                    codeValue: dt.code,
                    cityValue: dt.city,
                    netAreaValue: dt.netArea,
                }));
            } else {
                setting.postalCodes = [];
            }
            setting.isLoadingPC = false;
            this.setState({ setting });
        });

        SituationScreen.navigationOptions.validate = () => {
            const modifiedFields = {
                ...this.prepareField(fields),
                logger: {
                    tag: TagLogger.DATA,
                    triggerButton: TriggerButtonLogger.VALIDATE_BTN,
                },
            };
            const reducer = (accumulator, currentValue) =>
                accumulator &&
                (currentValue === 'EL' ? PDL.length === 0 : PCE.length === 0);
            const condition = energyTypes.reduce(reducer, true);
            if (condition) {
                return validateDataScreenFinalDispatch(modifiedFields);
            }
            return validateDataScreenDispatch(modifiedFields);
        };

        SituationScreen.navigationOptions.disabled = () => {
            setting.showError = true;
            this.setState({ setting });
        };

        SituationScreen.navigationOptions.returnButton = () => {
            const { dataScreenSaveCommonDispatch } = this.props;
            dataScreenSaveCommonDispatch({ ...fields.common });
        };

        SituationScreen.navigationOptions.saveData = () => {
            if (this.handleCondition()) {
                const { userState, summaryState, paymentState } = this.props;
                return {
                    ...userState,
                    ...fields,
                    ...summaryState,
                    ...paymentState,
                    logger: {
                        tag: TagLogger.DATA,
                        triggerButton: TriggerButtonLogger.SAVE_BTN,
                    },
                };
            }
            setting.showError = true;
            this.setState({ setting });
            return null;
        };

        this.handleCondition();

        const {
            userState,
            fetchCalendarAppointmentDispatch,
            summaryState,
        } = this.props;
        fetchCalendarAppointmentDispatch({
            ...userState,
            ...summaryState,
        });

        const { common } = dataState;

        energyTypes.forEach(energy => {
            if (common.dateContract[energy].timeslot) {
                const newDate = new Date(
                    common.dateContract[energy].year,
                    common.dateContract[energy].month,
                    common.dateContract[energy].date
                );
                this.sendRequestTimeslot(newDate, energy);
            }
        });

        if (fields.pro.SIRET) {
            const identificationNumber = fields.pro.SIRET.replace(
                /[^\d ]/g,
                ''
            );
            if (identificationNumber.length === 14) {
                this.searchSiretRequest(identificationNumber);
            }
        }
        if (isCurrentScreen) {
            scrollToRefDelay(this.myRef, 400);
        }
    }

    componentDidUpdate() {
        this.handleCondition();
    }

    componentWillUnmount() {
        this.searchService.unsubscribe();
    }

    prepareField = fields => {
        const {
            setting: {
                checkedAddCoOwner,
                checkedNewAddress,
                checkedNewBillingAddress,
            },
        } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        const modifiedFields = { ...fields, energyTypes };

        if (!checkedAddCoOwner) {
            modifiedFields.part = {
                coOwner: {
                    civility: 'MR',
                    firstName: '',
                    lastName: '',
                },
            };
        }
        if (!checkedNewAddress) {
            modifiedFields.common.newAddress = {
                address: null,
                postalCode: null,
                city: null,
                netArea: null,
            };
        }
        if (!checkedNewBillingAddress) {
            modifiedFields.common.newBillingAddress = {
                civility: 'MR',
                name: null,
                surname: null,
                address: null,
                postalCode: null,
                city: null,
                netArea: null,
            };
        }
        return modifiedFields;
    };

    initBirthCountry = () => {
        const { fields } = this.state;
        const params = helpers.searchParamsFromURL();

        let birthCountry = params.get('birthCountry');
        if (birthCountry) birthCountry = birthCountry.toUpperCase();

        const birthTown = params.get('birthTown');
        const postalCode = params.get('postalCode');

        if (birthCountry === 'FRANCE') {
            if (birthTown && postalCode && postalCode.length === 5) {
                fields.common.birthTown = {
                    ...fields.common.birthTown,
                    country: birthCountry,
                    townName: birthTown,
                    postalCode,
                };

                return this.setState({
                    fields,
                });
            }

            return null;
        }

        if (birthCountry && birthTown) {
            fields.common.birthTown = {
                ...fields.common.birthTown,
                country: birthCountry,
                townName: birthTown,
                postalCode: '',
            };

            return this.setState({
                fields,
            });
        }
        return null;
    };

    handleCondition = () => {
        const { setCanGoNext, userState } = this.props;
        // const { setting } = this.state;
        const condition =
            userState.userType === UserScreenWording.userType.professional
                ? this.handleConditionPro()
                : this.handleConditionPart();

        // if (condition && !setting.isGoingNext) {
        //     setting.isGoingNext = true;
        //     this.setState({ setting });
        //     nextScreen();
        // }

        if (setCanGoNext) {
            if (condition) setCanGoNext(true);
            else setCanGoNext(false);
            return condition;
        }
        return condition;
    };

    handleConditionPro = () => {
        const {
            fields,
            checkedNewAddress,
            checkedNewBillingAddress,
            setting,
        } = this.state;

        let condition = this.handleConditionSituation();
        condition = condition && this.handleConditionCommon(fields.common);
        condition =
            condition &&
            this.handleConditionDataPro(fields.pro, setting.validSiret);
        if (checkedNewAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newAddress);
        if (checkedNewBillingAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newBillingAddress);
        return condition;
    };

    handleConditionPart = () => {
        const {
            fields,
            setting: {
                checkedAddCoOwner,
                checkedNewAddress,
                checkedNewBillingAddress,
            },
        } = this.state;

        let condition = this.handleConditionSituation();

        condition =
            condition &&
            this.handleConditionCommon(fields.common) &&
            this.handleConditionAddress(fields.common.address);
        if (checkedAddCoOwner)
            condition =
                condition && this.handleConditionCoOwner(fields.part.coOwner);
        if (checkedNewAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newAddress);
        if (checkedNewBillingAddress)
            condition =
                condition &&
                this.handleConditionAddress(fields.common.newBillingAddress);
        return condition;
    };

    handleConditionSituation = () => {
        const {
            fields: {
                common: { dateContract },
            },
        } = this.state;
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;
        const isSwitch = dateContract.switch;

        const reducerSwitch = (accumulator, energy) =>
            accumulator && !summaryState[energy].cyclicBill
                ? dateContract[energy] &&
                  dateContract[energy].date !== null &&
                  dateContract[energy].month !== null &&
                  dateContract[energy].year !== null
                : accumulator;

        const reducer = (accumulator, energy) =>
            accumulator &&
            dateContract[energy] &&
            dateContract[energy].date !== null &&
            dateContract[energy].month !== null &&
            dateContract[energy].year !== null &&
            (!dateContract.switch
                ? dateContract[energy].timeslot !== ''
                : true);

        return energyTypes.reduce(isSwitch ? reducerSwitch : reducer, true);
    };

    handleConditionCoOwner = ({ firstName, lastName }) =>
        firstName !== '' && lastName !== '';

    handleConditionAddress = ({ address, city, postalCode }) =>
        address !== '' &&
        address !== null &&
        city !== '' &&
        city !== null &&
        postalCode !== '' &&
        postalCode !== null;

    handleConditionCommon = ({
        birthdate,
        birthTown,
        civility,
        name,
        surname,
        email,
        tel,
    }) => {
        const { dataState } = this.props;
        const { accessToken } = dataState.logingInformation;
        return (
            civility !== '' &&
            civility !== undefined &&
            name !== '' &&
            surname !== '' &&
            (accessToken ||
                (birthTown.country !== '' &&
                    birthTown.townName !== '' &&
                    (!(birthTown.country === 'FRANCE') ||
                        birthTown.postalCode !== '') &&
                    birthdate !== '' &&
                    !helpers.birthCondition(birthdate))) &&
            email !== '' &&
            helpers.handleConditionEmail(email) &&
            tel !== null &&
            helpers.handleConditionPhone(tel)
        );
    };

    handleConditionDataPro = (
        { socialReason, legalForm, APECode, SIRET },
        validSiret
    ) =>
        socialReason !== '' &&
        legalForm !== '' &&
        APECode.length !== '' &&
        SIRET.length === 14 &&
        validSiret === 200;

    handleEmailFormat = value => {
        const { setting } = this.state;
        const cleanValue = accents.remove(value);
        if (!helpers.handleConditionEmail(cleanValue)) {
            setting.showEmailError = true;
            this.setState({ setting });
            return cleanValue;
        }
        setting.showEmailError = false;
        this.setState({ setting });
        return cleanValue;
    };

    handleChangeInput = ({ target: { value } }, name, type) => {
        const { fields } = this.state;
        if (name === null) {
            switch (type) {
                case 'email':
                    fields.common.email = this.handleEmailFormat(value);
                    break;
                case 'surname':
                case 'name':
                    fields.common[type] = value.replace(/[0-9]/g, '');
                    break;
                case 'tel':
                    fields.common.tel = value.replace(/[^\d ]/g, '');
                    break;
                case 'SIRET': {
                    fields.pro.SIRET = value.replace(/[^\d ]/g, '');
                    if (fields.pro.SIRET.length === 14)
                        this.searchSiretRequest(value);
                    if (value === '') fields.pro.APECode = '';

                    break;
                }
                case 'APECode':
                    fields.pro.APECode = value;
                    break;
                case 'socialReason':
                    fields.pro.socialReason = value;
                    break;
                case 'coOwner':
                    fields.part.coOwner[type] = value;
                    break;
                default:
                    fields.common[type] = value;
                    break;
            }
        } else if (name === 'coOwner') {
            fields.part.coOwner[type] = value;
        } else {
            fields.common[name][type] = value;
        }
        this.setState({ fields });
    };

    handleDateChange = date => {
        const { fields } = this.state;
        if (date !== undefined) {
            const bDate = renderValueDate(date);
            fields.common.birthdate = bDate.toDateString();
            this.setState({ fields });
        }
    };

    handleChangeSelect = ({ value }, nameObj, type, ob) => {
        const { fields } = this.state;
        if (ob === undefined) fields[nameObj][type] = value;
        else if (type === 'birthTown' && ob === 'country') {
            fields[nameObj].birthTown.country = value;
            fields[nameObj].birthTown.postalCode = '';
            fields[nameObj].birthTown.townName = '';
            fields[nameObj].birthTown.netArea = '';
        } else fields[nameObj][type][ob] = value;
        this.setState({ fields });
    };

    handleInputChangeAddressContainer = (value, type) => {
        const { setting } = this.state;
        setting[type] = value.replace(/[^\d ]/g, '');
        if (value !== '') {
            setting.isLoadingPC = true;
        }

        const val = value.length > 5 ? value.substring(0, 4) : value;
        this.searchService.search(val);
        this.setState({ setting });
    };

    onFocusDropdown = (e, type) => {
        const { setting, fields } = this.state;
        if (type === 'address') {
            if (fields.common.address.postalCode !== '') {
                setting.codeText = fields.common.address.postalCode.toString();
            }
        } else if (type === 'newAddress') {
            if (fields.common.newAddress.postalCode) {
                setting.codeNewAddressText = fields.common.newAddress.postalCode.toString();
            }
        } else if (type === 'newBillingAddress') {
            if (fields.common.newBillingAddress.postalCode) {
                setting.codeNewBillingAddressText = fields.common.newBillingAddress.postalCode.toString();
            }
        } else if (type === 'birthTown') {
            if (fields.common.birthTown.postalCode) {
                setting.codeBirthTown = fields.common.birthTown.postalCode.toString();
            }
        }
        this.setState({ setting });
    };

    handleChangeDropdownSelect = (object, type) => {
        const { fields, setting } = this.state;
        const { codeValue, cityValue, netAreaValue } = object;
        if (type === 'address') {
            fields.common.address.city = cityValue;
            fields.common.address.postalCode = codeValue;
            fields.common.address.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        } else if (type === 'newAddress') {
            fields.common.newAddress.city = cityValue;
            fields.common.newAddress.postalCode = codeValue;
            fields.common.newAddress.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeNewAddressText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        } else if (type === 'newBillingAddress') {
            fields.common.newBillingAddress.city = cityValue;
            fields.common.newBillingAddress.postalCode = codeValue;
            fields.common.newBillingAddress.netArea = netAreaValue;
            if (codeValue !== undefined)
                setting.codeNewBillingAddressText = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        } else if (type === 'birthTown') {
            fields.common.birthTown.townName = cityValue;
            fields.common.birthTown.postalCode = codeValue;
            fields.common.birthTown.netArea = netAreaValue;
            fields.common.birthTown.country = 'FRANCE';
            if (codeValue !== undefined)
                setting.codeBirthTown = codeValue.toString();
            setting.isLoadingPC = false;
            setting.postalCodes = [];
            this.setState({ fields, setting });
        }
    };

    handleDisplayValue = (value, type) => {
        switch (type) {
            case 'tel':
            case 'SIRET':
                return value.replace(/[^\d ]/g, '');

            case 'name':
                return value.replace(/[0-9]/g, '');

            default:
                return value;
        }
    };

    handleToggle = ({ target: { checked } }, type) => {
        const { setting, fields } = this.state;
        if (type === 'checkedNewAddress') {
            setting.checkedNewAddress = setting.checkedNewAddress !== checked;
        } else {
            setting[type] = !setting[type];
        }
        this.setState({ setting, fields });
    };

    handleToggleSituation = (value, type) => {
        const { fields, setting } = this.state;
        const { resetScreenTo, isCurrentScreen } = this.props;
        setting.showBottomText = false;
        fields.common[type] = value;
        const defaultValue = {
            date: null,
            month: null,
            year: null,
            timeslot: '',
            timeslots: [],
            dateEffectiveStartDate: null,
            monthEffectiveStartDate: null,
            yearEffectiveStartDate: null,
        };
        fields.common.dateContract = {
            EL: { ...defaultValue },
            NG: { ...defaultValue },
            switch:
                value === SituationTypes.MOVE_IN
                    ? null
                    : fields.common.dateContract.switch,
        };
        if (value === SituationTypes.SWITCH) {
            const getSwitchLater = () => Wording.contractDate.dropdownValues[1];
            this.handleDropdownContract(getSwitchLater());
            this.setState({getSwitchLater: getSwitchLater()? true : false})
        }
        if (!isCurrentScreen) {
            setting.isGoingNext = false;
            this.setState({ setting });
            resetScreenTo();
        }
        this.setState({ fields, setting });
    };

    handleDatePicker = (date, type) => {
        const { fields, setting } = this.state;
        const { resetScreenTo, isCurrentScreen } = this.props;

        const {
            summaryState,
            dataState: { calendarAppointments },
        } = this.props;
        const day = Config.ChannelsDoorToDoor.includes(localStorage.getItem("channel")) ? 7 : 3;
        const featureDate = helpers.addBusinessDays(day);
        const newDate = new Date(date);
        const disabledSwitchDays = this.handleDateCalendarOption(
            type,
            'disabled'
        );
        let availableSwitchDate = true;
        disabledSwitchDays.forEach(elem => {
            if (
                (elem.before &&
                    newDate.getTime() < elem.before.getTime() &&
                    newDate.toLocaleDateString() !==
                        elem.before.toLocaleDateString()) ||
                (elem.after &&
                    newDate.getTime() > elem.after.getTime() &&
                    newDate.toLocaleDateString() !==
                        elem.after.toLocaleDateString()) ||
                (elem.daysOfWeek && elem.daysOfWeek.includes(newDate.getDay()))
            ) {
                availableSwitchDate = false;
            }
        });

        const isSwitch = fields.common.dateContract.switch;

        const daysList = (
            calendarAppointments.find(c => c.energy === type) || {
                daysList: [],
            }
        ).daysList;
        const formattedDate = newDate.toISOString().split('T')[0];
        const available = (
            daysList.find(d => d.date === formattedDate) || {
                available: false,
            }
        ).available;

        if (fields.common.situation === Wording.situation.values.moved.value)
            setting.showBottomText = newDate.getTime() <= featureDate.getTime();
        if (
            newDate.getTime() > new Date().getTime() &&
            ((!isSwitch && available) || (isSwitch && availableSwitchDate))
        ) {
            this.sendRequestTimeslot(newDate, type);
            fields.common.dateContract[type].date = newDate.getDate();
            fields.common.dateContract[type].year = newDate.getFullYear();
            fields.common.dateContract[type].month = newDate.getMonth();
            fields.common.dateContract[type].dateEffectiveStartDate = newDate.getDate();
            fields.common.dateContract[type].yearEffectiveStartDate = newDate.getFullYear();
            fields.common.dateContract[type].monthEffectiveStartDate = newDate.getMonth();

            const express = (
                daysList.find(d => d.date === formattedDate) || {
                    express: false,
                }
            ).express;

            const defaultCB = {
                firstPaymentCB: false,
            };
            const firstPaymentCB = (!isSwitch
                ? daysList.find(d => d.date === formattedDate) || defaultCB
                : defaultCB
            ).firstPaymentCB;

            fields.common.dateContract[type].express = express;
            fields.common.dateContract[type].firstPaymentCB = !summaryState[
                type
            ].cyclicBill
                ? firstPaymentCB
                : false;

            this.setState({ fields, setting });
            if (!isCurrentScreen) {
                setting.isGoingNext = false;
                this.setState({ setting });
                resetScreenTo();
            }
        }
    };

    sendRequestTimeslot = async (date, type) => {
        const { fields } = this.state;
        const ts = await searchAppointmentTimeslot(date, type);
        fields.common.dateContract[type].timeslots = ts.filter(
            t => t !== 'NONE'
        );
        this.setState({ fields });
    };

    searchSiretRequest = async value => {
        const { setting, fields } = this.state;
        const res = await searchSiret(value);
        if (res) {
            if (res.etablissement) {
                fields.pro.APECode = helpers.getAPEFromObject(res);
            }
            setting.validSiret = res.status;
            this.setState({ setting, fields });
        }
    };

    handleChangeTimeslotSelect = (value, type) => {
        const { fields } = this.state;
        fields.common.dateContract[type].timeslot = value;
        this.setState({ fields });
    };

    filterCalendarAppointmentResponse = (energyType, process) => {
        const { dataState } = this.props;
        const { calendarAppointments } = dataState;

        return calendarAppointments.filter(
            object => object.energy === energyType && object.process === process
        );
    };

    filterDateAppointment = ({ daysList: values }, type, condition) => {
        const arrSend = [];
        if (values !== undefined)
            values
                .filter(element =>
                    type === 'express'
                        ? element[type] === condition && element.available
                        : element[type] === condition
                )
                .forEach(val => arrSend.push(new Date(val.date)));
        if (type === 'available' && !condition) {
            const lastDay = values && new Date(values.slice(-1)[0].date);
            const today = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 1);
            if (Config.ChannelsDoorToDoor.includes(localStorage.getItem("channel"))) {
               addDays(tomorrow, 6)
            }
            arrSend.push({
                before: tomorrow,
                after: lastDay,
            });
        }
        return arrSend;
    };

    handleDateCalendarOption = (energyType, type) => {
        const { fields } = this.state;
        const { dateContract } = fields.common;
        const isSwitch = dateContract.switch;

        // render disabled day is situation === SWITCH
        if (isSwitch) {
            if (type === 'disabled') {
                const switchDropdownValues = (() => {
                    let day =
                        Config.DefaultSwitchDueDate[dateContract.switch.value][
                            energyType
                        ];
                        day = (dateContract.switch.value === "LATER" || dateContract.switch.value === "NOW") && Config.ChannelsDoorToDoor.includes(localStorage.getItem("channel")) ? day+=7 : day+=3;          
                    return helpers.addDays(new Date(), day);
                })();

                return [
                    {
                        before: switchDropdownValues,
                        after: helpers.addMonth(new Date(), 1),
                    },

                    {
                        daysOfWeek: [0, 6],
                    },
                ];
            }

            return [];
        }

        if (type === 'selected')
            return this.filterDateAppointment(
                ...this.filterCalendarAppointmentResponse(
                    energyType,
                    'MOVE_IN'
                ),
                'express',
                true
            );
        return this.filterDateAppointment(
            ...this.filterCalendarAppointmentResponse(energyType, 'MOVE_IN'),
            'available',
            false
        );
    };

    handleDropdownContract = object => {
        const { fields, setting } = this.state;
        const { dateContract } = fields.common;
        const {
            userState: { energyTypes },
            // dataState: { calendarAppointments },
        } = this.props;
        const { resetScreenTo, isCurrentScreen } = this.props;

        if (object.value === Wording.contractDate.dropdownValues[0].value) {
            dateContract.switch = object;
            setting.showBottomText = true;
        } else if (
            object.value === Wording.contractDate.dropdownValues[1].value
        ) {
            dateContract.switch = object;
            setting.showBottomText = false;
        }
        energyTypes.forEach(energy => {
            dateContract[energy].date = null;
            dateContract[energy].month = null;
            dateContract[energy].year = null;
        });

        if (!isCurrentScreen) {
            setting.isGoingNext = false;
            this.setState({ setting });
            resetScreenTo();
        }

        // energyTypes.forEach(energy => {
        //     let day = 0;
        //     if (object.value === Wording.contractDate.dropdownValues[0].value) {
        //         day = Config.DefaultSwitchDueDate.NOW[energy];
        //     } else if (
        //         object.value === Wording.contractDate.dropdownValues[1].value
        //     ) {
        //         day = Config.DefaultSwitchDueDate.LATER[energy];
        //     }
        //     const daysList = (
        //         calendarAppointments.find(c => c.energy === energy) || {
        //             daysList: [],
        //         }
        //     ).daysList;
        //     const actualDate = new Date();
        //     const newDate = helpers.addDays(actualDate, day);
        //     for (let i = 0; i !== daysList.length; i += 1) {
        //         const correctNewDate = new Date(daysList[i].date)
        //         if (correctNewDate.getDate() >= newDate.getDate() && correctNewDate.getMonth() >= newDate.getMonth() && correctNewDate.getFullYear() >= newDate.getFullYear()) {
        //             dateContract[energy].date = correctNewDate.getDate();
        //             dateContract[energy].month = correctNewDate.getMonth();
        //             dateContract[energy].year = correctNewDate.getFullYear();
        //             break;
        //         }
        //     }
        // });

        return this.setState({
            fields,
            setting,
        });
    };

    render() {
        const {
            userState,
            codeState,
            summaryState,
            autorizedBillingModes,
            isCurrentScreen,
            nextScreen,
            canGoNext,
            mainState: { loading },
            isModalOpen,
        } = this.props;
        const { fields, setting } = this.state;

        const isScheduleWithoutPack = helpers.isScheduleWithoutPackBillingMode(
            autorizedBillingModes
        );

        const isCyclic = 
            Boolean(
                autorizedBillingModes &&
                    autorizedBillingModes.find(
                        mode =>
                            mode.billingModeCode ===
                            BillingMode.CYCLICAL_BILLING
                    )
            );

        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout
                    step={isCyclic ? SituationScreen.navigationOptions.step -1 : SituationScreen.navigationOptions.step}
                    isModal={isModalOpen}
                >
                    <div className="summary-screen">
                        <div className="situation-container__title-container">
                            <Title
                                titleFormatGowun="Quelle est ma "
                                titleFormatLexand="situation ?"
                            />
                        </div>
                        <div className="situation-container__details-container">
                            <Typography>
                                Tu emménages ou changes juste de fournisseur ?
                                Dis nous tout et indique <br />
                                nous la date idéale à laquelle tu aimerais voir
                                ton contrat commencer.
                            </Typography>
                        </div>

                        <div className="situation-container__situationCheckbox__content">
                            <div className="situation-container__situationCheckbox__content__radios">
                                <CustomSelector
                                    defaultId={
                                        Wording.situation.values.moved.id
                                    }
                                    onMounSetter
                                    options={[
                                        {
                                            label:
                                                Wording.situation.values.moved
                                                    .text,
                                            id:
                                                Wording.situation.values.moved
                                                    .id,
                                            value:
                                                Wording.situation.values.moved
                                                    .value,
                                        },
                                        {
                                            label:
                                                Wording.situation.values
                                                    .changeSupplier.text,
                                            id:
                                                Wording.situation.values
                                                    .changeSupplier.id,
                                            value:
                                                Wording.situation.values
                                                    .changeSupplier.value,
                                        },
                                    ]}
                                    onSelect={(id, value) =>
                                        this.handleToggleSituation(
                                            value,
                                            'situation'
                                        )
                                    }
                                    className="situation-container__situationCheckbox__content__radios__selector"
                                    gap="0"
                                    style={{ width: '80%' }}
                                />
                            </div>
                        </div>
                        <SituationContainer
                            setting={setting}
                            fields={fields}
                            userState={userState}
                            codeState={codeState}
                            summaryState={summaryState}
                            isScheduleWithoutPack={isScheduleWithoutPack}
                            handleChangeSelect={this.handleChangeSelect}
                            handleDisplayValue={this.handleDisplayValue}
                            handleChangeInput={this.handleChangeInput}
                            handleDatePicker={this.handleDatePicker}
                            handleToggle={this.handleToggle}
                            handleDropdownContract={this.handleDropdownContract}
                            handleDateCalendarOption={
                                this.handleDateCalendarOption
                            }
                            handleInputChangeAddressContainer={
                                this.handleInputChangeAddressContainer
                            }
                            handleChangeDropdownSelect={
                                this.handleChangeDropdownSelect
                            }
                            handleChangeTimeslotSelect={
                                this.handleChangeTimeslotSelect
                            }
                            onFocusDropdown={this.onFocusDropdown}
                            handleDateChange={this.handleDateChange}
                            condition={this.handleCondition}
                            isCyclic={isCyclic}
                        />
                    </div>
                    {isCurrentScreen && (
                        <div className="validate-button-top-space">
                            <CustomButton
                                disabledBackgroundColor="#f3f3f3"
                                title="Valider"
                                color="orange"
                                disabled={!canGoNext}
                                onClick={nextScreen}
                                loading={loading}
                            />
                        </div>
                    )}
                </SectionLayout>
            </>
        );
    }
}

const mapStateToProps = state => ({
    dataState: state.dataReducer,
    userState: state.userReducer,
    codeState: state.codeReducer,
    summaryState: state.summaryReducer,
    paymentState: state.paymentReducer,
    autorizedBillingModes: state.packageReducer.autorizedBillingModes,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validateDataScreenDispatch: payload =>
        dispatch(actions.validateDataScreenSuccess(payload)),
    validateDataScreenFinalDispatch: payload =>
        dispatch(actions.validateDataScreenSuccessFinal(payload)),
    fetchCalendarAppointmentDispatch: payload =>
        dispatch(actions.fetchCalendarAppointment(payload)),
    dataScreenSaveCommonDispatch: payload =>
        dispatch(actions.dataScreenSaveCommon(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SituationScreen);
