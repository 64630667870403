import React from 'react';
import WordingConstant from '../../../utils/wording.json';

const DataWording = WordingConstant.DataScreen;

const AddressDetails = ({ userInfo }) => {
    const { civility, name, surname, address, newAddress } = userInfo;
    const civilityLabel = (
        DataWording.civility.values.find(v => v.value === civility) || {}
    ).label;
    const person = {
        name: `${civilityLabel} ${name} ${surname}`,
        address: `${newAddress.address ||
            address.address} ${newAddress.postalCode ||
            address.postalCode} ${newAddress.city || address.city}`,
    };
    return (
        <div className="address-details__container">
            <div className="address-details__container__title">
                Adresse de consommation:
            </div>
            <div className="address-details__container__content">
                <p>{person.name}</p>
                <p>{person.address}</p>
            </div>
        </div>
    );
};

export default AddressDetails;
