import React, { useState } from 'react';

import {
    useElements,
    useStripe,
    PaymentElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';

import CustomButton from '../CustomButton';
import resources from '../../utils/resources';
import logoWekiwi from '../../assets/icons/ic-logo.svg';
import logoStripe from '../../assets/icons/ic-stripe.svg';
import WordingConstant from '../../utils/wording.json';
import config from '../../utils/config';
import { log } from '../../utils/helpers';

const wording = WordingConstant.PaymentModalCB;

// eslint-disable-next-line no-unused-vars
const StripeForm = ({ clientSecret, amount, onFormValidate, clientName }) => {
    const [isLoading, setIsLoading] = useState(false);
    const elements = useElements();
    const stripe = useStripe();
    const [error, setError] = useState("");

    const channel = localStorage.getItem("channel")

    const onValidateForm = async e => {
        e.preventDefault();

        setIsLoading(true);
        let result;
        if (channel && config.ChannelsDoorToDoor.includes(channel.toUpperCase())) {
            result = await stripe.confirmSetup({
                // `Elements` instance that was used to create the Payment Element
                elements,
                redirect: 'if_required',
            });
        } else {
            result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: clientName,
                    },
                },
            });
        }

        setIsLoading(false);
        log('STRIPE ANSWER ===>', result);
        // if (result.paymentIntent.status ===  'succeeded') {
        //     const currentSearchParams = new URLSearchParams(window.location.search);

        //     currentSearchParams.set('status', 'success');

        //     const newUrl = `${window.location.pathname}?${currentSearchParams.toString()}`;

        //     window.location.href= newUrl;
        // }
        if (result.setupIntent) {
            if (result.setupIntent.status === 'succeeded') {
                const currentSearchParams = new URLSearchParams(
                    window.location.search
                );

                currentSearchParams.set('status', 'success');

                const newUrl = `${window.location.pathname
                    }?${currentSearchParams.toString()}`;

                window.location.href = newUrl;
            }
        }

        if (result.paymentIntent) {
            if (result.paymentIntent.status === 'succeeded') {
                const currentSearchParams = new URLSearchParams(
                    window.location.search
                );

                currentSearchParams.set('status', 'success');

                const newUrl = `${window.location.pathname
                    }?${currentSearchParams.toString()}`;

                window.location.href = newUrl;
            }
        }

        if (result.error) {
            //if (onFormValidate) onFormValidate(false, result.error.message);
            setError(result.error.message)
        } else if (onFormValidate) onFormValidate(true);
    };

    const renderFakeCard = (cardNumber, title) => {
        const [isClicked, setisClicked] = useState(false);

        const handleClickCard = () => {
            setisClicked(true);
            setTimeout(() => setisClicked(false), 1600);
            navigator.clipboard.writeText(cardNumber);
        };

        return (
            <div>
                {title}:
                <button type="button" onClick={handleClickCard}>
                    {cardNumber}
                    <div className="clicked-fake-card-label">
                        {isClicked && <span>Copié</span>}
                        <img
                            alt="clipboard"
                            src={resources['ic-copy-clipboard']}
                        />
                    </div>
                </button>
            </div>
        );
    };

    const onCancel = () => {
        if (onFormValidate) onFormValidate(false, wording.failureMessage);
    };

    const isDebugMode = config.ENV === 'PREPRODUCTION';

    return (
        <div className="stripe-form-container">
            <form className="form-content">
                <div className="logo-container">
                    <img
                        src={logoWekiwi}
                        alt="ic-logo-wekiwi"
                        width={230}
                        height={61}
                    />
                </div>
                <p className="amount">
                    {wording.amountMessage}
                    <span>{`${amount}€`}</span>
                </p>
                {
                    config.ChannelsDoorToDoor.includes(channel) && (<PaymentElement />)
                }

                {
                    !config.ChannelsDoorToDoor.includes(channel) && (<div className="stripe-card-container">
                        <div className="stripe-card-number-container">
                            <CardNumberElement
                                options={{ placeholder: wording.label_card_number }}
                                className="stripe-card-number-field"
                            />
                        </div>
                        <div className="stripe-container-information">
                            <CardExpiryElement
                                options={{ placeholder: wording.label_card_expiry }}
                                className="stripe-card-expiry-field"
                            />
                            <CardCvcElement
                                options={{ placeholder: wording.label_card_cvc }}
                                className="stripe-card-cvc-field"
                            />
                        </div>
                    </div>)
                }



                {isDebugMode && (
                    <div className="stripe-card-debug">
                        <span>
                            En mode demo vous pouvez choisir une carte de test
                        </span>
                        {renderFakeCard(
                            '4242424242424242',
                            'Paiement direct avec réussite'
                        )}
                        {renderFakeCard(
                            '4000000000003220',
                            'Paiement 3DSecure avec réussite'
                        )}
                        {renderFakeCard(
                            '4000008400001280',
                            'Paiement 3DSecure avec erreur'
                        )}
                        {renderFakeCard(
                            '4000002500003155',
                            'Authentification config initial'
                        )}
                        {renderFakeCard(
                            '4000000000009995',
                            'Carte refusée lors de la configuration'
                        )}
                    </div>
                )}
                {
                    error && (
                        <span style={{color: "red", textAlign: "center", display: "block", width: "100%", padding: "8px"}}>{error}</span>
                    )
                }
                <div className="button-container">
                    <CustomButton
                        title={wording.button_cancel}
                        color="gray"
                        onClick={onCancel}
                    />
                    <div className="button-validate">
                        <CustomButton
                            title={wording.button_validate}
                            color="orange"
                            type="submit"
                            loading={isLoading}
                            onClick={onValidateForm}
                        />
                    </div>
                </div>

                <div className="container-stripe-logo">
                    <p>{wording.stripeMessage}</p>
                    <img
                        src={logoStripe}
                        alt="logo stripe"
                        className="stripe-logo"
                    />
                </div>
            </form>
            <div className="footer" />
        </div>
    );
};

export default StripeForm;
