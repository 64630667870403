import React from 'react';

import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';
import LabeledInput from '../LabeledInput/LabeledInput';

const Wording = WordingConstant.Wekiwi;

let isOpenBot = false;

async function callBotSdk() {
    if (isOpenBot) {
        window.botSDK.close();
    } else {
        window.botSDK.open();
    }
    window.botSDK.onOpenBot(_ => {
        isOpenBot = true;
    });
    window.botSDK.onCloseBot(_ => {
        isOpenBot = false;
    });
}

const Footer = () => (
    <div className="footer">
        <div className="footer__container">
            <div className="footer__topSection">
                <div className="footer__topSection__subSection">
                    <div className="footer__logo">
                        <img src={resources['ic-logo-text']} alt="icon" />
                    </div>
                </div>
                <div className="footer__topSection__subSection">
                    <div className="footer__links">
                        {Wording.usefulLinks.map((link, i) => (
                            <a
                                key={`linkFooter${i}`}
                                href={link.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {link.text}
                            </a>
                        ))}
                    </div>
                    <div className="footer__newsletter">
                        <div className="footer__newsletter__text">
                            {Wording.newsletter.text}
                        </div>
                        <div className="footer__newsletter__form">
                            <LabeledInput
                                label={Wording.newsletter.placeholder}
                                type="text"
                            />
                            <button
                                type="button"
                                className="custom-button custom-button--black"
                            >
                                <i className="custom-button__arrowRight" />
                                <span>{Wording.newsletter.send}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="footer__topSection__subSection footer--last-item">
                    <div className="footer__bot">
                        <div className="footer__bot__content">
                            <p>Tu as des questions ?</p>
                            <p>Viens chatter avec notre bot !</p>
                        </div>
                        <div onClick={callBotSdk} className="footer__bot__logo">
                            <img src={resources['ic-bot']} alt="bot" />
                        </div>
                    </div>

                    <div className="footer__social">
                        <a
                            href={Wording.socialLink.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={resources['ic-facebook']}
                                alt="ic-facebook"
                            />
                        </a>

                        <a
                            href={Wording.socialLink.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={resources['ic-instagram']}
                                alt="ic-instagram"
                            />
                        </a>

                        <a
                            href={Wording.socialLink.youtube}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={resources['ic-youtube']}
                                alt="ic-youtube"
                            />
                        </a>
                        <a
                            href={Wording.socialLink.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={resources['ic-linkedin']}
                                alt="ic-linkedin"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer__bottomSection">
                <div className="footer__bottomSection__links">
                    {Wording.bottomLinks.map((link, i) => (
                        <a
                            key={`linkFooterBottom${i}`}
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {link.text}
                        </a>
                    ))}
                </div>

                <div className="footer__bottomSection__copyright">
                    <p>
                        <span className="footer__bottomSection__copyright__title">
                            {Wording.copyright.text1}
                        </span>
                        {Wording.copyright.countries.map((country, index) => (
                                <>
                                    <a target="_blank" href={country.link} className="footer__bottomSection__copyright__title__country" rel="noreferrer">
                                        {country.title}{' '}{index === Wording.copyright.countries.length -1 ? '' : '-'}{' '}
                                    </a>
                                </>
                            ))}
                    </p>
                    <p className="footer__bottomSection__copyright__catchphrase">
                        {Wording.copyright.text3}
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
