import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { mapGetPackages } from '../PackageScreen/PackageScreen.actions';

import {
    SURVEY_SCREEN_VALIDATE,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_FAILURE,
    SURVEY_SCREEN_LOAD,
} from '../types';

const loadSurveyScreen = payload => ({
    type: SURVEY_SCREEN_LOAD,
    payload,
});

const validateSurveyScreen = payload => ({
    type: SURVEY_SCREEN_VALIDATE,
    payload,
});

const validateSurveyScreenSuccess = payload => ({
    type: SURVEY_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validateSurveyScreenFailure = payload => ({
    type: SURVEY_SCREEN_VALIDATE_FAILURE,
    payload,
});

const preparePayload = ({
    housingType,
    residenceType,
    area,
    inhabitantsNumber,
    heatingType,
    priceOption,
    userType,
    energyTypes,
    postalCode,
    city,
    netArea,
    missingEnergy,
}) => {
    let energyList = [];
    if (missingEnergy) {
        energyList = missingEnergy.length === 0 ? energyTypes : missingEnergy;
    } else {
        energyList = energyTypes;
    }

    const survey = {
        energyList,
        housingType,
        residenceType,
        area,
        inhabitantsNumber,
        heatingType,
        rateOption: priceOption === '' ? 'UNKNOWN' : priceOption,
        address: {
            netArea,
            postalCode,
            townName: city,
        },
    };
    return {
        mode: 'SURVEY',
        customerType: userType,
        survey,
    };
};

const validateSurveyScreenEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(SURVEY_SCREEN_VALIDATE),
        mergeMap(action =>
            mapGetPackages(
                action,
                dependency,
                preparePayload,
                validateSurveyScreenSuccess,
                validateSurveyScreenFailure
            )
        )
    );

const surveyScreenEpic = combineEpics(validateSurveyScreenEpic);

export {
    preparePayload,
    validateSurveyScreen,
    validateSurveyScreenSuccess,
    validateSurveyScreenFailure,
    surveyScreenEpic,
    loadSurveyScreen,
};
