import firebase from 'firebase/app';
import 'firebase/analytics';
import config from '../config';

const { FirebaseConfig } = config;

const initFirebase = () => {
    if (!FirebaseConfig.apiKey) {
        console.error('Need auth for Firebase | Config not found');
    }
    if (typeof window !== 'undefined' && !firebase.apps.length) {
        firebase.initializeApp(FirebaseConfig);
        if ('measurementId' in FirebaseConfig) {
            firebase.analytics();
        }
    }
};

const logEvent = (...props) => {
    try {
        firebase.analytics().logEvent(...props);
    } catch {
        console.log('firebase.analytics not found');
    }
};

const logChannel = channel => {
    try {
        firebase.analytics().logEvent(`CH-${channel}`);
    } catch {
        console.log('firebase.analytics not found');
    }
};

const logScreen = tag => {
    try {
        firebase.analytics().logEvent(`${tag}`);
    } catch {
        console.log('firebase.analytics not found');
    }
};

export { initFirebase, logEvent, logChannel, logScreen };

export default firebase;
