import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import Lambda from 'aws-sdk/clients/lambda';

import Config from './config';
import Wording from './wording.json';
import { log, getInitialChannel, checkPathOrder } from './helpers';

import { log as logger } from './logger';
import Screen from '../screens/screen';

export const invokeLambda = (lambda, payload) => {
    const lambdaInstance = new Lambda(Config.LamdaConfig);

    log(`lambda ${lambda.functionName}: payload ====>`, payload);

    return new Promise((resolve, reject) => {
        lambdaInstance.invoke(
            {
                FunctionName: lambda.functionName,
                Payload: JSON.stringify(payload),
                InvocationType: 'RequestResponse',
                LogType: 'None',
            },
            (err, res) => {
                if (err) {
                    const error = {
                        ajaxError: 'lambda',
                        code: err.code,
                        message: err.message,
                    };
                    log(
                        `Error from lambda ${lambda.functionName} ====> `,
                        error
                    );

                    logger({
                        returnCode: error.code,
                        logMessage: error.message,
                        serviceCalled: lambda.functionName,
                        statusAction: 'LAMBDA FAILURE',
                    });

                    reject(error);
                } else {
                    const response = JSON.parse(res.Payload);
                    log(
                        `lambda ${lambda.functionName}: response ====>`,
                        response
                    );
                    resolve(response);
                }
            }
        );
    });
};

export const apiRequest = ({
    token,
    path,
    method,
    body,
    isLocal,
    lambda,
    urlOverride,
}) => {
    const modifiedBody = {
        ...body,
        channel: 'WEB',
        seller: 'WEKIWI',
    };
    const channel = localStorage.getItem('channel');

    if (channel) {
        modifiedBody.channel = checkPathOrder(path)
            ? channel.trim()
            : getInitialChannel(channel.trim());
    }

    if (lambda) {
        // return from(invokeLambda(lambda, modifiedBody));
    }

    const url = isLocal ? Config.URL_LOCAL : Config.URL;

    const settings = {
        method,
        url: `${urlOverride || url}${path}`,
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': Config.ApiKey,
        },
        responseType: 'json',
        crossDomain: true,
        body: modifiedBody,
    };


    if (token) {
        settings.headers.Authorization = `Bearer ${token}`;
    }

    return ajax(settings).pipe(
        catchError(({ message, response, status }) => {
            const error = {
                ajaxError: message,
                code: status,
                message: Wording.Common.errorAjax,
            };
            if (response) {
                error.code = response.code;
                error.message = response.message;
            }
            log(`Error from API ${path} ====> `, error);
            return throwError(error);
        }),
        map(({ response }) => {
            return response;
        })
    );
};

export const searchAppointmentTimeslot = async (date, energy) => {
    let day = date.getDate();
    day = `${day < 10 ? `0${day}` : day}`;
    let month = date.getMonth();
    month = `${month + 1 < 10 ? `0${month + 1}` : month + 1}`;
    const data = {
        date: `${date.getFullYear()}-${month}-${day}`,
        energy,
    };
    try {
        const res = await fetch(`${Config.URL}/getAppointmentTimeslot`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-api-key': Config.ApiKey,
            },
            body: JSON.stringify(data),
        });
        const response = await res.json();
        logger({
            tag: Screen.DATA,
            returnCode: '200',
            logMessage: null,
            serviceCalled: '/getAppointmentTimeslot',
            statusAction: 'SUCCESS',
            triggerButton: 'select-date',
        });
        const ts = response.timeslots;
        if (ts) {
            return ts;
        }
        throw res.message || { message: 'Service indisponible' };
    } catch (error) {
        log(`Error from API /getAppointmentTimeslot ====> `, error);

        logger({
            tag: Screen.DATA,
            returnCode: error.code,
            logMessage: error.message,
            serviceCalled: '/getOffers',
            statusAction: 'FAILURE',
            triggerButton: 'select-date',
        });
        return [];
    }
};

export const searchSiret = async value => {
    try {
        const res = await fetch(`${Config.URL_GET_SIRET}/${value}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer b6a24984-f301-3418-b06f-5ebb0081af43',
            },
        });
        if (res.status) {
            const response = await res.json();
            return {
                ...response,
                status: res.status,
            };
        }
        throw res.message || { message: 'Service indisponible' };
    } catch (error) {
        log(`Error from API /getSiret ====> `, error);
        return null;
    }
};

export default apiRequest;
