import React from 'react';
import { connect } from 'react-redux';

import resources from '../../utils/resources';
import { logScreen, TagLogger } from '../../utils/firebase';

import Screen from '../screen';

import WordingData from '../../utils/wording.json';
import SectionLayout from '../../components/SectionLayout/SectionLayout';

const Wording = WordingData.SaveScreen;
export class SaveScreen extends React.Component {
    static navigationOptions = {
        nextScreen: null,
        previousScreen: Screen.FINALSUMMARY,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.SAVE,
        showSaveText: true,
        step: 9,
        validate: () => {},
        saveData: () => {},
    };
    /* static navigationOptions = {
        nextScreen: null,
        previousScreen: null,
        buttonNextTitle: '',
        buttonPreviousTitle: '',
        title: Wording.title,
        screenId: Screen.SAVE,
        showSaveButton: false,
        step: 9,
        validate: () => {},
    }; */

    componentDidMount() {
        logScreen(TagLogger.SAVE);
    }

    render() {
        const { orderReference, isValidationEmail } = this.props;
        return (
            <SectionLayout>
                <div className="save-screen">
                    <img
                        className="save-screen__img"
                        src={resources['ic-save-screen']}
                        alt={isValidationEmail ? '' : Wording.text1}
                    />
                    <h1 className="save-screen__text">
                        {isValidationEmail ? Wording.text3 : Wording.text1}
                    </h1>
                    <h1 className="save-screen__text">
                        {isValidationEmail ? '' : Wording.text2}
                    </h1>
                    {orderReference !== null && (
                        <div className="save-screen__displayOrderReference">
                            Voici ton numéro de souscription : <br />
                            <strong>{orderReference}</strong>
                        </div>
                    )}
                </div>
            </SectionLayout>
        );
    }
}

const mapStateToProps = state => ({
    orderReference: state.paymentReducer.reference,
});

export default connect(mapStateToProps, null)(SaveScreen);
