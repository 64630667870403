import { combineEpics, ofType } from 'redux-observable';

import { of, forkJoin, timer } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';

import {
    PAYMENT_MODAL_LOAD,
    PAYMENT_MODAL_VALIDATE,
    PAYMENT_MODAL_VALIDATE_SUCCESS,
    PAYMENT_MODAL_VALIDATE_FAILURE,
    VALIDATE_DIRECT_DEBIT_PAYMENT,
} from '../../screens/types';
import { log, TagLogger } from '../../utils/logger';
import {
    mapGetPaymentCB,
    firstPaymentCBFailure,
} from './PaymentCBModal.actions';
import config from '../../utils/config';

const validatePaymentScreen = payload => ({
    type: PAYMENT_MODAL_VALIDATE,
    payload,
});

const validatePaymentScreenSuccess = payload => ({
    type: PAYMENT_MODAL_VALIDATE_SUCCESS,
    payload,
});

const validatePaymentScreenFailure = payload => ({
    type: PAYMENT_MODAL_VALIDATE_FAILURE,
    payload,
});

const loadPaymentModal = payload => ({
    type: PAYMENT_MODAL_LOAD,
    payload,
});

const validateDirectDebitPayment = payload => ({
    type: VALIDATE_DIRECT_DEBIT_PAYMENT,
    payload,
});

const preparePayload = ({ reference }) => ({
    orderNumber: reference,
});

const mapGetPayment = (action, { apiRequest }) => {
    const payload = preparePayload(action.payload);
    return apiRequest({
        path: '/getOrderStatus',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.orderStatus) {
                const condition =
                    (response.orderStatus === 'FINALIZED' ||
                        response.orderStatus === 'FINALIZED_WITHOUT_DOC') &&
                    response.firstPaymentCBStatus !== undefined &&
                    response.firstPaymentCBStatus !== 'PENDING';

                log({
                    tag: condition ? TagLogger.PAYMENT : TagLogger.SIGNING,
                    returnCode: '200',
                    logMessage: response,
                    serviceCalled: '/getOrderStatus',
                    statusAction: 'SUCCESS',
                    triggerButton: condition
                        ? 'after-signing'
                        : 'validate-button',
                });

                return of(response);
            }

            log({
                tag: TagLogger.SIGNING,
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/getOrderStatus',
                statusAction: 'FAILURE',
                triggerButton: 'validate-button',
            });
            return of(response);
        }),
        catchError(error => of(error))
    );
};

const validatePaymentModalEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(PAYMENT_MODAL_VALIDATE),
        switchMap(
            action => timer(10000).pipe(
                switchMap(() => mapGetPayment(action, dependency))
            ),
            (action, r) => [action, r]
        ),

        switchMap(([action, getOrderStatusResponse]) => {
            const {
                orderStatus,
                firstPaymentCBStatus,
            } = getOrderStatusResponse;
            const orderST =
                orderStatus === 'FINALIZED' ||
                orderStatus === 'FINALIZED_WITHOUT_DOC';

            if (orderST && firstPaymentCBStatus === 'PENDING') {
                const newAction = {
                    payload: {
                        order: action.payload.prepareOrder,
                        whenCollected: config.ChannelsDoorToDoor.includes(action.payload.prepareOrder.contracts[0].thirdParties[0].code.toUpperCase()) ? 'LATER' : 'NOW',
                    },
                };
                return forkJoin(
                    of(getOrderStatusResponse),
                    mapGetPaymentCB(newAction, dependency),
                    of(action.payload.prepareOrder)
                );
            }
            const observables = [of(getOrderStatusResponse), of(undefined), of(action.payload.prepareOrder)];
            return forkJoin(observables);
        }),

        switchMap(([getOrderStatusResponse, paymentCBResponse, order]) => {
            const {
                orderStatus,
                firstPaymentCBStatus,
            } = getOrderStatusResponse;

            const orderSTFinalized =
                orderStatus === 'FINALIZED' ||
                orderStatus === 'FINALIZED_WITHOUT_DOC';

            if (
                orderStatus !== undefined &&
                orderStatus !== 'CANCELED' &&
                orderStatus !== 'IN_FAILURE' &&
                orderStatus !== 'IN_PROGRESS'
            ) {
                if (firstPaymentCBStatus !== undefined && orderSTFinalized) {
                    if (
                        paymentCBResponse !== undefined &&
                        paymentCBResponse.stripe !== undefined
                    ) {
                        log({
                            tag: TagLogger.PAYMENT,
                            returnCode: '200',
                            logMessage: paymentCBResponse,
                            serviceCalled: '/paymentCB',
                            statusAction: 'SUCCESS',
                            triggerButton: 'after-sign-order',
                        });
                        return of(
                            loadPaymentModal({
                                ...getOrderStatusResponse,
                                ...paymentCBResponse,
                                order,
                                paymentType: 'PAYMENT_CB',
                            }),
                            validateDirectDebitPayment()
                        );
                    }

                    if (firstPaymentCBStatus === 'FINALIZED') {
                        log({
                            tag: TagLogger.PAYMENT,
                            returnCode: '200',
                            logMessage: getOrderStatusResponse,
                            serviceCalled: '/paymentCB',
                            statusAction: 'SUCCESS',
                            triggerButton: 'after-sign-order',
                        });
                        return of(validatePaymentScreenSuccess({}));
                    }

                    // Failure if status === IN_FAILURE || CANCELED || IN_PROGRESS
                    return of(firstPaymentCBFailure());
                }
                return of(
                    validatePaymentScreenSuccess(getOrderStatusResponse),
                    validateDirectDebitPayment(order)
                );
            }
            return of(validatePaymentScreenFailure(getOrderStatusResponse));
        }),

        catchError(error => {
            const message = error.message || error.errorMessage;

            return of(
                validatePaymentScreenFailure({
                    error: message,
                })
            );
        })
    );

const paymentModalEpic = combineEpics(validatePaymentModalEpic);

export {
    validatePaymentScreen,
    validatePaymentScreenSuccess,
    validatePaymentScreenFailure,
    validateDirectDebitPayment,
    paymentModalEpic,
    loadPaymentModal,
};