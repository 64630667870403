import React from 'react';
import SituationMovedContainer from './SituationMovedContainer';
import SituationChangeSupplierContainer from './SituationChangeSupplierContainer';

const SituationContainer = ({
    userState: { energyTypes },
    fields: {
        common: { situation, dateContract },
    },
    // setting: { showError },
    handleDatePicker,
    handleDropdownContract,
    handleDateCalendarOption,
    handleChangeTimeslotSelect,
    isCyclic,
}) => (
    <div className="situation-container">
        {situation === 'MOVE_IN' && (
            <SituationMovedContainer
                energyTypes={energyTypes}
                dateContract={dateContract}
                handleDatePicker={handleDatePicker}
                handleDateCalendarOption={handleDateCalendarOption}
                handleChangeTimeslotSelect={handleChangeTimeslotSelect}
                isCyclic={isCyclic}
            />
        )}

        {situation === 'SWITCH' && (
            <SituationChangeSupplierContainer
                energyTypes={energyTypes}
                dateContract={dateContract}
                handleDatePicker={handleDatePicker}
                handleDropdownContract={handleDropdownContract}
                handleDateCalendarOption={handleDateCalendarOption}
                handleChangeTimeslotSelect={handleChangeTimeslotSelect}
                isCyclic={isCyclic}
            />
        )}
    </div>
);
export default SituationContainer;
