import React from 'react';
import {
    CustomInput,
    // CustomRadio,
    ImageElement,
    CustomDropdownInput,
} from '../../components';
import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';
import SurveySelectorStyled from './SurveySelectorStyled';
import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';
import CustomSelectV2 from '../../components/CustomSelect/CustomSelectV2';
import CustomSelector from '../../components/CustomSelector/CustomSelector';

const Wording = WordingConstant.SurveyScreen;

const SurveyContainer = ({
    fields: {
        city,
        postalCode,
        housingType,
        residenceType,
        heatingType,
        priceOption,
        area,
        inhabitantsNumber,
    },
    setting: { postalCodes, isLoadingPC, codeText, showError },
    handleToggle,
    handleSelect,
    handleInputCity,
    handleInputChangeAddressContainer,
    handleChangeDropdownSelect,
    onFocusDropdown,
    showPriceOption,
    condition,
}) => {
    const renderSelectArea = () => {
        const {
            value: { start },
        } = Wording.area;
        const {
            value: { length },
        } = Wording.area;
        const {
            value: { step },
        } = Wording.area;
        const list = Array.from({ length }, (v, k) => {
            if (k === 0) {
                return { value: 20, label: '0 à 20', id: 20 };
            }
            const s = start + step * (k + 1) + 1;
            const ss = start + step * (k + 2);
            return { value: ss, label: `${s} à ${ss}`, id: ss };
        });
        return (
            <CustomSelectV2
                style={{ marginTop: '5px' }}
                customSize="225px"
                value={area}
                handleChange={option => handleSelect(option, 'area')}
                defaultId={area}
                placeholder="Surface en m²"
                options={list}
                halfSize
            />
        );
    };

    const renderSelectInhabitants = () => {
        const {
            value: { start },
        } = Wording.inhabitantsNumber;
        const {
            value: { length },
        } = Wording.inhabitantsNumber;
        const {
            value: { step },
        } = Wording.inhabitantsNumber;
        const list = Array.from({ length }, (v, k) => {
            const s = start + step * k;
            return { value: s, label: `${s}`, id: s };
        });
        return (
            <CustomSelectV2
                style={{ marginTop: '15px', marginBottom: '5px' }}
                customSize="225px"
                value={inhabitantsNumber}
                handleChange={option =>
                    handleSelect(option, 'inhabitantsNumber')
                }
                defaultValue={inhabitantsNumber}
                placeholder={Wording.inhabitantsNumber.title}
                options={list}
                halfSize
            />
        );
    };

    const renderSelector = (
        { values },
        choiceType,
        handleClick,
        error,
        w,
        totalWidth = '70%'
    ) => (
        <div className="survey-container__selector">
            <div
                className="survey-container__selector__container"
                style={{ width: totalWidth }}
            >
                <div className="survey-container__selector__container__images">
                    {values &&
                        values.map(({ text, value, imageSrc, imageSrcOn }) => (
                            <ImageElement
                                key={value}
                                comp={
                                    <SurveySelectorStyled
                                        imageSrc={
                                            choiceType === value &&
                                            imageSrcOn &&
                                            resources[imageSrcOn]
                                                ? resources[imageSrcOn]
                                                : resources[imageSrc]
                                        }
                                        text={text}
                                        isChecked={choiceType === value}
                                        width={w}
                                    />
                                }
                                onClick={() => handleClick(value)}
                            />
                        ))}
                </div>
                {error && error !== '' && (
                    <p className="survey-container__selector__container__error">
                        {error}
                    </p>
                )}
            </div>
        </div>
    );

    const renderRadio = ({ values }, option, handleCheck) => (
        <>
            <Title
                titleFormatGowun="Quelle est ton "
                titleFormatLexand="option tarifaire ?"
            />
            <CustomSelector
                notFullWidth
                gap="2rem"
                options={values}
                value={option}
                onSelect={v => handleCheck(v)}
            />
        </>
        // <div className="survey-container__selector">
        //     <div className="survey-container__selector__container">
        //         <div className="survey-container__selector__container__custom-radio">
        //             {values &&
        //                 values.map(({ id, text, value }, index) => (
        //                     <CustomRadio
        //                         key={index}
        //                         id={id}
        //                         text={text}
        //                         value={value}
        //                         checked={option === value}
        //                         handleCheck={() => handleCheck(value)}
        //                     />
        //                 ))}
        //         </div>
        //         {error && error !== '' && (
        //             <p className="survey-container__selector__container__error">
        //                 {error}
        //             </p>
        //         )}
        //     </div>
        // </div>
    );

    return (
        <div className="survey-container">
            <div className="survey-container__titles">
                <Title
                    titleFormatGowun="J’identifie "
                    titleFormatLexand="mon logement"
                />
                <Typography className="survey-container__titles__subtitle">
                    Notre simulation te permettra de trouver le forfait qui te
                    correspond le mieux !
                </Typography>
            </div>

            <div className="survey-container__drop-down-container">
                <CustomDropdownInput
                    text={postalCode}
                    inputValue={
                        codeText.length <= 5
                            ? codeText
                            : codeText.substring(0, 5)
                    }
                    onFocus={onFocusDropdown}
                    onInputChange={handleInputChangeAddressContainer}
                    options={postalCodes}
                    placeholder="Code postal"
                    handleChange={handleChangeDropdownSelect}
                    value={postalCode ? { label: postalCode } : undefined}
                    isLoading={isLoadingPC}
                    error={
                        (postalCode === '' || postalCode === null) &&
                        showError &&
                        Wording.error.mainText
                    }
                />
                <CustomInput
                    placeholder={Wording.city.title}
                    type="text"
                    value={city}
                    onInputChange={handleInputCity}
                    isLocked
                />
            </div>

            {renderSelector(
                Wording.housingType,
                housingType,
                e => handleToggle(e, 'housingType'),
                housingType === '' && showError ? Wording.error.mainText : null
            )}
            {renderSelector(
                Wording.residenceType,
                residenceType,
                e => handleToggle(e, 'residenceType'),
                residenceType === '' && showError
                    ? Wording.error.mainText
                    : null
            )}
            <div className="survey-container__drop-down-container-2">
                {renderSelectArea()}
                {renderSelectInhabitants()}
            </div>
            {renderSelector(
                Wording.heatingType,
                heatingType,
                e => handleToggle(e, 'heatingType'),
                heatingType === '' && showError ? Wording.error.mainText : null,
                '60px',
                '82%'
            )}

            {showPriceOption &&
                renderRadio(
                    Wording.priceOption,
                    priceOption,
                    e => handleToggle(e, 'priceOption'),
                    priceOption === '' && showError
                        ? Wording.error.mainText
                        : null
                )}

            {showError &&
                !condition({
                    city,
                    postalCode,
                    housingType,
                    residenceType,
                    heatingType,
                    priceOption,
                    area,
                    inhabitantsNumber,
                }) && (
                    <p className="survey-container__error">
                        {Wording.error.bottomText}
                    </p>
                )}
        </div>
    );
};

export default SurveyContainer;
