/**
 * @author Ivan Naluzhnyi | Nawin Kim
 * @email [ivan.naluzhnyi@gmail.com]
 * @email [nawin.kim@uppli.fr]
 * @create date 2019-09-16 16:44:55
 * @modify date 2019-09-16 16:50:47
 * @desc [client-space | Uppli]
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import MainScreen from './screens/MainScreen';
import { unregister } from './registerServiceWorker';
import store from './store';
import Config from './utils/config';

import { initFirebase } from './utils/firebase';

import { version } from '../package.json';

import './index.css';

if (Config.ENV !== 'PREPRODUCTION') {
    ReactPixel.init('741997262974944', {}, { debug: false, autoConfig: false });
    ReactPixel.pageView();
}

initFirebase();

const App = () => (
    <Provider store={store}>
        <MainScreen />
    </Provider>
);

console.log('Version : ', version);

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
unregister();

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    const siteWidth = 380;

    const initialScale = window.screen.availWidth / 980;

    document
        .querySelector('meta[name="viewport"]')
        .setAttribute(
            'content',
            `width=${siteWidth}, initial-scale=${initialScale}`
        );
}
