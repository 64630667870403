export const scrollToRefDelay = (ref, delay) => {
    setTimeout(() => {
        if (!ref || !ref.current) return;
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }, delay);
};
