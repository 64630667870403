import React from 'react';
import { connect } from 'react-redux';

import { CustomModal } from '../../components';

import resources from '../../utils/resources';

import WordingData from '../../utils/wording.json';

import * as actions from '../MainScreen/MainScreen.actions';

const Wording = WordingData.SaveScreen;

const SaveDraftModal = ({
    mainState: {
        // saveDraftModal: { open, loading, orderNumber, isValidationEmail },
        saveDraftModal: { open, orderNumber, isValidationEmail },
    },
    closeSaveDraftModalDispatch,
}) => (
    <CustomModal
        show={open}
        showCloseButton
        contentClassName="save-screen-overflow"
        handleClick={() => closeSaveDraftModalDispatch()}
    >
        <div className="save-screen">
            <img
                className="save-screen__img"
                src={resources['ic-kiwi']}
                alt={isValidationEmail ? '' : Wording.text1}
            />
            <h1 className="save-screen__text">
                {isValidationEmail ? Wording.text3 : Wording.text1}
            </h1>
            <h1 className="save-screen__text_2">
                {isValidationEmail ? '' : Wording.text2}
            </h1>
            {orderNumber !== null && (
                <div className="save-screen__displayOrderReference">
                    Voici ton numéro de souscription :<br />
                    <strong>{orderNumber}</strong>
                </div>
            )}
            <div className="save-screen__footer" />
        </div>
    </CustomModal>
);

// export default SaveDraftModal;
const mapStateToProps = state => ({
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    closeSaveDraftModalDispatch: payload =>
        dispatch(actions.closeSaveDraftModal(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveDraftModal);
