import React, { createRef } from 'react';
import { scrollToRefDelay } from '../../utils/scroll';

import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.Common;

const CustomModal = ({
    show,
    showCloseButton,
    handleClick,
    children,
    contentClassName,
}) => {
    const myRef = createRef();
    if (show) scrollToRefDelay(myRef, 200);
    return (
        <div
            role="button"
            tabIndex="0"
            className={`custom-modal${show ? ' custom-modal--show-modal' : ''}`}
            onClick={showCloseButton === undefined ? handleClick : undefined}
            onKeyDown={showCloseButton === undefined ? handleClick : undefined}
        >
            <div
                role="button"
                tabIndex="0"
                className={`custom-modal__content ${contentClassName &&
                    contentClassName}`}
                onClick={e => {
                    e.stopPropagation();
                }}
                onKeyDown={() => {}}
            >
                <div ref={myRef} className="modalToScroll" />
                {showCloseButton && (
                    <span
                        className="custom-modal__content__close-button"
                        onClick={handleClick}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex="0"
                    >
                        {Wording.popup.closeButton}
                    </span>
                )}
                {children}
            </div>
        </div>
    );
};

export default CustomModal;
