import { combineEpics, ofType } from 'redux-observable';

import { of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import {
    SUMMARY_SCREEN_VALIDATE_SUCCESS,
    SUMMARY_SCREEN_CHANGE_OPTION,
    SUMMARY_SCREEN_CHANGE_OFFER,
    SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS,
    SUMMARY_SCREEN_CHANGE_OPTION_FAILURE,
    SUMMARY_SCREEN_GET_SPONSOR,
    SUMMARY_SCREEN_GET_PARTNER,
    CHECK_CONTRACT_PAYMENT_CB,
} from '../types';
import Config from '../../utils/config';
import { log } from '../../utils/logger';
import { controlBillingMode } from '../../utils/helpers';

const validateSummaryScreenSuccess = payload => ({
    type: SUMMARY_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const changeOffer = payload => ({
    type: SUMMARY_SCREEN_CHANGE_OFFER,
    payload,
});

const changeProductOption = payload => ({
    type: SUMMARY_SCREEN_CHANGE_OPTION,
    payload,
});

const changeProductOptionSuccess = payload => ({
    type: SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS,
    payload,
});

const changeProductOptionFailure = payload => ({
    type: SUMMARY_SCREEN_CHANGE_OPTION_FAILURE,
    payload,
});

const getSponsor = payload => ({
    type: SUMMARY_SCREEN_GET_SPONSOR,
    payload,
});

const getPartner = payload => ({
    type: SUMMARY_SCREEN_GET_PARTNER,
    payload,
});

const checkContractPaymentCB = payload => ({
    type: CHECK_CONTRACT_PAYMENT_CB,
    payload,
});

const preparePayloadGetOffer = ({
    contracts,
    chosenProducts,
    userType,
    ...payload
}) => {
    const date = new Date().toISOString().split('T')[0];
    const modifiedContracts =
        contracts &&
        contracts.map(contract => {
            const chosenProduct =
                chosenProducts &&
                chosenProducts.find(p => p.energy === contract.energy);
            const {
                chosenPackages,
                cyclicBill,
                installmentFrequency,
            } = payload[contract.energy];
            const billingModeCode = controlBillingMode(
                cyclicBill,
                payload[contract.energy].billingModeCode
            );

            return {
                ...contract,
                installmentFrequency,
                billingModeCode,
                chosenProduct,
                chosenPackages: chosenPackages || [],
                effectiveStartDate: date,
                subscriptionDate: date,
            };
        });
    return {
        contracts: modifiedContracts,
        customerType: userType,
    };
};

const mapGetOffer = (
    action,
    { apiRequest },
    callbackSuccess,
    callbackFailure
) => {
    const payload = preparePayloadGetOffer(action.payload);
    const option = {
        path: '/getOffers',
        method: 'post',
        body: payload,
    };
    if (Config.FunctionName.getOffers) {
        option.lambda = {
            functionName: Config.FunctionName.getOffers,
        };
    }
    return apiRequest(option).pipe(
        mergeMap(response => {
            if (response.offers) {
                const { offers } = response;
                const result = {};
                ['EL', 'NG'].forEach(energy => {
                    if (action.payload[energy]) {
                        result[energy] = {
                            offer:
                                offers.find(offer => offer.energy === energy) ||
                                {},
                            installmentFrequency:
                                action.payload[energy].installmentFrequency,
                            cyclicBill: action.payload[energy].cyclicBill,
                            chosenPackages:
                                action.payload[energy].chosenPackages,
                            packageID: action.payload[energy].packageID,
                            billingModeCode:
                                action.payload[energy].billingModeCode,
                        };
                    }
                });

                log({
                    ...action.payload.logger,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getOffers',
                    statusAction: 'SUCCESS',
                });
                return of(
                    callbackSuccess({
                        ...result,
                        offers,
                        products: action.payload.products,
                        energyType: action.payload.energyType,
                    })
                );
            }
            const message = response.message || response.errorMessage;

            log({
                ...action.payload.logger,
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/getOffers',
                statusAction: 'FAILURE',
            });
            return throwError({ message });
        }),

        catchError(error =>
            of(
                callbackFailure({
                    error: error.message,
                    energyType: action.payload.energyType,
                })
            )
        )
    );
};

const changeProductOptionEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(SUMMARY_SCREEN_CHANGE_OPTION),
        mergeMap(action => {
            const chosenProducts =
                action.payload.products &&
                action.payload.products.filter(
                    product =>
                        product.productCode === action.payload.productCode
                );
            const modifiedAction = {
                ...action,
                payload: {
                    ...action.payload,
                    chosenProducts,
                },
            };
            return mapGetOffer(
                modifiedAction,
                dependency,
                changeProductOptionSuccess,
                changeProductOptionFailure
            );
        })
    );

const summaryScreenEpic = combineEpics(changeProductOptionEpic);

export {
    validateSummaryScreenSuccess,
    summaryScreenEpic,
    changeProductOption,
    changeProductOptionSuccess,
    changeProductOptionFailure,
    checkContractPaymentCB,
    changeOffer,
    getSponsor,
    getPartner,
    mapGetOffer,
};
