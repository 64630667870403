import React from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';

/**
 * The markup Information for an event Line. You can stack multiple lines on top of eachother
 *
 * @param  {object} props The props from parent, styling and positioning
 * @return {StatelessFunctionalReactComponent} Markup Information for the event line.
 */
const EventsLine = ({
    left,
    width,
    fillingMotion,
    backgroundColor,
    dashed,
}) => {
    const style = {
        position: 'absolute',
        top: 0,
        height: '100%',
        transformOrigin: 'left center',
        backgroundColor,
    };
    if (dashed) {
        style.background = `repeating-linear-gradient(
                to right,
                #b37e07,
                #b37e07 10px,
                #FFF523 10px,
                #FFF523 20px
              )`;
    }
    return (
        <Motion
            style={{
                tWidth: spring(width, fillingMotion),
                tLeft: spring(left, fillingMotion),
            }}
        >
            {({ tWidth, tLeft }) => (
                <span
                    aria-hidden="true"
                    className="timeline-eventline"
                    style={{
                        width: `${tWidth}px`,
                        left: `${tLeft}px`,
                        ...style,
                    }}
                />
            )}
        </Motion>
    );
};

EventsLine.propTypes = {
    // Location and dimension
    left: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    // how the filling motion will look like when in action
    fillingMotion: PropTypes.shape({
        stiffness: PropTypes.number,
        damping: PropTypes.number,
    }).isRequired,
    // What color the line should have
    backgroundColor: PropTypes.string.isRequired,
    dashed: PropTypes.bool.isRequired,
};

export default EventsLine;
