import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import UserContainer from '../../containers/UserContainer';
import * as actions from './UserScreen.actions';

import Screen from '../screen';
import WordingConstant from '../../utils/wording.json';

import { logScreen, TagLogger } from '../../utils/firebase';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import './_UserScreen.scss';
import CustomSelector from '../../components/CustomSelector/CustomSelector';
import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';

const Wording = WordingConstant.UserScreen;

export class UserScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.DATA,
        previousScreen: null,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.USER,
        showSaveButton: false,
        step: 1,
        validate: () => {},
    };

    constructor(props) {
        super(props);
        const { userState } = props;
        this.state = {
            fields: userState,
            setting: {
                isGoingNext: false,
            },
        };
        this.handleValidate = this.handleValidate.bind(this);
    }

    componentDidMount() {
        logScreen(TagLogger.USER);

        const {
            userState: { userType, energyTypes },
            setCanGoNext,
        } = this.props;
        UserScreen.navigationOptions.validate = this.handleValidate;

        let cgn = false;
        if (userType && energyTypes.length > 0) cgn = true;
        if (setCanGoNext) setCanGoNext(cgn);
    }

    componentDidUpdate() {
        const { fields } = this.state;
        const { setCanGoNext } = this.props;
        this.onUpdate();
        if (fields.userType && fields.energyTypes.length > 0)
            setCanGoNext(true);
        else setCanGoNext(false);
    }

    onUpdate() {
        const { fields, setting } = this.state;
        const { nextScreen } = this.props;
        if (
            fields.userType &&
            fields.energyTypes.length > 0 &&
            !setting.isGoingNext
        ) {
            this.setState({ setting: { isGoingNext: true } });
            nextScreen();
        }
        const { mainState, setStepById } = this.props;
        if (mainState.isRecover) {
            setStepById('STEP_HEIGHT_FINAL_RECAP');
        }
    }

    handleOnClick = (value, type) => {
        const { fields, setting } = this.state;
        const { resetScreenTo, isCurrentScreen } = this.props;
        fields[type] = value;
        this.setState({ fields });
        if (!isCurrentScreen) resetScreenTo();
        if (setting.isGoingNext === true) {
            setting.isGoingNext = false;
            this.setState({ setting });
        }
    };

    handleHideBanner = () => {
        const { resetRecoverDispatch } = this.props;
        resetRecoverDispatch();
    };

    checkEphemeral = () => {
        if (
            moment(new Date()).isBetween(
                moment('2022-11-14'),
                moment('2022-11-30')
            )
        ) {
            const title = `Attention :`;
            const messages = [
                `Nous avons momentanément désactivé la souscription à nos offres GAZ!`,
                `L'équipe Wekiwi`,
            ];

            return (
                <div className="user-screen__ephemeralMessage">
                    <div className="user-screen__ephemeralMessage__content">
                        <h3>{title}</h3>
                        {messages.map((m, idx) => (
                            <p key={idx}>{m}</p>
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    };

    handleValidate() {
        const {
            userState: { userType, energyTypes },
            validateUserScreenDispatch,
        } = this.props;
        const { fields } = this.state;
        validateUserScreenDispatch(fields);
        return userType && energyTypes;
    }

    render() {
        const { fields } = this.state;
        const {
            mainState: { errorRecovery },
            isModalOpen,
        } = this.props;
        const { items, typeUser } = Wording;
        return (
            <SectionLayout step={1} isModal={isModalOpen}>
                <div
                    className="user-screen"
                    onClick={this.handleHideBanner}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex="0"
                >
                    {errorRecovery && (
                        <div className="user-screen__invalidLink">
                            <div className="custom-input__layer">?</div>
                            <p>{errorRecovery}</p>
                            <span
                                className=""
                                onClick={this.handleHideBanner}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex="-1"
                            >
                                X
                            </span>
                        </div>
                    )}
                    {this.checkEphemeral()}
                    <div className="user-screen__title-container">
                        <Title
                            titleFormatGowun="Qui"
                            titleFormatLexand="suis-je ?"
                        />
                        <Typography className="subtitle-p2">
                            Afin de bien t’accompagner dans ta souscription, dis
                            nous <br /> si tu es un particulier ou un
                            professionnel !
                        </Typography>
                    </div>

                    <CustomSelector
                        options={[
                            {
                                id: typeUser.values[0].id,
                                label: (
                                    <Typography>
                                        Je suis un{' '}
                                        <span style={{ fontWeight: 400 }}>
                                            particulier
                                        </span>
                                    </Typography>
                                ),
                            },
                            {
                                id: typeUser.values[1].id,
                                label: (
                                    <Typography>
                                        Je suis un{' '}
                                        <span style={{ fontWeight: 400 }}>
                                            professionnel
                                        </span>
                                    </Typography>
                                ),
                            },
                        ]}
                        value={fields.userType}
                        onSelect={e => this.handleOnClick(e, 'userType')}
                        gap="4rem"
                        notFullWidth
                    />
                    <div className="user-screen__title-container">
                        <Title
                            titleFormatGowun="Type d’"
                            titleFormatLexand="énergie"
                            noGap
                        />
                        <Typography className="subtitle-p2">
                            Besoin de lumière ou bien de quoi vous chauffer ?
                            Wekiwi vous propose de l’électricité, du gaz, ou les
                            deux !
                        </Typography>
                    </div>
                    {items &&
                        items.map(({ itemType, title, values }, index) => (
                            <UserContainer
                                key={index}
                                title={title}
                                choiceType={fields[itemType]}
                                imageList={values}
                                onClick={value =>
                                    this.handleOnClick(value, itemType)
                                }
                            />
                        ))}
                </div>
            </SectionLayout>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validateUserScreenDispatch: payload =>
        dispatch(actions.validateUserScreenSuccess(payload)),
    resetRecoverDispatch: payload => dispatch(actions.resetRecover(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserScreen);
