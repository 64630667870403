import AES256 from 'aes-everywhere';

import Config from './config';
import { toMonth_, fromMonth_ } from '../components/CustomDate/CustomDate';
import WordingConstant from './wording.json';
import { BillingMode, SituationTypes } from '../constants/enums';

export const Wording = WordingConstant.Price;
export const _MS_PER_DAY = 1000 * 60 * 60 * 24;

const UserWording = WordingConstant.UserScreen;
const DataWording = WordingConstant.DataScreen;


const offerSimple = (offers) => {
   return offers.some(offer => {
        return offer.product.productCode.includes("OFFRE-SIMPLE")
      })
}

const log = (title, message) => {
    const debug = Config.ENV === 'PREPRODUCTION';
    if (debug) {
        console.log(title, message);
    }
};

const pad = s => (s < 10 ? `0${s}` : s);

const getFormattedDate = date => {
    const d = new Date(date);
    return d
        ? [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
        : undefined;
};

const getFormattedDateToBO = date => {
    const d = new Date(date);
    return d
        ? [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
        : undefined;
};

const areListEqual = (arr1, arr2) => {
    if (
        arr1 === undefined ||
        arr2 === undefined ||
        arr1.length !== arr2.length
    ) {
        return false;
    }
    const arr = arr1.filter(e => !arr2.includes(e));
    return arr.length === 0;
};

const sendImage = (choiceType, value, imageSrcOn, imageSrc) => {
    if (typeof choiceType === 'string') return imageSrc;
    if (areListEqual(choiceType, value)) return imageSrcOn;
    return imageSrc;
};

const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

const isWeekend = date => {
    const dt = new Date(date);
    return dt.getDay() === 6 || dt.getDay() === 0;
};

const getDateString = date => {
    const day = `${date.date < 10 ? `0${date.date}` : date.date}`;
    const month = `${
        date.month + 1 < 10 ? `0${date.month + 1}` : date.month + 1
    }`;
    return `${date.year}-${month}-${day}`;
};

const getDateEffectiveString = date => {
    const day = `${date.dateEffectiveStartDate < 10 ? `0${date.dateEffectiveStartDate}` : date.dateEffectiveStartDate}`;
    const month = `${
        date.monthEffectiveStartDate + 1 < 10 ? `0${date.monthEffectiveStartDate + 1}` : date.monthEffectiveStartDate + 1
    }`;
    return `${date.yearEffectiveStartDate}-${month}-${day}`;
};

const getTodayDateString = () => {
    const today = new Date();
    const date = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();
    return getDateString({
        date,
        month,
        year,
    });
};

const dateDiffDays = date => {
    const today = new Date().toISOString().split('T')[0];
    const d1 = new Date(today);
    const d2 = new Date(date);
    return Math.floor((d1 - d2) / _MS_PER_DAY);
};

const handleConditionEmail = value => {
    const regex = /^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()/[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value);
};

const handleConditionPhone = value => {
    const regex = /^((06)|(07))[0-9]{8}$/;
    return regex.test(String(value));
};

const handleConditionPDLPCE = value => {
    const regex = /^[0-9]{14}$/;
    return regex.test(value);
};

const mergeArray = (arr1, arr2, key) => {
    if (arr1 === undefined || arr1.length === 0) {
        return arr2;
    }
    if (arr2 === undefined || arr2.length === 0) {
        return arr1;
    }
    const arr = [...arr1];
    const temp = arr.map(a => a[key]);
    arr2.forEach(a => {
        if (temp.indexOf(a[key]) < 0) {
            arr.push(a);
        }
    });
    return arr;
};

const addBusinessDays = (days, date = new Date()) => {
    let nDays = days;
    const now = date;
    const dayOfTheWeek = now.getDay();
    const deliveryDay = dayOfTheWeek + days;
    let calendarDays = days;
    let deliveryWeeks;
    if (deliveryDay >= 6) {
        nDays -= 6 - dayOfTheWeek;
        calendarDays += 2;
        deliveryWeeks = Math.floor(nDays / 5);
        calendarDays += deliveryWeeks * 2;
    }
    now.setTime(now.getTime() + calendarDays * 24 * 60 * 60 * 1000);
    return now;
};

const transformLabel = label => {
    if (label === Wording.totalhour.label) {
        return Wording.totalhour.text;
    }
    if (label === Wording.high.label) {
        return Wording.high.text;
    }
    if (label === Wording.low.label) {
        return Wording.low.text;
    }
    if (label === Wording.paymentFrequency.label) {
        return Wording.paymentFrequency.text;
    }
    return label;
};

const displayPhone = phone => {
    if (!phone) {
        return '';
    }
    if (phone.startsWith('+33')) {
        return `0${phone.substring(3)}`;
    }
    return phone;
};

const parseQueryString = search => {
    if (search === undefined) {
        return {};
    }
    try {
        return JSON.parse(
            `{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
            (key, value) => (key === '' ? value : decodeURIComponent(value))
        );
    } catch (e) {
        return {};
    }
};

const isEmptyObject = obj =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

const validUserType = s =>
    s === UserWording.userType.individual ||
    s === UserWording.userType.professional;

const validEnergyTypes = energyTypes => {
    if (energyTypes === undefined) {
        return false;
    }
    if (energyTypes.length === 0) {
        return false;
    }
    const types = [UserWording.energyType.EL, UserWording.energyType.NG];
    const reducer = (accumulator, currentValue) =>
        accumulator && types.indexOf(currentValue) !== -1;
    return energyTypes.reduce(reducer, true);
};

const validCivility = s => {
    if (s === undefined) {
        return false;
    }
    return s === 'MR' || s === 'MRS' || s === 'MS';
};

const validCompanyType = s => {
    if (s === undefined) {
        return false;
    }
    const value = DataWording.legalForm.values.find(v => v.value === s);
    return value !== undefined;
};

const filteredArrNoDuplicates = (arr, type) =>
    arr.reduce((acc, current) => {
        const x = acc.find(item => item[type] === current[type]);
        if (!x) {
            return acc.concat([current]);
        }
        return acc;
    }, []);

const getPointOfDeliveryLogger = pointOfDelivery => ({
    energyList:
        pointOfDelivery.length === 2 ? 'EL/NG' : pointOfDelivery[0].energy,
    customerIdentificationKey:
        pointOfDelivery.length === 2
            ? `${pointOfDelivery[0].reference} | ${pointOfDelivery[1].reference}`
            : pointOfDelivery[0].reference,
});

const multiplicationInstallmentFrequency = (type, amount) => {
    switch (type) {
        case 'MONTHLY':
            return amount * 1;
        case 'BIMONTHLY':
            return amount * 2;
        case 'QUARTERLY':
            return amount * 3;
        case 'FOURMONTHLY':
            return amount * 4;
        default:
            return amount;
    }
};

const sumPackageAmountInstallmentFrequency = (
    chosenPackages,
    installmentFrequency
) => {
    if (!chosenPackages || !Array.isArray(chosenPackages)) {
        return 0; 
    }

    return chosenPackages.reduce(
        (a, b) =>
            a +
            (multiplicationInstallmentFrequency(
                installmentFrequency,
                parseFloat(b.amount)
            ) || 0),
        0
    );
};

const camelToUpperCase = str =>
    Number.isNaN(Number(str)) ? str.toUpperCase() : str;

const birthCondition = birthdate => {
    const year = new Date(birthdate).getFullYear();
    return year > toMonth_.getFullYear() || year < fromMonth_.getFullYear();
};

const removeFirstPaymentCBFromContract = contract => {
    if (contract.firstPaymentCB !== undefined) {
        const object = contract;
        delete object.firstPaymentCB;
        return { ...object };
    }

    return { ...contract };
};

const removeCodeFromCity = city =>
    city
        .split(' ')
        .map(el => (!Number.isInteger(Number(el)) ? el : ''))
        .join(' ')
        .trim();

const handleResetStore = withUrl => {
    localStorage.clear();

    if (withUrl) {
        document.location = window.location.href.split('?')[0];
    } else window.location.reload();
};

const getInitialChannel = group => group.split('_')[0];
const getInitialretryCB = group => group.split('_')[0];

const checkPathOrder = path => path === '/signOrder' || path === '/saveOrder' || path === '/signContract';

const additionalRatesDuplicateValue = arr => {
    let addRate = {};
    const init = [];
    arr.forEach(element => {
        if (element.categoryCode && element.categoryCode.includes('PP_FREQ')) {
            if (!addRate.amount) {
                addRate = { ...element };
            } else {
                addRate.amount = (
                    parseFloat(addRate.amount) + parseFloat(element.amount)
                ).toFixed(2);
            }
        } else init.push(element);
    });

    return [...init, { ...addRate }];
};

const buildPackagesForSlider = packages => {
    const init = [[]];

    let nbrElmInSection = 0;
    let countSectionNbr = 0;

    let recommendedInfo = [];

    packages.forEach(pkg => {
        if (nbrElmInSection < 4) {
            init[countSectionNbr].push(pkg);

            if (pkg.recommended) {
                recommendedInfo = [countSectionNbr, nbrElmInSection];
            }
            nbrElmInSection += 1;
        } else {
            init.push([]);

            countSectionNbr += 1;
            nbrElmInSection = 1;

            init[countSectionNbr].push(pkg);

            if (pkg.recommended) {
                recommendedInfo = [countSectionNbr, nbrElmInSection];
            }
        }
    });

    let finalBuild = [];
    let first = [];
    let isRecommendedPush = false;

    init.forEach((arr, idx) => {
        if (idx === recommendedInfo[0]) {
            isRecommendedPush = true;
        }

        if (isRecommendedPush) finalBuild = [...finalBuild, ...arr];
        else first = [...first, ...arr];
    });

    return [...finalBuild, ...first];
};

/**
 *
 * @param {Date} date
 * @param {'EL' | 'NG'} energy
 * @param {'NOW' | 'LATER'} type
 * @param {string} billingModeCode
 *
 * *SWITCH_NOW
 * ---------- EL => dueDate + 7 business + 2 calenders days
 * ---------- NG => dueDate + 7 business + 4 calenders days
 *
 * *SWITCH_LATER
 * ---------- EL => dueDate + 7 business + 2 calenders
 * ---------- NG => dueDate + 7 business + 4 calenders
 */
const prepareEffectiveStartDate = (date, energy, type, billingModeCode) => {
    const { EffectiveStartDate, EffectiveStartDateWithoutPack } = Config;

    const ControlConfig =
        billingModeCode !== undefined &&
        billingModeCode === BillingMode.PAYMENT_SCHEDULE_WITHOUT_PACK
            ? EffectiveStartDateWithoutPack
            : EffectiveStartDate;

    const { businessDays, calendarDays } = ControlConfig[type][energy];

    const nextBusinessDate = addBusinessDays(businessDays, date);
    const nextCalendarDate = addDays(nextBusinessDate, calendarDays);

    return nextCalendarDate;
};

const prepareCyclicBillDate = (energy, type) =>
    addDays(new Date(), Config.DefaultSwitchDueDate[type][energy]);

const addMonth = (date, nmMonth) =>
    new Date(date.setMonth(date.getMonth() + nmMonth));

const prepareFrequenciesToDisplay = arr => {
    const priorityOrder = {
        "FOURMONTHLY": 1,
        "QUARTERLY": 2,
        "BIMONTHLY": 3,
        "MONTHLY": 4
    };

    return arr.sort((a, b) => {
        const priorityA = priorityOrder[a.frequency] || 5;
        const priorityB = priorityOrder[b.frequency] || 5;

        return priorityA - priorityB;
    });
};

const searchParamsFromURL = () => {
    const url =
        window.location !== window.parent.location
            ? document.referrer
            : document.location.href;
    return new URL(url).searchParams;
};

const getParamsFromDecodedUrl = () => {
    const sparams = searchParamsFromURL().get('sparams');
    if (sparams) {
        try {
            const decodedUrl = AES256.decrypt(
                sparams.replaceAll(' ', '+'),
                Config.URL_PASSPHRASE
            );

            const paramsFromUrl = new URL(
                `${window.location.origin}?${decodedUrl}`
            ).searchParams;
            const payload = {};
            paramsFromUrl.forEach((value, k) => {
                payload[k] = value;
            });
            const energyTypes = paramsFromUrl.getAll('energyTypes[]');
            if (payload['energyTypes[]']) delete payload['energyTypes[]'];

            return {
                ...payload,
                energyTypes,
            };
        } catch (error) {
            console.warn('URL =>', sparams);
            console.error('URL ERROR =>', error);

            return {};
        }
    }

    return null;
};

const getAPEFromObject = obj =>
    obj
        ? obj.etablissement.uniteLegale.activitePrincipaleUniteLegale.replaceAll(
              '.',
              ''
          )
        : '';

const isScheduleWithoutPackBillingMode = autorizedBillingModes =>
    Boolean(
        autorizedBillingModes &&
            autorizedBillingModes.find(
                mode =>
                    mode.billingModeCode ===
                    BillingMode.PAYMENT_SCHEDULE_WITHOUT_PACK
            )
    );

const controlBillingMode = (cyclicBill, code) => {
    if (cyclicBill) {
        return BillingMode.CYCLICAL_BILLING;
    }

    if (code) {
        if (typeof code === 'string') return code;

        return code.billingModeCode;
    }

    return BillingMode.PAYMENT_SCHEDULE;
};

const checkIsDisableSwitchSelect = situation => {
    if (situation === SituationTypes.MOVE_IN) return false;

    const channel = localStorage.getItem('channel');

    if (channel === null) return false;

    return Config.ChannelsFixSwitchLater.some(curr => curr.test(channel));
};

const getSwitchLater = () => DataWording.contractDate.dropdownValues[1];

const pdfToBase64 = file =>
    new Promise((resolve, reject) => {
        fetch(file)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            })
            .catch(e => reject(e));
    });

export {
    offerSimple,
    log,
    controlBillingMode,
    getSwitchLater,
    checkIsDisableSwitchSelect,
    getAPEFromObject,
    isScheduleWithoutPackBillingMode,
    getParamsFromDecodedUrl,
    searchParamsFromURL,
    prepareCyclicBillDate,
    prepareFrequenciesToDisplay,
    addMonth,
    additionalRatesDuplicateValue,
    removeFirstPaymentCBFromContract,
    buildPackagesForSlider,
    getInitialChannel,
    getInitialretryCB,
    handleResetStore,
    checkPathOrder,
    birthCondition,
    removeCodeFromCity,
    multiplicationInstallmentFrequency,
    sumPackageAmountInstallmentFrequency,
    camelToUpperCase,
    filteredArrNoDuplicates,
    getPointOfDeliveryLogger,
    areListEqual,
    sendImage,
    addDays,
    isWeekend,
    getDateString,
    getTodayDateString,
    handleConditionEmail,
    handleConditionPhone,
    mergeArray,
    addBusinessDays,
    dateDiffDays,
    transformLabel,
    displayPhone,
    parseQueryString,
    isEmptyObject,
    validUserType,
    validEnergyTypes,
    validCivility,
    validCompanyType,
    handleConditionPDLPCE,
    getFormattedDate,
    prepareEffectiveStartDate,
    getFormattedDateToBO,
    pdfToBase64,
    getDateEffectiveString,
};
