import React, { useEffect, useState } from 'react';

import { endOfWeek, format, isWithinInterval, startOfWeek } from 'date-fns';
import { fr } from 'date-fns/locale';

import { DayPicker } from 'react-day-picker/DayPicker';
import { formatDate, parseDate } from 'react-day-picker/moment';

import WordingConstant from '../../utils/wording.json';
import resources from '../../utils/resources';
import icArrowDown from '../../assets/icons/ic-arrow-down.svg';
import * as helpers from '../../utils/helpers';

const Wording = WordingConstant.DataScreen;

const CustomCalendar = ({
    currentDate,
    handleOnSelectedDays,
    handleOnDisabledDays,
    handleInputOnFocus,
    handleOnDayChange,
    handleOnDayPickerHide,
}) => {
    const [toggle, setToggle] = useState(false);
    const [currentValue, setCurrentValue] = useState(currentDate);

    const selectedDays = handleOnSelectedDays();
    const disabledDays = handleOnDisabledDays();
    let firstAvailableDay = helpers.addDays(new Date(), 1);

    disabledDays.forEach(elem => {
        if (
            elem.before &&
            elem.before.getTime() >= firstAvailableDay.getTime()
        ) {
            firstAvailableDay = elem.before;
        }
    });

    useEffect(() => {
        const weeks = document.querySelectorAll('.DayPicker-Week');
        for (let i = 0; i < weeks.length; i += 1) {
            if (weeks[i].innerHTML.includes('hide-me')) {
                weeks[i].style.display = 'none';
            } else {
                weeks[i].style.display = 'table-row';
            }
        }
    }, [toggle]);

    useEffect(() => {
        setCurrentValue(currentDate);
    }, [currentDate]);

    function renderDay(day) {
        const date = day.getDate();
        const locale = { locale: fr };
        const isDateInCurrentWeek = dateToCheck => {
            const today = currentDate
                ? new Date(currentDate)
                : firstAvailableDay;
            const start = startOfWeek(today, locale);
            const end = endOfWeek(today, locale);
            return isWithinInterval(dateToCheck, { start, end });
        };
        const isNotCurrentWeek = !isDateInCurrentWeek(day);
        return (
            <div
                className={
                    !toggle && isNotCurrentWeek
                        ? 'DayPicker-Day__container hide-me'
                        : 'DayPicker-Day__container'
                }
            >
                <img
                    className="day-warning"
                    alt="warning"
                    src={resources['ic-warning']}
                />
                <div className="day-name">
                    {[...format(day, 'E', locale).split('.')[0]]
                        .map((char, index) =>
                            index === 0 ? char.toUpperCase() : char
                        )
                        .join('')}
                </div>
                <div className="day-number">{date}</div>
            </div>
        );
    }

    return (
        <div
            className={`calendar-picker ${!toggle &&
                'calendar-picker--simplified'}`}
        >
            <div className="calendar-picker__container__toggle-text">
                Sélectionner date :
            </div>
            <div className="calendar-picker__container">
                <div className="calendar-picker__container__calendar">
                    <DayPicker
                        mode="single"
                        renderDay={renderDay}
                        showOutsideDays
                        placeholder="JJ/MM/AAAA"
                        format="DD/MM/YYYY"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        selectedDays={selectedDays}
                        disabledDays={disabledDays}
                        className="selectable"
                        locale={Wording.contractDate.LOCAL}
                        months={
                            Wording.contractDate.MONTH[
                                Wording.contractDate.LOCAL
                            ]
                        }
                        weekdaysShort={
                            Wording.contractDate.WEEKDAYS_SHORT[
                                Wording.contractDate.LOCAL
                            ]
                        }
                        weekdaysLong={
                            Wording.contractDate.WEEKDAYS_LONG[
                                Wording.contractDate.LOCAL
                            ]
                        }
                        firstDayOfWeek={
                            Wording.contractDate.FIRST_DAY_OF_WEEK[
                                Wording.contractDate.LOCAL
                            ]
                        }
                        modifiers={{
                            highlighted: currentDate,
                        }}
                        keepFocus
                        inputProps={{
                            readOnly: true,
                            value: currentValue,
                            onFocus: () => handleInputOnFocus,
                        }}
                        onDayChange={d => {
                            handleOnDayChange(d);
                        }}
                        onDayClick={d => {
                            handleOnDayChange(d);
                        }}
                        hideOnDayClick={false}
                        onDayPickerHide={handleOnDayPickerHide}
                        value={currentValue}
                        initialMonth={
                            currentValue
                                ? new Date(currentValue)
                                : new Date(firstAvailableDay)
                        }
                        month={currentValue}
                    />
                </div>
                <button
                    className="DayPicker-Expande-button"
                    type="button"
                    onClick={() => setToggle(!toggle)}
                >
                    <img
                        alt="Accordion arrow indicator"
                        className={`accordion-root__calendar__arrow-${
                            toggle ? 'open' : 'close'
                        }`}
                        src={icArrowDown}
                    />
                </button>
            </div>
        </div>
    );
};

export default CustomCalendar;
