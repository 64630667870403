import React, { createRef } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';

import { CustomAccordion, CustomModal } from '../../components';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';
import { BillingMode } from '../../constants/enums';
import PackageContainer from '../../containers/PackageContainer';
import { logScreen } from '../../utils/firebase';
import { sumPackageAmountInstallmentFrequency } from '../../utils/helpers';
import { TagLogger, TriggerButtonLogger } from '../../utils/logger';
import WordingConstant from '../../utils/wording.json';
import { changePackages } from '../FinalSummaryScreen/FinalSummaryScreen.actions';
import {
    changeProductOption,
    changeOffer,
} from '../SummaryScreen/SummaryScreen.actions';
import Screen from '../screen';
import { validatePackageScreen } from './PackageScreen.actions';
import CustomButton from '../../components/CustomButton/CustomButton';
import { scrollToRefDelay } from '../../utils/scroll';
import LoadingOverlay from '../../components/LoadingOverlay';

const Wording = WordingConstant.PackageScreen;

const kiwiInstructionVideo = `${process.env.PUBLIC_URL}/static/wekiwi-offer-short.mov`;

export class PackageScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.SUMMARY,
        previousScreen: Screen.CODE,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.PACKAGE,
        showSaveButton: false,
        step: 4,
        validate: () => {},
    };

    constructor(props) {
        super(props);
        this.state = this.setupPackages();
        this.myRef = createRef();
    }

    setupPackages = cb => {
        const { packageState, summaryState } = this.props;
        const cyclicBillingMode =
            packageState.autorizedBillingModes &&
            packageState.autorizedBillingModes.find(
                mode => mode.billingModeCode === BillingMode.CYCLICAL_BILLING
            );
        const scheduleBillingMode =
            packageState.autorizedBillingModes &&
            packageState.autorizedBillingModes.find(
                mode => mode.billingModeCode === BillingMode.PAYMENT_SCHEDULE
            );

        const scheduleWithoutPackBillingMode =
            packageState.autorizedBillingModes &&
            packageState.autorizedBillingModes.find(
                mode =>
                    mode.billingModeCode ===
                    BillingMode.PAYMENT_SCHEDULE_WITHOUT_PACK
            );

        const fields = {};
        ['EL', 'NG'].forEach(energy => {
            fields[energy] = {
                installmentFrequency: summaryState[energy].installmentFrequency,
                cyclicBill: summaryState[energy].cyclicBill,
                chosenPackages:
                    summaryState[energy].chosenPackages || [
                        packageState.packages.find(p => p.recommended),
                    ] ||
                    [],
                packageID: summaryState[energy].packageID,
                billingModeCode: summaryState[energy].billingModeCode,
            };
        });

        const state = {
            fields,
            setting: {
                showError: false,
            },
            showingModalVideo: false,
            cyclicBillingMode,
            scheduleBillingMode,
            scheduleWithoutPackBillingMode,
            raw: packageState.autorizedBillingModes,
        };
        if (cb) cb(state);
        return state;
    };

    componentDidMount() {
        logScreen(TagLogger.PACKAGE);

        const {
            validatePackageScreenDispatch,
            userState: { userType, energyTypes },
            summaryState: { EL, NG },
        } = this.props;

        PackageScreen.navigationOptions.validate = () => {
            const { fields } = this.state;
            const {
                summaryState: { contracts },
            } = this.props;
            validatePackageScreenDispatch({
                ...fields,
                userType,
                energyTypes,
                contracts,
                logger: {
                    tag: TagLogger.PACKAGE,
                    triggerButton: TriggerButtonLogger.VALIDATE_BTN,
                },
            });
            return true;
        };

        PackageScreen.navigationOptions.disabled = () => {
            const { setting } = this.state;
            setting.showError = true;
            this.setState({ setting });
        };

        PackageScreen.navigationOptions.title =
            energyTypes.length > 1 ? Wording.secondaryTitle : Wording.title;

        // if (setCanGoNext) setCanGoNext(true);
        this.handleNextStep();

        const fields = {
            EL,
            NG,
        };
        this.handleCondition({ fields, energyTypes });

        this.handlePreselectPackage();
        scrollToRefDelay(this.myRef, 200);
        // let isCyclic = false;
        // for (let i = 0; i < energyTypes.length; i += 1) {
        //     if (fields[energyTypes[i]].cyclicBill === true) {
        //         isCyclic = true;
        //         break;
        //     }
        // }
        // this.setState({ cyclicBillingMode: isCyclic });
    }

    componentDidUpdate() {
        const { fields, raw } = this.state;
        const {
            userState: { energyTypes },
            packageState,
        } = this.props;

        this.handleNextStep();
        this.handleCondition({ fields, energyTypes });
        if (raw !== packageState.autorizedBillingModes) {
            this.setupPackages(data => this.setState(data));
        }
    }

    handleNextStep() {
        const { fields, cyclicBillingMode } = this.state;
        const {
            userState: { energyTypes },
            setCanGoNext,
            summaryState,
        } = this.props;
        let canGoNext = true;
        let isCyclic = false;
        for (let i = 0; i < energyTypes.length; i += 1) {
            if (summaryState[energyTypes[i]].cyclicBill === true) {
                isCyclic = true;
                canGoNext = true;
                break;
            }
            if (
                fields[energyTypes[i]].installmentFrequency === '' ||
                fields[energyTypes[i]].packageID === ''
            ) {
                canGoNext = false;
            }
        }
        if (isCyclic !== cyclicBillingMode) {
            this.setupPackages(data =>
                this.setState({ ...data, cyclicBillingMode: isCyclic })
            );
        }
        setCanGoNext(canGoNext);
    }

    handleCondition = ({ fields, energyTypes }) => {
        const { handleGoNext } = this.props;
        if (handleGoNext) {
            const reducer = (accumulator, currentValue) => {
                const { installmentFrequency, packageID, cyclicBill } = fields[
                    currentValue
                ];
                return (
                    accumulator &&
                    ((installmentFrequency &&
                        installmentFrequency !== '' &&
                        packageID !== '') ||
                        cyclicBill)
                );
            };
            const condition = energyTypes.reduce(reducer, true);
            handleGoNext(condition);
        }
    };

    conditionSumFirstPayment = () => {
        const { fields } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;

        const reducer = (accumulator, currentValue) => {
            const { installmentFrequency, packageID, cyclicBill } = fields[
                currentValue
            ];

            if (energyTypes.length === 2) {
                return (
                    accumulator &&
                    ((installmentFrequency &&
                        installmentFrequency !== '' &&
                        packageID !== '') ||
                        cyclicBill)
                );
            }

            return (
                accumulator &&
                installmentFrequency &&
                installmentFrequency !== '' &&
                packageID !== '' &&
                !cyclicBill
            );
        };

        const allCheckedCyclicBill = energyTypes.filter(
            el => fields[el].cyclicBill === true
        ).length;

        return allCheckedCyclicBill !== 2 && energyTypes.reduce(reducer, true);
    };

    handleDisplaySum = () => {
        const { fields } = this.state;
        const {
            userState: { energyTypes },
        } = this.props;
        const reducer = (accumulator, currentValue) => {
            const { chosenPackages, installmentFrequency } = fields[
                currentValue
            ];

            return (
                accumulator +
                sumPackageAmountInstallmentFrequency(
                    chosenPackages,
                    installmentFrequency
                )
            );
        };
        return this.conditionSumFirstPayment() ? (
            <p className="package-screen__sum-first-payment">{`${
                Wording.sumFirstPayment
            } ${energyTypes.reduce(reducer, 0)}€`}</p>
        ) : (
            undefined
        );
    };

    handleChoosePackage = (id, kind) => {
        const {
            fields,
            cyclicBillingMode,
            scheduleWithoutPackBillingMode,
            scheduleBillingMode,
        } = this.state;
        const {
            packageState: { packages },
            resetScreenTo,
            isCurrentScreen,
        } = this.props;

        if (!isCurrentScreen && resetScreenTo) resetScreenTo();
        if (cyclicBillingMode && fields[kind].cyclicBill) return;
        fields[kind].packageID = id;
        const pkg = packages.find(p => p.id === id);
        fields[kind].chosenPackages = pkg ? [pkg] : [];

        fields[kind].billingModeCode =
            scheduleWithoutPackBillingMode !== undefined
                ? scheduleWithoutPackBillingMode.billingModeCode
                : scheduleBillingMode.billingModeCode;
        this.setState({ fields });
    };

    handleChangeInstallmentFrequency = (e, kind) => {
        const { value } = e.target;
        const { fields, cyclicBillingMode } = this.state;
        const { resetScreenTo, isCurrentScreen } = this.props;
        if (!isCurrentScreen) resetScreenTo();
        if (cyclicBillingMode && fields[kind].cyclicBill) {
            return;
        }
        fields[kind].installmentFrequency = value;
        fields[kind].cyclicBill = false;
        this.setState({ fields });
    };

    handleChangeCyclicBill = kind => {
        const { fields } = this.state;
        fields[kind].cyclicBill = !fields[kind].cyclicBill;
        fields[kind].packageID = '';
        fields[kind].chosenPackages = [];
        fields[kind].installmentFrequency = '';
        this.setState({ fields });
    };

    handleOpenModalVideo = () => {
        const { showingModalVideo } = this.state;
        this.setState({ showingModalVideo: !showingModalVideo });
        if (this.videoNode && !showingModalVideo) {
            this.videoNode.pause();
            this.videoNode.currentTime = 0;
            this.videoNode.controls = true;
            this.videoNode.play();
        }
    };

    renderModalVideo() {
        const { showingModalVideo } = this.state;
        return (
            <CustomModal
                show={showingModalVideo}
                showCloseButton
                handleClick={this.handleOpenModalVideo}
            >
                <div className="package-screen__modal__content">
                    <div className="package-screen__modal__content__title">
                        <div className="custom-input__layer">?</div>
                        <p>{Wording.popup.package.title}</p>
                    </div>
                    <ReactPlayer
                        ref={ref => {
                            this.playerRef = ref;
                        }}
                        url={showingModalVideo ? kiwiInstructionVideo : null}
                        playing={showingModalVideo}
                        autoPlay
                        controls
                        width="100%"
                        heigh="100%"
                    />
                </div>
            </CustomModal>
        );
    }

    handlePreselectPackage() {
        const {
            userState: { energyTypes },
            packageState: { packages, prepaymentFrequencies },
        } = this.props;

        if (energyTypes) {
            energyTypes.forEach(energy => {
                const packageList = packages.filter(p => p.energy === energy);

                if (packageList.length === 1) {
                    const { id } = packageList[0];
                    this.handleChoosePackage(id, energy);
                }

                if (prepaymentFrequencies.length === 1) {
                    const { frequency } = prepaymentFrequencies[0];
                    this.handleChangeInstallmentFrequency(
                        { target: { value: frequency } },
                        energy
                    );
                }
            });
        }
    }

    transformAuthorizedPackages = packages => {
        const res =
            packages &&
            packages
                .map(p => {
                    if (p && p.length === 2) {
                        const tmp = p
                            .sort((x, y) =>
                                x.timeframe < y.timeframe ? -1 : 1
                            )
                            .map(x => parseInt(x.amount, 0));
                        return [...tmp, ...p.map(it => it.quantityMonthly)];
                    }
                    return [0, 0, ...p.map(it => it.quantityMonthly)];
                })
                .sort((x, y) => (x[0] < y[0] ? -1 : 1));
        return res;
    };

    findPackages = (packages, couple) => {
        const res = (packages &&
            packages.find(
                p =>
                    p &&
                    p.length === 2 &&
                    p[0].amount === `${couple[0]}` &&
                    p[1].amount === `${couple[1]}`
            )) || [{}, {}];
        return res;
    };

    handleChangePackageFields = (prices, packageID, energy) => {
        const { fields } = this.state;
        const { isCurrentScreen, resetScreenTo } = this.props;
        fields[energy].prices = prices;
        // fields[energy].packageID = packageID;
        if (!isCurrentScreen) resetScreenTo();
        const {
            // changePackagesDispatch,
            packageState,
            // summaryState,
        } = this.props;
        let packages = [];
        let id = packageID;
        if (packageID === '') {
            fields[energy].lastPackageID = packageID;
            id = fields[energy].packageID;
            const currentPackage =
                packageState.packages.find(p => p.id === id) ||
                packageState.packages.find(p => p.recommended);

            if (currentPackage) {
                packages = this.findPackages(
                    currentPackage.authorizedPackages,
                    prices
                );

                id = currentPackage.id;
            }
        } else {
            fields[energy].lastPrices = prices;
            packages = packageState.packages
                ? packageState.packages.filter(
                      p => p.energy === energy && p.id === packageID
                  )
                : [];
        }
        fields[energy].chosenPackages = packages;
        // changePackagesDispatch({ packages, packageID: id, energyType: energy });
        this.setState({ fields });
    };

    getEnergyValue = energy =>
        energy === 'EL' ? ' forfait électricité' : ' forfait gaz';

    render() {
        const {
            userState: { energyTypes },
            packageState: { packages, prepaymentFrequencies },
            summaryState,
            userState,
            changeProductOptionDispatch,
            changePackagesDispatch,
            mainState: { loading },
            nextScreen,
            canGoNext,
            isCurrentScreen,
            isModalOpen,
            changeOfferDispatch,
        } = this.props;
        const {
            fields,
            setting: { showError },
            cyclicBillingMode,
            scheduleBillingMode,
            scheduleWithoutPackBillingMode,
        } = this.state;
        // let forfaitType;

        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout
                    step={PackageScreen.navigationOptions.step}
                    isModal={isModalOpen}
                >
                    <div className="package-screen">
                        {this.renderModalVideo()}
                        <Title
                            titleFormatGowun={`Je choisis  ${
                                +energyTypes.length > 1 ? 'mes' : 'mon'
                            }`}
                            titleFormatLexand={
                                energyTypes.length > 1
                                    ? ' forfaits'
                                    : this.getEnergyValue(energyTypes[0])
                            }
                        />
                        <Typography
                            style={{
                                marginBottom: '1rem',
                                letterSpacing: '0.1px',
                            }}
                        >
                            Le forfait mensuel suggéré se base sur tes
                            consommations historiques. Cependant tu peux choisir
                            ton propre forfait, nous t’invitons à prendre une
                            légère marge afin d’être sûr de ne pas le dépasser !
                        </Typography>

                        {/* {!scheduleWithoutPackBillingMode && (
                        <div
                            className="package-screen__instruction"
                            onClick={this.handleOpenModalVideo}
                            tabIndex="0"
                            onKeyPress={() => {}}
                            role="button"
                        >
                            {Wording.instruction}
                        </div>
                    )} */}

                        <CustomAccordion title="Le petit cours de Kiwi : C’est quoi le forfait Wekiwi ?">
                            <Typography
                                style={{
                                    marginBottom: '16px',
                                    marginTop: '16px',
                                }}
                            >
                                {Wording.about.description}
                            </Typography>
                            <Typography style={{ marginBottom: '16px' }}>
                                {Wording.about.choiceText}
                            </Typography>
                            <ul>
                                <li>
                                    {Wording.about.packageDiscount.main}{' '}
                                    <Typography style={{ display: 'inline' }}>
                                        {
                                            Wording.about.packageDiscount
                                                .description
                                        }
                                    </Typography>
                                </li>
                                <li>
                                    {Wording.about.digitalDiscount.main}{' '}
                                    <Typography style={{ display: 'inline' }}>
                                        {
                                            Wording.about.digitalDiscount
                                                .description
                                        }
                                    </Typography>
                                </li>
                            </ul>
                            <Typography>
                                {Wording.about.descriptionBottom}
                            </Typography>
                            <Typography style={{ fontWeight: 500 }}>
                                {Wording.about.descriptionBottomBis}
                            </Typography>
                        </CustomAccordion>

                        {energyTypes &&
                            energyTypes.map((energy, index) => {
                                const obj = fields[energy];
                                const packageList = packages.filter(
                                    p => p.energy === energy
                                );
                                return (
                                    <LoadingOverlay
                                        loading={loading && isCurrentScreen}
                                        key={index}
                                    >
                                        <PackageContainer
                                            userState={userState}
                                            packageTitle={
                                                Wording.subtitle[energy]
                                            }
                                            packageList={packageList}
                                            summaryState={summaryState}
                                            transformAuthorizedPackages={
                                                this.transformAuthorizedPackages
                                            }
                                            handleChangePackageFields={(a, b) =>
                                                this.handleChangePackageFields(
                                                    a,
                                                    b,
                                                    energy
                                                )
                                            }
                                            onChangePaymentFrequency={e =>
                                                this.handleChangeInstallmentFrequency(
                                                    e,
                                                    energy
                                                )
                                            }
                                            onChoosePackage={id =>
                                                this.handleChoosePackage(
                                                    id,
                                                    energy
                                                )
                                            }
                                            paymentFrequency={
                                                obj.installmentFrequency
                                            }
                                            prepaymentFrequencies={
                                                prepaymentFrequencies
                                            }
                                            billingModes={{
                                                cyclicBillingMode,
                                                scheduleBillingMode,
                                                scheduleWithoutPackBillingMode,
                                            }}
                                            packageID={obj.packageID}
                                            kind={energy}
                                            cyclicBill={obj.cyclicBill}
                                            showError={showError}
                                            onChangeCyclicBill={() =>
                                                this.handleChangeCyclicBill(
                                                    energy
                                                )
                                            }
                                            changePackagesDispatch={
                                                changePackagesDispatch
                                            }
                                            changeProductOptionDispatch={
                                                changeProductOptionDispatch
                                            }
                                            changeOfferDispatch={
                                                changeOfferDispatch
                                            }
                                            showSeparator={
                                                index === 0 &&
                                                energyTypes.length > 1
                                            }
                                            loading={loading}
                                        />
                                    </LoadingOverlay>
                                );
                            })}

                        {/* {this.handleDisplaySum()} */}
                    </div>
                    {isCurrentScreen && (
                        <div className="validate-button-top-space">
                            <CustomButton
                                disabledBackgroundColor="#f3f3f3"
                                title="Valider"
                                color="orange"
                                disabled={!canGoNext}
                                onClick={nextScreen}
                                loading={loading}
                            />
                        </div>
                    )}
                </SectionLayout>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userState: state.userReducer,
    packageState: state.packageReducer,
    summaryState: state.summaryReducer,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validatePackageScreenDispatch: payload =>
        dispatch(validatePackageScreen(payload)),
    changePackagesDispatch: payload => dispatch(changePackages(payload)),
    changeProductOptionDispatch: payload =>
        dispatch(changeProductOption(payload)),
    changeOfferDispatch: payload => dispatch(changeOffer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageScreen);
