import {
    PREVIOUS_SCREEN_LOAD,
    NEXT_SCREEN_LOAD,
    MAIN_SCREEN_SAVE_ORDER,
    MAIN_SCREEN_SAVE_ORDER_SUCCESS,
    MAIN_SCREEN_SAVE_ORDER_FAILURE,
    MAIN_SCREEN_GET_ORDER,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    MAIN_SCREEN_GET_ORDER_FAILURE,
    MAIN_SCREEN_GET_OFFER_FAILURE,
    MAIN_SCREEN_GET_PACKAGE_FAILURE,
    MAIN_SCREEN_GET_PRODUCT_FAILURE,
    MAIN_SCREEN_GET_FORM,
    USER_SCREEN_VALIDATE_SUCCESS,
    USER_SCREEN_RESET_RECOVER,
    CODE_SCREEN_VALIDATE,
    CODE_SCREEN_VALIDATE_SUCCESS,
    CODE_SCREEN_VALIDATE_FAILURE,
    FINAL_CODE_SCREEN_VALIDATE,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_FAILURE,
    SURVEY_SCREEN_LOAD,
    SURVEY_SCREEN_VALIDATE,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_FAILURE,
    PACKAGE_SCREEN_VALIDATE,
    PACKAGE_SCREEN_VALIDATE_SUCCESS,
    PACKAGE_SCREEN_VALIDATE_FAILURE,
    DATA_SCREEN_VALIDATE_SUCCESS,
    DATA_SCREEN_VALIDATE_SUCCESS_FINAL,
    SUMMARY_SCREEN_VALIDATE_SUCCESS,
    FINAL_SUMMARY_SCREEN_VALIDATE,
    FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS,
    FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE,
    PAYMENT_MODAL_VALIDATE,
    PAYMENT_MODAL_VALIDATE_SUCCESS,
    PAYMENT_MODAL_VALIDATE_FAILURE,
    FETCH_CALENDAR_APPOINTMENT_FAILURE,
    SUMMARY_SCREEN_CHANGE_OPTION_FAILURE,
    SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS,
    FIRST_PAYMENT_CB_FAILURE,
    REDIRECT_FINAL_SCREEN,
    RECALL_PAYMENT_CB,
    PAYMENT_MODAL_LOAD,
    REDIRECT_TO_DATA_SCREEN_START_DATE,
    FINAL_CODE_SCREEN_VALIDATE_V2,
    SEND_EMAIL_CODE_REQUEST,
    VERIFY_EMAIL_CODE_REQUEST,
    SEND_EMAIL_CODE_REQUEST_FAIL,
    SEND_EMAIL_CODE_REQUEST_SUCCESS,
    VERIFY_EMAIL_CODE_REQUEST_FAIL,
    VERIFY_EMAIL_CODE_REQUEST_SUCCESS,
    LOG_USER_INFORMATION_SUCCESS,
    LOG_USER_INFORMATION_REQUEST,
    LOG_USER_INFORMATION_FAIL,
    CLOSE_SAVE_DRAFT_MODAL,
    FINAL_PAYMENT_SCREEN_VALIDATE,
    FINAL_PAYMENT_SCREEN_VALIDATE_SUCCESS,
    FINAL_PAYMENT_SCREEN_VALIDATE_FAILURE,
} from '../types';
import Screen from '../screen';

import { validUserType, validEnergyTypes } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.UserScreen;

export const initialState = {
    loading: false,
    isRecover: false,
    errorRecovery: null,
    loadingPaymentStatus: false,
    loadingPaymentCB: false,
    screenIds: [Screen.USER],
    error: null,
    errorFrom: null,
};

function mainReducer(state = initialState, action) {
    switch (action.type) {
        case PREVIOUS_SCREEN_LOAD: {
            const screenIds = state.screenIds.slice(0, -1);
            return {
                ...state,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case NEXT_SCREEN_LOAD: {
            const screenIds = [...state.screenIds, action.payload.screenId];
            return {
                ...state,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case SURVEY_SCREEN_LOAD: {
            const screenIds = [...state.screenIds, Screen.SURVEY];
            return {
                ...state,
                screenIds,
                loading: false,
                error: null,
                errorFrom: null,
            };
        }
        case SURVEY_SCREEN_VALIDATE:
        case PACKAGE_SCREEN_VALIDATE:
        case CODE_SCREEN_VALIDATE:
        case FINAL_CODE_SCREEN_VALIDATE:
        case FINAL_SUMMARY_SCREEN_VALIDATE:
        case LOG_USER_INFORMATION_REQUEST:
        case SEND_EMAIL_CODE_REQUEST:
        case VERIFY_EMAIL_CODE_REQUEST:
        case MAIN_SCREEN_SAVE_ORDER:
            return {
                ...state,
                loading: true,
                saveDraftModal: {
                    open: false,
                    orderNumber: null,
                },
            };

        case MAIN_SCREEN_GET_ORDER: {
            return {
                ...state,
                ...initialState,
                loading: true,
            };
        }
        // Only trigger of loading
        case LOG_USER_INFORMATION_SUCCESS:
        case LOG_USER_INFORMATION_FAIL:
        case SEND_EMAIL_CODE_REQUEST_FAIL:
        case SEND_EMAIL_CODE_REQUEST_SUCCESS:
        case VERIFY_EMAIL_CODE_REQUEST_FAIL:
        case VERIFY_EMAIL_CODE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case CODE_SCREEN_VALIDATE_FAILURE:
        case FINAL_CODE_SCREEN_VALIDATE_FAILURE:
        case PACKAGE_SCREEN_VALIDATE_FAILURE:
        case SURVEY_SCREEN_VALIDATE_FAILURE:
        case FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE:
        case FETCH_CALENDAR_APPOINTMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                errorFrom: action.type,
            };

        case MAIN_SCREEN_SAVE_ORDER_FAILURE:
            return {
                ...state,
                loading: false,
                saveDraftModal: {
                    open: true,
                    error: action.payload.error,
                    errorFrom: action.type,
                },

                error: action.payload.error,
                errorFrom: action.type,
            };
        case MAIN_SCREEN_GET_OFFER_FAILURE:
        case MAIN_SCREEN_GET_PACKAGE_FAILURE:
        case MAIN_SCREEN_GET_PRODUCT_FAILURE:
        case SUMMARY_SCREEN_CHANGE_OPTION_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                errorFrom: action.type,
            };
        case USER_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.DATA];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case CODE_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.PACKAGE];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.PACKAGE];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case PACKAGE_SCREEN_VALIDATE_SUCCESS: {
            const isFinal = state.screenIds.indexOf(Screen.DATA) > -1;
            let screenIds = state.screenIds;
            if (!isFinal) {
                screenIds = [...state.screenIds, Screen.SUMMARY];
            }

            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case SUMMARY_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.DATA];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS: {
            return {
                ...state,
                error: null,
                errorFrom: null,
            };
        }
        case DATA_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.FINALSUMMARY];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case DATA_SCREEN_VALIDATE_SUCCESS_FINAL: {
            const screenIds = [...state.screenIds, Screen.CODE];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }
        case FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                errorFrom: null,
            };
        }
        case FINAL_CODE_SCREEN_VALIDATE_V2:
        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.FINALSUMMARY];
            return {
                ...state,
                loading: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }

        case REDIRECT_TO_DATA_SCREEN_START_DATE: {
            const screenIds = [...state.screenIds, Screen.DATA];
            return {
                ...state,
                screenIds,
            };
        }
        case PAYMENT_MODAL_VALIDATE:
            return {
                ...state,
                loading: true,
                loadingPaymentStatus: true,
            };

        case RECALL_PAYMENT_CB: {
            return {
                ...state,
                loading: true,
                loadingPaymentStatus: true,
            };
        }

        case FINAL_PAYMENT_SCREEN_VALIDATE: {
            return {
                ...state,
                loading: true,
            };
        }

        case FINAL_PAYMENT_SCREEN_VALIDATE_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case FINAL_PAYMENT_SCREEN_VALIDATE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }

        case FIRST_PAYMENT_CB_FAILURE: {
            const screenIds = [...state.screenIds, Screen.FAILURE_CB];
            return {
                ...state,
                loading: false,
                loadingPaymentStatus: false,
                screenIds,
            };
        }

        case PAYMENT_MODAL_VALIDATE_SUCCESS: {
            const screenIds = [...state.screenIds, Screen.FINALPAYMENT];
            return {
                ...state,
                loading: false,
                loadingPaymentStatus: false,
                screenIds,
                error: null,
                errorFrom: null,
            };
        }

        case REDIRECT_FINAL_SCREEN: {
            const screenIds = [...state.screenIds, Screen.FINALPAYMENT];

            return {
                ...state,
                screenIds,
                loading: false,
                loadingPaymentStatus: false,
                error: null,
                errorFrom: null,
            };
        }
        case PAYMENT_MODAL_LOAD:
        case PAYMENT_MODAL_VALIDATE_FAILURE: {
            return {
                ...state,
                loading: false,
                loadingPaymentStatus: false,
            };
        }
        case MAIN_SCREEN_SAVE_ORDER_SUCCESS: {
            // const screenIds = [...state.screenIds, Screen.SAVE];
            return {
                ...state,
                loading: false,
                saveDraftModal: {
                    open: true,
                    orderNumber: action.payload.orderNumber,
                },
                // loading: false,
                // screenIds,
                // error: null,
                // errorFrom: null,
            };
        }

        case CLOSE_SAVE_DRAFT_MODAL: {
            return {
                ...state,
                loading: false,
                saveDraftModal: {
                    open: false,
                    orderNumber: null,
                },
            };
        }

        case MAIN_SCREEN_GET_ORDER_FAILURE: {
            const { code } = action.payload;
            const screenIds = [Screen.USER];
            let errorRecovery;
            // 1: orderStatus === 'FINALIZED'
            // 2: expired link
            if (code === 1) {
                errorRecovery = Wording.invalidLinkFinalized;
            } else if (code === 2) {
                errorRecovery = Wording.invalidLink;
            } else {
                errorRecovery = Wording.invalidLinkNotFound;
            }
            return {
                ...state,
                screenIds,
                errorRecovery,
                loading: false,
            };
        }
        case MAIN_SCREEN_GET_ORDER_SUCCESS: {
            const { order } = action.payload;
            const checkPoinOfDelivery = order.contracts.find(
                c => c.deliveryPoint.pointOfDelivery
            );

            const isHaveChosenPackages = order.contracts.reduce(
                (acc, curr) => {
                    if (curr.billingModeCode === 'PAYMENT_SCHEDULE')
                        return (
                            acc &&
                            curr.chosenPackages !== undefined &&
                            curr.chosenPackages.length !== 0
                        );

                    return acc;
                },

                true
            );

            const stateReturn = {
                ...state,
                isRecover: true,
                loading: false,
                error: null,
                errorFrom: null,
            };

            if (!isHaveChosenPackages) {
                return {
                    ...stateReturn,
                    screenIds: [Screen.USER, Screen.CODE, Screen.PACKAGE],
                };
            }

            if (checkPoinOfDelivery !== undefined) {
                return {
                    ...stateReturn,
                    screenIds: [
                        Screen.USER,
                        Screen.CODE,
                        Screen.PACKAGE,
                        Screen.SUMMARY,
                        Screen.DATA,
                        Screen.FINALSUMMARY,
                    ],
                };
            }
            return {
                ...state,
                isRecover: true,
                loading: false,
                screenIds: [
                    Screen.USER,
                    Screen.SURVEY,
                    Screen.CODE,
                    Screen.PACKAGE,
                    Screen.SUMMARY,
                    Screen.DATA,
                    Screen.CODE,
                ],
                error: null,
                errorFrom: null,
            };
        }
        case MAIN_SCREEN_GET_FORM: {
            const { customerType, energyTypes } = action.payload;
            const screenIds =
                validUserType(customerType) && validEnergyTypes(energyTypes)
                    ? [Screen.USER, Screen.CODE]
                    : state.screenIds;
            return {
                ...state,
                screenIds,
            };
        }
        case USER_SCREEN_RESET_RECOVER: {
            return {
                ...state,
                isRecover: false,
                errorRecovery: null,
            };
        }
        default:
            return state;
    }
}

export default mainReducer;
