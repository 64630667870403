import React from 'react';

const ImageElement = ({ isChecked, text, imageSrc, onClick, comp }) => (
    <button
        type="button"
        className={`image-element ${isChecked ? 'image-element--checked' : ''}`}
        onClick={onClick}
    >
        {comp && comp}
        {!comp && (
            <div className="image-element__container">
                <img
                    className="image-element__container__image"
                    src={imageSrc}
                    alt="icon"
                />
                <p className="image-element__container__text">{text}</p>
            </div>
        )}
    </button>
);

export default ImageElement;
