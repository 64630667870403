import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import apiRequest from './utils/apiRequest';
import { rootEpic, rootReducer } from './root';

import Screen from './screens/screen';

const epicMiddleware = createEpicMiddleware({
    dependencies: { apiRequest },
});

const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const reduxState = localStorage.getItem('reduxState');
const expireDate = localStorage.getItem('expires');

const prepareToStore = str => ({
    ...str,

    paymentReducer: {
        ...str.paymentReducer,
        iframe: null,
    },
});

const persistedState =
    new Date().getTime() < expireDate && reduxState
        ? prepareToStore(JSON.parse(reduxState))
        : {};

const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(epicMiddleware))
);

// Expires in 30 days
const expires = new Date().getTime() + 1000 * 3600 * 24 * 30;

store.subscribe(() => {
    const lastScreen = store.getState().mainReducer.screenIds.slice(-1)[0];
    if (lastScreen === Screen.FINAL || lastScreen === Screen.SAVE) {
        localStorage.setItem('reduxState', {});
        localStorage.setItem('expires', 0);
        localStorage.removeItem('channel');
    } else {
        localStorage.setItem('reduxState', JSON.stringify(store.getState()));
        localStorage.setItem('expires', expires);
    }
});

epicMiddleware.run(rootEpic);

export default store;
