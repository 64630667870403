import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './_LoadingOverlay.scss';

const LoadingOverlay = ({
    children,
    loading,
    radius = '0px',
    minHeight = '32px',
    fullscreen = false,
    loaderSize = 'lg',
}) => (
    <div
        className="loading-overlay-wrapper"
        style={{ minHeight: loading ? minHeight : '0px' }}
    >
        {loading && (
            <div
                className={
                    fullscreen
                        ? 'loading-overlay-fullscreen'
                        : 'loading-overlay'
                }
                style={{ borderRadius: radius, minHeight }}
            >
                <div className="loading-overlay-content">
                    <FontAwesomeIcon
                        icon={faCircleNotch}
                        spin
                        size={
                            loaderSize === 'lg' && fullscreen
                                ? '3x'
                                : loaderSize
                        }
                        style={{
                            color: 'rgb(81 81 81)',
                        }}
                    />
                </div>
            </div>
        )}
        {children}
    </div>
);

export default LoadingOverlay;
