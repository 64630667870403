import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { addDays } from 'date-fns';
import {
    controlBillingMode,
    getDateString,
    getDateEffectiveString,
    getFormattedDateToBO,
    getTodayDateString,
    prepareCyclicBillDate,
} from '../../utils/helpers';

import { loadPaymentModal } from '../../containers/PaymentModal/PaymentModal.actions';
import {
    FINAL_PAYMENT_SCREEN_VALIDATE,
    FINAL_PAYMENT_SCREEN_VALIDATE_SUCCESS,
    FINAL_PAYMENT_SCREEN_VALIDATE_FAILURE,
    FINAL_PAYMENT_SCREEN_CHANGE_ADDITIONAL_RATES,
    FINAL_PAYMENT_SCREEN_CHANGE_PACKAGES,
    FINAL_PAYMENT_SCREEN_SET_REFERENCE,
    REDIRECT_TO_DATA_SCREEN_START_DATE,
} from '../types';

import { log, TagLogger, TriggerButtonLogger } from '../../utils/logger';

const validateFinalPaymentScreen = payload => ({
    type: FINAL_PAYMENT_SCREEN_VALIDATE,
    payload,
});

const validateFinalPaymentScreenSuccess = payload => ({
    type: FINAL_PAYMENT_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validateFinalPaymentScreenFailure = payload => ({
    type: FINAL_PAYMENT_SCREEN_VALIDATE_FAILURE,
    payload,
});

const changePackages = payload => ({
    type: FINAL_PAYMENT_SCREEN_CHANGE_PACKAGES,
    payload,
});

const changeAdditionalRates = payload => ({
    type: FINAL_PAYMENT_SCREEN_CHANGE_ADDITIONAL_RATES,
    payload,
});

const setReference = payload => ({
    type: FINAL_PAYMENT_SCREEN_SET_REFERENCE,
    payload,
});

const redirectToDataScreen = () => ({
    type: REDIRECT_TO_DATA_SCREEN_START_DATE,
});

const formatAddress = address => {
    const modifiedAddress = { ...address };
    const sliceIndexAddressStreet = address.street.indexOf(' ');
    if (sliceIndexAddressStreet > 0) {
        modifiedAddress.number = address.street.substring(
            0,
            sliceIndexAddressStreet
        );
        modifiedAddress.street = address.street.substring(
            sliceIndexAddressStreet + 1
        );
    }

    return modifiedAddress;
};

const prepareOrder = ({
    userType,
    energyTypes,
    common,
    part,
    pro,
    finance,
    contracts,
    partnerReference,
    thirdParty,
    reference,
    externalReference,
    createdBy,
    status,
    currentOrder,
    ...payload
}) => {
    const channel = localStorage.getItem('channel');

    const isSwitch = common.dateContract
        ? common.dateContract.switch
        : undefined;

    let address = {
        street: common.address.address,
        postalCode: `${common.address.postalCode}`,
        townName: common.address.city,
    };
    if (
        common.newAddress.address &&
        common.newAddress.postalCode &&
        common.newAddress.city
    ) {
        address.street = common.newAddress.address;
        address.postalCode = `${common.newAddress.postalCode}`;
        address.townName = common.newAddress.city;
    }
    address = formatAddress(address);
    const acDt = new Date(common.birthdate);
    const contact = {
        address,
        birthTown: common.birthTown,
        civility: common.civility,
        firstName: common.name,
        lastName: common.surname,
        birthdate: common.birthdate
            ? getDateString({
                  year: acDt.getFullYear(),
                  month: acDt.getMonth(),
                  date: acDt.getDate(),
              })
            : '',
        email: common.email,
        phone: common.tel,
    };
    if (
        pro &&
        pro.socialReason !== '' &&
        pro.legalForm !== '' &&
        pro.APECode !== '' &&
        pro.SIRET !== ''
    ) {
        contact.companyName = pro.socialReason;
        contact.companyType = pro.legalForm;
        contact.activityCode = pro.APECode;
        contact.identificationNumber = pro.SIRET;
    }
    let modifiedFinance = {
        paymentMode: 'DIRECT_DEBIT',
        accountOwnerFirstName: common.name,
        accountOwnerLastName: common.surname,
    };
    if (finance) {
        modifiedFinance = { ...finance };
    }
    if (
        common.newBillingAddress.address &&
        common.newBillingAddress.postalCode &&
        common.newBillingAddress.city
    ) {
        let billingAddress = {
            street: common.newBillingAddress.address,
            postalCode: `${common.newBillingAddress.postalCode}`,
            townName: common.newBillingAddress.city,
        };
        billingAddress = formatAddress(billingAddress);
        modifiedFinance.billingContact = {
            civility: common.newBillingAddress.civility,
            firstName: common.newBillingAddress.name,
            lastName: common.newBillingAddress.surname,
            address: billingAddress,
        };
        modifiedFinance.accountOwnerFirstName = common.newBillingAddress.name;
        modifiedFinance.accountOwnerLastName = common.newBillingAddress.surname;
    }
    const filteredContracts =
        contracts &&
        contracts.filter(contract => energyTypes.includes(contract.energy));

    const modifiedContracts =
        filteredContracts &&
        filteredContracts.map((contract, index) => {
            const nextContract = { ...contract };
            const {
                chosenPackages,
                offer,
                additionalOptions,
                cyclicBill,
                billingModeCode: bmc,
            } = payload[contract.energy];
            const billingModeCode = controlBillingMode(cyclicBill, bmc);

            const process = {
                type: common.situation,
                express: common.dateContract[contract.energy].express,
                appointmentTimeslot:
                    common.dateContract[contract.energy].timeslot,
            };
            const effectiveStartRange = (
                common.dateContract.switch || { value: 'NOW' }
            ).value;

            const thirdParties = [];
            if (
                thirdParty &&
                !thirdParty.error &&
                thirdParty.code !== '' &&
                thirdParty.role !== ''
            ) {
                thirdParties.push({
                    role: thirdParty.role,
                    code: thirdParty.code,
                });
            }

            thirdParties.push({
                role: 'PARTNER',
                code:
                    channel !== undefined && channel !== null && channel !== ''
                        ? channel
                        : 'WEB',
            });

            const coholder = part.coOwner;
            if (
                coholder &&
                coholder.firstName !== '' &&
                coholder.lastName !== ''
            ) {
                thirdParties.push({
                    role: 'COHOLDER',
                    code: '',
                    civility: coholder.civility,
                    firstName: coholder.firstName,
                    lastName: coholder.lastName,
                });
            }

            const effectiveStartDate = (() => {
                const url =
                window.location !== window.parent.location
                    ? document.referrer
                    : document.location.href;
                const params = new URL(url).searchParams;
                const id = params.get('id');
                let dString;
                if (currentOrder && id) {
                  dString = currentOrder.contracts[index].effectiveStartDate
                }
                else {
                    dString = getDateEffectiveString(
                        common.dateContract[contract.energy]
                    );
                }

                if (isSwitch) {
                    const { value: switchValue } = common.dateContract.switch;

                    let date = cyclicBill
                        ? prepareCyclicBillDate(contract.energy, switchValue)
                        : new Date(dString);
                    if (effectiveStartRange === 'LATER' && !id) {
                        date = addDays(date, 21)
                    }
                    return getFormattedDateToBO(
                        // prepareEffectiveStartDate(
                        //     date,
                        //     contract.energy,
                        //     switchValue,
                        //     billingModeCode
                        // )
                        date
                    );
                }

                return dString;
            })();

            const subscriptionDate = getTodayDateString();
            const chosenProduct = { ...offer.product };

            if (offer.product && additionalOptions) {
                if (offer.product.additionalRates) {
                    chosenProduct.additionalRates = offer.product.additionalRates.map(
                        rate => {
                            if (rate.type !== 'OPTION') {
                                return rate;
                            }
                            const active =
                                additionalOptions.indexOf(rate.label) !== -1;
                            return {
                                ...rate,
                                active,
                            };
                        }
                    );
                } else {
                    chosenProduct.additionalRates = [];
                }
            }

            if (isSwitch && !cyclicBill) {
                nextContract.dueDate = getDateString(
                    common.dateContract[contract.energy]
                );
            }

            return {
                ...nextContract,
                thirdParties,
                chosenProduct,
                effectiveStartRange,
                effectiveStartDate,
                subscriptionDate,
                billingModeCode,
                chosenPackages: chosenPackages || [],
                installmentFrequency:
                    payload[contract.energy].installmentFrequency,
                deliveryPoint: {
                    ...contract.deliveryPoint,
                    process,
                },
            };
        });
    const firstPaymentCBStatus = modifiedContracts
        ? modifiedContracts.reduce(
              (
                  acc,
                  {
                      firstPaymentCB,
                      deliveryPoint: {
                          process: { type },
                      },
                  }
              ) =>
                  acc ||
                  (firstPaymentCB !== undefined && type === 'MOVE_IN'
                      ? firstPaymentCB
                      : false),

              false
          )
        : {};

    const order = {
        contracts: modifiedContracts,
        customer: {
            contact,
            finance: modifiedFinance,
            type: userType,
            typePayment: common.situation === 'SWITCH' && 
            (
                common.dateContract.switch || { value: 'NOW' }
            ).value === 'LATER' ? 'SDD' : 'OTHER',
        },
    };
    if (partnerReference) {
        order.partnerReference = partnerReference;
    }

    if (firstPaymentCBStatus || payload.retryCB) {
        order.firstPaymentCBStatus = 'PENDING';
    }

    if (createdBy) {
        order.createdBy = createdBy;
    }

    if (reference && reference !== '') {
        // In case of FAIL
        // use the same order reference
        return {
            ...order,
            externalReference,
            orderReference: reference,
            orderStatus: status,
        };
    }

    return order;
};

const preparePayload = payload => {
    const order = prepareOrder(payload);

    const agent = localStorage.getItem('agent');

    if (agent) {
        order.createdBy = agent;
    }

    return { order };
};

const mapGetPayment = (action, { apiRequest }) => {
    const payload = preparePayload(action.payload);
    const req = {
        ...payload,
        retryCB:
            action.payload && action.payload.retryCB
                ? action.payload.retryCB
                : false,
        typeOrder: action.payload && action.payload.typeOrder,
        alreadyClient: action.payload && action.payload.alreadyClient,
    };
    const option = {
        path: '/signContract',
        method: 'post',
        body: req,
    };

    return apiRequest(option).pipe(
        mergeMap(response => {
            if (response.iframe) {
                log({
                    tag: TagLogger.SIGNING,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/signContract',
                    statusAction: 'SUCCESS',
                    triggerButton: 'validate-button',
                });
                return of(
                    loadPaymentModal({
                        ...response,
                        order: payload.order,
                        paymentType: 'DIRECT_DEBIT',
                    }),
                    validateFinalPaymentScreenSuccess(action.payload)
                );
            }
            const message = response.message || response.errorMessage;
            log({
                tag: TagLogger.SIGNING,
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/signContract',
                statusAction: 'FAILURE',
                triggerButton: 'validate-button',
            });
            return of(
                validateFinalPaymentScreenFailure({
                    error: message,
                })
            );
        }),
        catchError(error => {
            log({
                tag: TagLogger.SIGNING,
                returnCode: error.code,
                logMessage: error.message,
                serviceCalled: '/signContract',
                statusAction: 'FAILURE',
                triggerButton: TriggerButtonLogger.VALIDATE_BTN,
            });

            return of(
                validateFinalPaymentScreenFailure({
                    error: error.message,
                })
            );
        })
    );
};

const validateFinalPaymentScreenEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(FINAL_PAYMENT_SCREEN_VALIDATE),
        mergeMap(action => mapGetPayment(action, dependency))
    );

const finalPaymentScreenEpic = combineEpics(validateFinalPaymentScreenEpic);

export {
    validateFinalPaymentScreen,
    validateFinalPaymentScreenSuccess,
    validateFinalPaymentScreenFailure,
    redirectToDataScreen,
    finalPaymentScreenEpic,
    mapGetPayment,
    prepareOrder,
    changeAdditionalRates,
    changePackages,
    setReference,
};
