import React from 'react';
import { connect } from 'react-redux';
import Title from '../../components/Typography/Title';
import WordingConstant from '../../utils/wording.json';
import Screen from '../screen';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import Typography from '../../components/Typography/Typography';
import { FontSizeVariant } from '../../constants/enums';
import informationSVG from '../../assets/icons/ic-info.svg';
import './_MinimalUserData.scss';
import LabeledInput from '../../components/LabeledInput/LabeledInput';
import CustomSelector from '../../components/CustomSelector/CustomSelector';
import { CustomButton } from '../../components';
import {
    handleConditionEmail,
    handleConditionPhone,
} from '../../utils/helpers';
import {
    logUserInformationRequest,
    resetMinimumUserDataEmail,
    sendEmailCodeRequest,
    storeMinimumUserData,
    verifyEmailCodeRequest,
    getLoggedUserInfoRequest,
} from './DataScreen.actions';
import ValidateCodeEmail from './ValidateCodeEmail';
import { prepareOrder } from '../FinalSummaryScreen/FinalSummaryScreen.actions';
import { scrollToRefDelay } from '../../utils/scroll';
import CustomSelectV2 from '../../components/CustomSelect/CustomSelectV2';
import SearchService from '../../utils/search';

const Wording = WordingConstant.DataScreen;
const AddressContainerWording = WordingConstant.AddressContainer;

export const CLIENT_MODE = Object.freeze({
    ALREADY_EXIST: 'ALREADY_EXIST',
    NEW: 'NEW',
});

const blockedYopmailDomains = ['yopmail.com', 'yopmail.fr'];

export class MinimalUserData extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.FINALSUMMARY,
        previousScreen: Screen.SUMMARY,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        screenId: Screen.DATA,
        step: 2,
        validate: () => {},
        disabled: () => {},
        returnButton: () => {},
        saveData: () => {},
    };

    constructor(props) {
        super(props);
        const { dataState } = props;
        this.state = {
            mode: CLIENT_MODE.NEW,
            showValidationModal: false,
            nonExistingForm: {
                civility: dataState.common.civility,
                surname: dataState.common.surname,
                email: dataState.common.email,
                name: dataState.common.name,
                tel: dataState.common.tel,
                firstAddress: dataState.common.firstAddress,
                postalCode: dataState.common.firstAddress.postalCode,
                city: dataState.common.firstAddress.city,
                netArea: dataState.common.firstAddress.netArea,
            },
            existingForm: {
                email: dataState.common.email,
                pass: '',
            },
            setting: {
                fieldChange: false,
                isGoingNext: false,
                postalCodes: [],
                codeText: '',
                showError: false,
                isLoadingPC: false,
                checkError: {
                    email: false,
                    phone: false,
                    firstname: false,
                    lastname: false,
                    loginEmail: false,
                },
            },
            errorMessage: '',
        };
        this.myRef = React.createRef();
        this.onUpdate = this.onUpdate.bind(this);
        this.changeMode = this.changeMode.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.handleValidate = this.handleValidate.bind(this);
        this.renderNewClient = this.renderNewClient.bind(this);
        this.handleValidateCode = this.handleValidateCode.bind(this);
        this.renderExistingClient = this.renderExistingClient.bind(this);
        this.nextScreenValidation = this.nextScreenValidation.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onMouseEnterValidate = this.onMouseEnterValidate.bind(this);
        this.searchService = new SearchService();
    }

    onUpdate() {
        const { dataState, getLoggedUserInfoDispatch, nextScreen } = this.props;
        const { showValidationModal, setting, mode } = this.state;
        if (dataState.emailValid.isValid && showValidationModal)
            this.setState({ showValidationModal: false });
        if (this.nextScreenValidation() && !setting.isGoingNext) {
            if (mode === CLIENT_MODE.ALREADY_EXIST) {
                getLoggedUserInfoDispatch({
                    token: dataState.logingInformation.token,
                    customerNbr: dataState.logingInformation.user,
                });
            }
            if (mode !== CLIENT_MODE.NEW || this.checkFirstModuleCondition()) {
                setting.isGoingNext = true;
                this.setState({ setting });
                nextScreen(null, null, false, 2);
            }
        }
    }

    componentDidMount() {
        this.searchService.getResults().subscribe(res => {
            const { setting } = this.state;
            if (res.length > 0) {
                setting.postalCodes = res.map(dt => ({
                    id: dt.code,
                    label: `${dt.code} - - - ${dt.city}`,
                    value: `${dt.code}__${dt.city}`,
                    codeValue: dt.code,
                    cityValue: dt.city,
                    netAreaValue: dt.netArea,
                }));
            } else {
                setting.postalCodes = [];
            }
            setting.isLoadingPC = false;
            this.setState({ setting });
        });
        // const { dataState } = this.props;
        // console.log(dataState.common.firstAddress.postalCode)
        // this.handleInputChangeAddressContainer(dataState.common.firstAddress.postalCode)
        // this.onFocusDropdown()
        // this.handleChangeDropdownSelect({
        //     codeValue: dataState.common.firstAddress.postalCode,
        //     cityValue: dataState.common.firstAddress.city,
        //     netAreaValue: dataState.common.firstAddress.netArea,
        // })
        MinimalUserData.navigationOptions.validate = this.nextScreenValidation;
        const { setCanGoNext, isCurrentScreen } = this.props;
        const { showValidationModal } = this.state;
        if (this.nextScreenValidation()) setCanGoNext(true);
        else setCanGoNext(false);
        if (!showValidationModal && isCurrentScreen)
            scrollToRefDelay(this.myRef, 400);
        const { nextScreen } = this.props;
        const { setting } = this.state;
        if (this.nextScreenValidation() && !setting.isGoingNext) {
            setting.isGoingNext = true;
            this.setState({ setting });
            nextScreen(null, null, false, 2);
        }
    }

    componentDidUpdate() {
        this.onUpdate();
        const { setCanGoNext } = this.props;
        if (this.nextScreenValidation()) setCanGoNext(true);
        else setCanGoNext(false);
    }

    changeMode(id) {
        const { setting, showValidationModal } = this.state;
        if (setting.isGoingNext === true) {
            setting.isGoingNext = false;
            this.setState({ setting });
        }
        if (showValidationModal === true && id === CLIENT_MODE.ALREADY_EXIST) {
            this.setState({ showValidationModal: false });
        }
        this.setState({
            mode: id,
        });
    }

    nextScreenValidation() {
        const { dataState } = this.props;
        const {
            mode,
            showValidationModal,
            setting: { fieldChange },
        } = this.state;
        return Boolean(
            (!fieldChange &&
                dataState.emailValid.isValid &&
                mode === CLIENT_MODE.NEW &&
                !showValidationModal) ||
                (dataState.logingInformation.accessToken &&
                    mode === CLIENT_MODE.ALREADY_EXIST)
        );
    }

    checkFirstModuleCondition() {
        const { nonExistingForm, existingForm, mode } = this.state;
        if (mode === CLIENT_MODE.NEW) {
            const {
                civility,
                surname,
                email,
                name,
                tel,
                firstAddress,
            } = nonExistingForm;
            const valid =
                handleConditionEmail(email) &&
                handleConditionPhone(tel) &&
                civility !== undefined &&
                civility !== '' &&
                surname !== undefined &&
                surname !== '' &&
                name !== undefined &&
                name !== '' &&
                firstAddress.postalCode !== undefined &&
                firstAddress.postalCode !== '' &&
                firstAddress.city !== undefined &&
                firstAddress.city !== '';
            return valid;
        }
        const { email, pass } = existingForm;
        const valid = handleConditionEmail(email) && pass !== '';
        return valid;
    }

    handleValidate() {
        const {
            storeMinimumUserDataDispatch,
            logUserInformationRequestDispatch,
            dataState,
            nextScreen,
        } = this.props;

        const { mode, nonExistingForm, existingForm, setting } = this.state;

        const isYopmailBlocked = this.isYopmailBlocked(nonExistingForm.email);
        if (isYopmailBlocked) {
            this.setState(
                { errorMessage: "Merci d'entrer un email valide" },
                () => {}
            );
            return;
        }

        this.setState({ setting: { ...setting, fieldChange: false } }, () => {
            if (mode === CLIENT_MODE.NEW) {
                storeMinimumUserDataDispatch(nonExistingForm);

                if (!dataState.emailValid.isValid) {
                    const order = prepareOrder({ common: dataState.common });
                    logUserInformationRequestDispatch({
                        user: nonExistingForm.email,
                        password: ' ',
                        dataOff: {
                            onUserNotExist: () =>
                                this.setState({ showValidationModal: true }),
                            onUserExist: () =>
                                this.setState({ showValidationModal: false }),
                            isOnlyCheck: true,
                            order,
                        },
                    });
                } else {
                    setting.isGoingNext = true;
                    this.setState({ setting }, () => {
                        nextScreen(null, null, false, 2);
                    });
                }
            } else {
                storeMinimumUserDataDispatch({ email: existingForm.email });
                logUserInformationRequestDispatch({
                    user: existingForm.email,
                    password: existingForm.pass,
                });
            }
        });
    }

    isYopmailBlocked(email) {
        const domain = email.split('@')[1];
        return blockedYopmailDomains.includes(domain);
    }

    handleValidateCode(verificationCode) {
        const {
            storeMinimumUserDataDispatch,
            verifyEmailCodeRequestDispatch,
            paymentReducer,
            dataState,
        } = this.props;
        const { mode, nonExistingForm } = this.state;
        if (mode === CLIENT_MODE.NEW)
            storeMinimumUserDataDispatch(nonExistingForm);
        const order = prepareOrder({
            common: dataState.common,
            ...paymentReducer,
        });
        verifyEmailCodeRequestDispatch({
            order: { ...order },
            validationEmailCode: verificationCode,
        });
    }

    onBlur(field) {
        const { setting, nonExistingForm } = this.state;
        if (field === 'lastname') {
            const value = nonExistingForm.name;
            const cleanValue = value.toUpperCase();
            this.setState({
                nonExistingForm: {
                    ...nonExistingForm,
                    name: cleanValue,
                },
            });
        } else if (field === 'firstname') {
            const value = nonExistingForm.surname;
            const cleanValue =
                value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            this.setState({
                nonExistingForm: {
                    ...nonExistingForm,
                    surname: cleanValue,
                },
            });
        }
        if (setting.checkError[field] !== undefined) {
            setting.checkError[field] = true;
            this.setState({ setting });
        }
    }

    handleInputChangeAddressContainer = value => {
        const { setting } = this.state;
        setting.codeText = value.replace(/[^\d ]/g, '');
        if (value !== '') {
            setting.isLoadingPC = true;
        }
        const val = value.length > 5 ? value.substring(0, 4) : value;
        this.searchService.search(val);
        this.setState({ setting });
    };

    // onFocusDropdown = () => {
    //     const { setting, fields } = this.state;
    //     if (fields.postalCode !== undefined) {
    //         setting.codeText = fields.postalCode.toString();
    //         this.setState({ setting });
    //     }
    // };

    handleChangeDropdownSelect = object => {
        const { fields, setting, nonExistingForm } = this.state;
        const {
            storeMinimumUserDataDispatch,
            dataState,
            isCurrentScreen,
            resetScreenTo,
        } = this.props;
        const { codeValue, netAreaValue, cityValue } = object;
        if (codeValue !== undefined) setting.codeText = codeValue.toString();
        setting.isLoadingPC = false;
        setting.postalCodes = [];
        this.setState({
            fields,
            setting,
            nonExistingForm: {
                ...nonExistingForm,
                firstAddress: {
                    postalCode: codeValue,
                    city: cityValue,
                    netArea: netAreaValue,
                },
            },
        });
        if (!isCurrentScreen) resetScreenTo();
        if (dataState.emailValid.isValid || dataState.logingInformation.token) {
            storeMinimumUserDataDispatch({
                ...nonExistingForm,
                firstAddress: {
                    postalCode: codeValue,
                    city: cityValue,
                    netArea: netAreaValue,
                },
            });
        }
    };

    renderNewClient() {
        const { nonExistingForm, setting } = this.state;
        const { dataState } = this.props;
        const emailValidation = () => {
            if (dataState.logingInformation.isUserExist === true)
                return Wording.error.alreadyExistEmail;
            return !handleConditionEmail(nonExistingForm.email)
                ? Wording.error.email
                : undefined;
        };
        const validation = {
            phone:
                setting.checkError.phone &&
                !handleConditionPhone(nonExistingForm.tel) &&
                Wording.error.phone,
            email:
                (setting.checkError.email && emailValidation()) ||
                (dataState.logingInformation.isUserExist === true &&
                    Wording.error.alreadyExistEmail),
            firstname:
                setting.checkError.firstname &&
                !nonExistingForm.surname &&
                Wording.error.mainText,
            lastname:
                setting.checkError.lastname &&
                !nonExistingForm.name &&
                Wording.error.mainText,
        };
        return (
            <div className="form-existing-user">
                <CustomSelectV2
                    placeholder={Wording.civility.title}
                    options={Wording.civility.values}
                    halfSize
                    handleChange={e =>
                        this.onFieldChange('civility', e ? e.value : e, 'new')
                    }
                    value={nonExistingForm.civility}
                />
                <LabeledInput
                    label="Nom"
                    name="lastname"
                    type="text"
                    defaultValue={dataState.common.surname}
                    messages={{ error: validation.lastname }}
                    onBlur={() => this.onBlur('lastname')}
                    onChange={e => this.onFieldChange('surname', e, 'new')}
                    isUpperCase
                />
                <LabeledInput
                    label="Prénom"
                    name="firstname"
                    type="text"
                    defaultValue={dataState.common.name}
                    messages={{ error: validation.firstname }}
                    onBlur={() => this.onBlur('firstname')}
                    onChange={e => this.onFieldChange('name', e, 'new')}
                    isCapital
                />
                <LabeledInput
                    label="Email"
                    name="email"
                    type="text"
                    defaultValue={dataState.common.email}
                    messages={{
                        error: this.state.errorMessage || validation.email,
                    }}
                    onBlur={() => this.onBlur('email')}
                    onChange={e => this.onFieldChange('email', e, 'new')}
                />
                {!this.state.errorMessage && (
                    <div className="error-message">
                        {this.state.errorMessage}
                    </div>
                )}
                <LabeledInput
                    label="Numéro de téléphone"
                    name="phone-number"
                    type="text"
                    value={nonExistingForm.tel}
                    messages={{ error: validation.phone }}
                    onBlur={() => this.onBlur('phone')}
                    onChange={e => this.onFieldChange('tel', e, 'new')}
                />
                <CustomSelectV2
                    placeholder={Wording.postalCode}
                    hideArrow
                    isLoading={setting.isLoadingPC}
                    onInputChange={value => {
                        this.handleInputChangeAddressContainer(value);
                    }}
                    options={setting.postalCodes}
                    handleChange={obj => {
                        this.handleChangeDropdownSelect(obj);
                    }}
                    value={{
                        id: nonExistingForm.firstAddress.postalCode,
                        label: nonExistingForm.firstAddress.postalCode,
                        value: nonExistingForm.firstAddress.postalCode,
                    }}
                />
                <LabeledInput
                    label={AddressContainerWording.city}
                    name={AddressContainerWording.city}
                    value={nonExistingForm.firstAddress.city}
                    isLocked
                    type="text"
                    onChange={e =>
                        this.onFieldChange('newBillingCity', e, 'new')
                    }
                />
            </div>
        );
    }

    renderExistingClient() {
        const { existingForm, setting } = this.state;
        const { dataState } = this.props;
        const emailValidation = () => {
            if (dataState.logingInformation.error)
                return dataState.logingInformation.error;
            return !handleConditionEmail(existingForm.email)
                ? Wording.error.email
                : undefined;
        };
        const validation = {
            loginEmail: setting.checkError.loginEmail && emailValidation(),
            password: dataState.logingInformation.error,
        };
        return (
            <div className="form-existing-user">
                <LabeledInput
                    label="Email"
                    type="text"
                    name="email"
                    messages={{ error: validation.loginEmail }}
                    onBlur={() => this.onBlur('loginEmail')}
                    defaultValue={dataState.common.email}
                    onChange={e => this.onFieldChange('email', e, 'exist')}
                />
                <LabeledInput
                    onChange={e => this.onFieldChange('pass', e, 'exist')}
                    messages={{ error: validation.password ? ' ' : undefined }}
                    label="Mot de passe"
                    type="password"
                    name="password"
                />
            </div>
        );
    }

    onMouseEnterValidate() {
        const { storeMinimumUserDataDispatch } = this.props;
        const { nonExistingForm } = this.state;
        storeMinimumUserDataDispatch(nonExistingForm);
    }

    onFieldChange(field, value, form = undefined) {
        const {
            existingForm,
            nonExistingForm,
            setting,
            showValidationModal,
            mode,
        } = this.state;
        const {
            dataState,
            resetEmailDispatch,
            resetScreenTo,
            isCurrentScreen,
        } = this.props;
        let cleanValue = value;

        if (
            (dataState.emailValid.isValid ||
                dataState.logingInformation.token) &&
            field === 'email' &&
            dataState.common.email !== value
        ) {
            resetEmailDispatch();
        }
        if (
            field === 'email' &&
            showValidationModal === true &&
            mode === CLIENT_MODE.NEW
        ) {
            this.setState({ showValidationModal: false });
        }
        // TODO Remove the order number of previously validate

        if (field === 'tel' && cleanValue !== undefined) {
            const regex = /\D|\d{11,}/g;
            const tmp = regex.test(cleanValue);
            if (tmp) {
                cleanValue = cleanValue.slice(0, -1);
            }
        }
        if (!isCurrentScreen) resetScreenTo();
        if (setting.isGoingNext === true) {
            setting.isGoingNext = false;
        }
        setting.fieldChange = true;
        this.setState({ setting });
        if (form === undefined)
            this.setState({
                [field]: cleanValue,
            });
        else if (form === 'exist')
            this.setState({
                existingForm: {
                    ...existingForm,
                    [field]: cleanValue,
                },
            });
        else if (form === 'new')
            this.setState({
                nonExistingForm: {
                    ...nonExistingForm,
                    [field]: cleanValue,
                },
            });
    }

    render() {
        const { mode, showValidationModal } = this.state;
        const {
            dataState: {
                emailValid: { error, isValid },
                logingInformation,
                common: { email },
            },
            isCurrentScreen,
            mainState,
            isModalOpen,
        } = this.props;

        const defaultValueSelector = () => {
            if (mode === CLIENT_MODE.ALREADY_EXIST)
                return CLIENT_MODE.ALREADY_EXIST;
            if (logingInformation.token) return CLIENT_MODE.ALREADY_EXIST;
            if (isValid) return CLIENT_MODE.NEW;
            return CLIENT_MODE.NEW;
        };

        return (
            <>
                <div ref={this.myRef} />
                <SectionLayout
                    step={MinimalUserData.navigationOptions.step}
                    isModal={isModalOpen}
                >
                    <Title
                        titleFormatGowun="Je"
                        titleFormatLexand="renseigne mes informations"
                    />
                    <Typography className="subtitle-p2">
                        Afin de simplifier le contact entre nous, remplis les
                        champs ci-dessous :
                    </Typography>

                    <div className="__mode-selector">
                        <CustomSelector
                            options={[
                                {
                                    id: CLIENT_MODE.NEW,
                                    label: (
                                        <Typography>
                                            Je suis{' '}
                                            <span style={{ fontWeight: 400 }}>
                                                nouveau client !
                                            </span>
                                        </Typography>
                                    ),
                                },
                                {
                                    id: CLIENT_MODE.ALREADY_EXIST,
                                    label: (
                                        <Typography>
                                            Je suis déjà{' '}
                                            <span style={{ fontWeight: 400 }}>
                                                client Wekiwi !
                                            </span>
                                        </Typography>
                                    ),
                                },
                            ]}
                            defaultId={defaultValueSelector()}
                            onMounSetter
                            onSelect={this.changeMode}
                            gap="4rem"
                            notFullWidth
                        />
                    </div>

                    {mode === CLIENT_MODE.ALREADY_EXIST &&
                        this.renderExistingClient()}

                    {mode === CLIENT_MODE.NEW && this.renderNewClient()}

                    <div className="info-text">
                        <img
                            alt="yellow information icon"
                            src={informationSVG}
                        />
                        <Typography fontSize={FontSizeVariant.XTRA_SMALL}>
                            {Wording.data_collect_title}
                        </Typography>
                    </div>

                    <Typography fontSize={FontSizeVariant.XTRA_SMALL}>
                        {Wording.data_collect_info}
                    </Typography>

                    {isCurrentScreen && !showValidationModal && (
                        <div className="__button-container">
                            <CustomButton
                                disabledBackgroundColor="#f3f3f3"
                                disabled={!this.checkFirstModuleCondition()}
                                onClick={this.handleValidate}
                                title="Valider"
                                color="orange"
                                loading={mainState.loading}
                                onMouseEnter={this.onMouseEnterValidate}
                            />
                        </div>
                    )}
                </SectionLayout>
                {showValidationModal && isCurrentScreen && (
                    <SectionLayout
                        step={MinimalUserData.navigationOptions.step}
                    >
                        <ValidateCodeEmail
                            loading={mainState.loading}
                            isVerificationFail={!!error}
                            onValidateCode={this.handleValidateCode}
                            onResendCode={this.handleValidate}
                            email={email}
                        />
                    </SectionLayout>
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    dataState: state.dataReducer,
    paymentReducer: state.paymentReducer,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    storeMinimumUserDataDispatch: payload =>
        dispatch(storeMinimumUserData(payload)),
    sendEmailCodeRequestDispatch: payload =>
        dispatch(sendEmailCodeRequest(payload)),
    verifyEmailCodeRequestDispatch: payload =>
        dispatch(verifyEmailCodeRequest(payload)),
    logUserInformationRequestDispatch: payload =>
        dispatch(logUserInformationRequest(payload)),
    resetEmailDispatch: payload => dispatch(resetMinimumUserDataEmail(payload)),
    getLoggedUserInfoDispatch: payload =>
        dispatch(getLoggedUserInfoRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MinimalUserData);
