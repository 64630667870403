import React from 'react';
import WordingConstant from '../../../utils/wording.json';
import CustomSelector from '../../CustomSelector/CustomSelector';

const Wording = WordingConstant.DataScreen;

const TimeSlotComponent = ({
    energy,
    dateContract,
    handleChangeTimeslotSelect,
}) => {
    const ts = dateContract[energy].timeslots;
    if (!ts || ts.length === 0) {
        return null;
    }
    if (ts.indexOf('NONE') !== -1) {
        return (
            <p
                className={`date-container__date__calendars__${energy}-calendar__paragraph`}
            >
                {Wording.contractDate.noTimeslotLabel}
            </p>
        );
    }
    const timeslotOptions = Wording.contractDate.timeslots.values.filter(
        element => ts.find(val => val === element.value) !== undefined
    );

    /* const searchValue = Wording.contractDate.timeslots.values.find(
        e => e.value === dateContract[energy].timeslot
    ); */

    /* const value = searchValue
        ? searchValue.value
        : Wording.contractDate.placeholderSelector; */

    return (
        <React.Fragment>
            <p
                className={`date-container__date__calendars__${energy}-calendar__paragraph`}
            >
                {Wording.contractDate.timeslotSelectorTitle}
            </p>
            <CustomSelector
                id="idCreceau"
                options={timeslotOptions}
                className="hour-container__hour__selector"
                gap="0"
                style={{ width: '85%' }}
                onSelect={(id, value) =>
                    handleChangeTimeslotSelect(value, energy)
                }
            />
        </React.Fragment>
    );
};

export default TimeSlotComponent;
