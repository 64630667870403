import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import CustomSelector from '../../components/CustomSelector/CustomSelector';
import Title from '../../components/Typography/Title';
import { CustomAccordion } from '../../components';
import Typography from '../../components/Typography/Typography';
import DividerDashOr from '../../components/DividerDashOr/DividerDashOr';
import { ReactComponent as RedCross } from '../../assets/icons/ic-red-cross.svg';
import { BillingMode } from '../../constants/enums';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.PackageScreen;

function OfferSelector({ packageState, onChange }) {
    const { autorizedBillingModes } = packageState;
    const [currentSelection, setCurrentSellection] = useState(null);

    const handleCurrentSelection = id => {
        setCurrentSellection(id);
    };

    useEffect(() => {
        if (currentSelection) onChange(currentSelection);
    }, [currentSelection]);

    let isCyclicDisabled = true;
    autorizedBillingModes.forEach(it => {
        if (it.billingModeCode === BillingMode.CYCLICAL_BILLING)
            isCyclicDisabled = false;
    });

    return (
        <>
            <Title
                titleFormatGowun="Je choisis"
                titleFormatLexand="mon offre"
            />

            <CustomSelector
                className="offer-selector"
                value={currentSelection}
                onSelect={handleCurrentSelection}
                options={[
                    {
                        id: BillingMode.PAYMENT_SCHEDULE,
                        label: (
                            <Typography>
                                Je choisis{' '}
                                <span style={{ fontWeight: 400 }}>
                                    le forfait Wekiwi
                                </span>
                            </Typography>
                        ),
                    },
                ]}
            />
            <CustomAccordion title="Le petit cours de Kiwi : C’est quoi le forfait Wekiwi ?">
                <Typography
                    style={{
                        marginBottom: '16px',
                        marginTop: '16px',
                    }}
                >
                    {Wording.about.description}
                </Typography>
                <Typography style={{ marginBottom: '16px' }}>
                    {Wording.about.choiceText}
                </Typography>
                <ul>
                    <li>
                        {Wording.about.packageDiscount.main}{' '}
                        <Typography style={{ display: 'inline' }}>
                            {Wording.about.packageDiscount.description}
                        </Typography>
                    </li>
                    <li>
                        {Wording.about.digitalDiscount.main}{' '}
                        <Typography style={{ display: 'inline' }}>
                            {Wording.about.digitalDiscount.description}
                        </Typography>
                    </li>
                </ul>
                <Typography>{Wording.about.descriptionBottom}</Typography>
                <Typography style={{ fontWeight: 500 }}>
                    {Wording.about.descriptionBottomBis}
                </Typography>
            </CustomAccordion>
            <DividerDashOr />

            <CustomSelector
                className="offer-selector"
                value={currentSelection}
                onSelect={handleCurrentSelection}
                options={[
                    {
                        id: BillingMode.CYCLICAL_BILLING,
                        label: (
                            <Typography>
                                Je choisis{' '}
                                <span style={{ fontWeight: 400 }}>
                                    l’offre cyclique (facturation mensuelle ou
                                    bimestrielle)
                                </span>
                            </Typography>
                        ),
                    },
                ]}
                disabled={isCyclicDisabled}
            />
            <CustomAccordion
                disabled={isCyclicDisabled}
                title="Le petit cours de Kiwi : C’est quoi l’offre cyclique ?"
            >
                <Typography
                    style={{
                        marginBottom: '14px',
                        marginTop: '16px',
                    }}
                >
                    {Wording.aboutCyclic.description.first}
                    <span style={{ fontWeight: 500 }}>
                        {Wording.aboutCyclic.description.bold}
                    </span>
                    {Wording.aboutCyclic.description.second}
                </Typography>
                <Typography style={{ marginBottom: '16px', fontWeight: 500 }}>
                    {Wording.aboutCyclic.attention}
                </Typography>
                <ul style={{ marginBottom: 0 }}>
                    <li>
                        {Wording.aboutCyclic.point.main}{' '}
                        <Typography style={{ display: 'inline' }}>
                            {Wording.aboutCyclic.point.description}
                        </Typography>
                    </li>
                </ul>
            </CustomAccordion>
            {isCyclicDisabled && (
                <div className="no-more-offer">
                    <RedCross />

                    <Typography>
                        Oups ! Le type de compteur que tu possèdes ne te permet
                        pas de choisir l’offre cyclique...
                    </Typography>
                </div>
            )}
        </>
    );
}

const mapStateToProps = state => ({
    packageState: state.packageReducer,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OfferSelector);
