import React, { forwardRef } from 'react';
import './_SectionLayout.scss';

/**
 *
 * @param step to draw on the top left buble (can be use to override style with clasnname 'section-layout-{stepNumber}' )
 * @returns
 */
const SectionLayout = forwardRef(
    ({ children, step, hidden, isModal = false }, ref) => (
        <div
            className={`section-layout ${step && `section-layout-${step}`}`}
            style={{ display: hidden ? 'none' : 'block' }}
        >
            {step && (
                <>
                    {isModal && <div className="modal-overlay" />}
                    <div
                        ref={ref}
                        className={`section-layout-number ${
                            step.length > 2 ? 'string' : ''
                        }`}
                    >
                        {step}
                    </div>
                </>
            )}
            {children}
        </div>
    )
);

export default SectionLayout;
