import React from 'react';

import Slider from 'react-slick';
import classnames from 'classnames';

import { PackageElement, CustomModal, EnergyGauge } from '../../components';

import WordingConstant from '../../utils/wording.json';
import resources from '../../utils/resources';
import {
    buildPackagesForSlider,
    prepareFrequenciesToDisplay,
} from '../../utils/helpers';
import CustomSelector from '../../components/CustomSelector/CustomSelector';
import Typography from '../../components/Typography/Typography';
import { TypoVariant } from '../../constants/enums';
import MenLongArm from '../../assets/icons/ic-mister-long-arm.png';
import GasIcon from '../../assets/icons/ic-gas-on.svg';
import ElecIcon from '../../assets/icons/ic-elec-on.svg';
import CustomToggleV2 from '../../components/CustomToggle/CustomToggleV2';
import informationSVG from '../../assets/icons/ic-info.svg';

const Wording = WordingConstant.PackageScreen;

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
);

const NextArrow = () => (
    <img src={resources['ic-arrow-next']} alt="ic-arrow-next" />
);
const BackArrow = () => (
    <img src={resources['ic-arrow-back']} alt="ic-arrow-back" />
);

class PackageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showingModalFrequency: false,
            isCustomSeasons: false,
            fields: {
                prices: [],
            },
        };
    }

    handleToggle = ({ target: { checked } }, ranges) => {
        const {
            kind,
            handleChangePackageFields,
            packageID,
            onChoosePackage,
        } = this.props;
        if (checked) {
            handleChangePackageFields([ranges[0][0], ranges[0][1]], '', kind);
        } else {
            onChoosePackage(packageID);
        }
    };

    handleOpenModalFrequency = () => {
        const { showingModalFrequency } = this.state;
        this.setState({ showingModalFrequency: !showingModalFrequency });
    };

    getConsumptionPerMonth = energy => {
        const { summaryState } = this.props;
        try {
            const filteredData = summaryState.contracts.filter(
                item => item.energy === energy
            )[0];
            let total = 0;
            filteredData.estimates.forEach(element => {
                total += element.quantity;
            });
            return Math.round(total / 12);
        } catch {
            return 0;
        }
    };

    renderModalFrequency() {
        const { showingModalFrequency } = this.state;
        return (
            <CustomModal
                show={showingModalFrequency}
                showCloseButton
                handleClick={this.handleOpenModalFrequency}
            >
                <div className="modal__content">
                    <div className="modal__title">
                        <div className="custom-input__layer">?</div>
                        <p>{Wording.popup.frequency.title}</p>
                    </div>
                    <p>{Wording.popup.frequency.description}</p>
                    <br />

                    <p>{Wording.popup.frequency.itemsTitle}</p>

                    <ul>
                        {Wording.popup.frequency.frequencyItems.map(
                            (elm, index) => (
                                <li key={index}>{elm}</li>
                            )
                        )}
                    </ul>
                    <p>{Wording.popup.frequency.frequencyExemple}</p>
                </div>
            </CustomModal>
        );
    }

    render() {
        const {
            // packageTitle,
            packageList,
            onChangePaymentFrequency,
            onChoosePackage,
            // onChangeCyclicBill,
            kind,
            paymentFrequency,
            packageID,
            // showSeparator,
            prepaymentFrequencies,
            // showError,

            billingModes: {
                cyclicBillingMode,
                scheduleBillingMode,
                scheduleWithoutPackBillingMode,
            },
            handleChangePackageFields,
            // packages,
            transformAuthorizedPackages,
            // summaryState,
            loading,
        } = this.props;
        const {
            fields: { prices },
            isCustomSeasons,
        } = this.state;
        const isMultiple = packageList.length > 4;
        const isActiveBilling = cyclicBillingMode || loading;

        const SliderClasses = classnames('package-container__slider-carousel', {
            'package-container__slider-carousel--small': packageList.length < 4,
            'disabled-section': isActiveBilling,
        });

        const FrequenciesClasses = classnames('package-container__frequency', {
            'disabled-section': isActiveBilling,
        });

        const currentPackage =
            packageList.find(p => p.id === packageID) ||
            packageList.find(p => p.recommended) ||
            {};

        const ranges = transformAuthorizedPackages
            ? transformAuthorizedPackages(currentPackage.authorizedPackages)
            : [];

        let initialProgress = 0;
        if (prices && prices.length > 1) {
            initialProgress = ranges.findIndex(
                r => r[0] === prices[0] && r[1] === prices[1]
            );
            initialProgress = initialProgress === -1 ? 0 : initialProgress;
        }
        const reducer = (accumulator, current) => {
            const max = current[0] > current[1] ? current[0] : current[1];
            return accumulator > max ? accumulator : max;
        };
        const gaugeMaxValue = ranges ? ranges.reduce(reducer, 0) : 0;
        return (
            <div className="package-container">
                {this.renderModalFrequency()}
                <div className="package-container__title-container">
                    <div className="package-container__title-container__icon">
                        <img
                            alt="energy"
                            src={kind === 'EL' ? ElecIcon : GasIcon}
                        />
                    </div>
                    <h3
                        className="package-container__title-container__title"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        Tu consommes en moyenne{' '}
                        <Typography
                            variant={TypoVariant.SPAN}
                            style={{
                                fontWeight: 700,
                                marginBottom: '-0.5px',
                                marginRight: '5px',
                                marginLeft: '5px',
                                fontSize: '20px',
                            }}
                        >
                            ~{this.getConsumptionPerMonth(kind)} kWh{' '}
                            {kind === 'EL' ? 'd’électricité' : 'de gaz'}
                        </Typography>{' '}
                        par mois
                    </h3>
                </div>
                <div className={SliderClasses}>
                    <Slider
                        infinite={false}
                        speed={500}
                        slidesToShow={4}
                        slidesToScroll={4}
                        arrows={isMultiple}
                        nextArrow={
                            <SlickButtonFix>
                                <NextArrow />
                            </SlickButtonFix>
                        }
                        prevArrow={
                            <SlickButtonFix>
                                <BackArrow />
                            </SlickButtonFix>
                        }
                    >
                        {packageList &&
                            (isMultiple
                                ? buildPackagesForSlider(packageList)
                                : packageList
                            ).map(
                                (
                                    {
                                        amount,
                                        id,
                                        recommended,
                                        quantityMonthly,
                                    },
                                    index
                                ) => (
                                    <PackageElement
                                        key={index}
                                        price={amount}
                                        quantityMonthly={quantityMonthly}
                                        chosen={packageID === id}
                                        recommended={recommended}
                                        onClick={() => {
                                            this.setState({
                                                isCustomSeasons: false,
                                            });
                                            onChoosePackage(id);
                                        }}
                                    />
                                )
                            )}
                    </Slider>
                </div>
                {(scheduleBillingMode || scheduleWithoutPackBillingMode) && (
                    <div className={FrequenciesClasses}>
                        <div style={{ width: '270px', marginRight: '15px' }}>
                            <h3 className="package-container__frequency__title">
                                {Wording.frequency.title}
                            </h3>
                            <p className="package-container__frequency__subtitle">
                                Par exemple, si tu choisis un forfait avec un
                                prépaiement mensuel de 50€ et une fréquence
                                trimestrielle, tu paieras 150€ tous les 3 mois.
                            </p>
                        </div>
                        {prepaymentFrequencies && (
                            <CustomSelector
                                options={prepareFrequenciesToDisplay(
                                    prepaymentFrequencies
                                ).map(it => ({
                                    label: (
                                        <Typography
                                            style={{ fontSize: '14px' }}
                                        >
                                            {it.label}
                                        </Typography>
                                    ),
                                    id: it.frequency,
                                    fee: it.fee + it.currency,
                                }))}
                                value={paymentFrequency}
                                onSelect={e =>
                                    onChangePaymentFrequency({
                                        target: { value: e },
                                    })
                                }
                            />
                        )}
                        {/* {prepaymentFrequencies &&
                            prepareFrequenciesToDisplay(
                                prepaymentFrequencies
                            ).map(({ frequency, fee, currency }, index) => {
                                const label = (
                                    Wording.frequency.values.find(
                                        v => v.value === frequency
                                    ) || {}
                                ).text;
                                return (
                                    <CustomRadio
                                        key={index}
                                        text={label}
                                        value={frequency}
                                        id={`idPrepayment${index}__${kind}`}
                                        description={`${fee}${currency}`}
                                        checked={paymentFrequency === frequency}
                                        handleCheck={onChangePaymentFrequency}
                                    />
                                );
                            })} */}
                    </div>
                )}
                {/* {cyclicBillingMode ? (
                    <div className="package-container__cyclicBill">
                        <div className="package-container__cyclicBill__toggle">
                            <h3>{Wording.option.title}</h3>
                            <CustomToggle
                                text={cyclicBillingMode.label}
                                id={`idCyclicBill__${kind}`}
                                checked={cyclicBill}
                                handleCheck={onChangeCyclicBill}
                            />
                        </div>
                        {Wording.option.descriptions.map((d, index) => (
                            <p key={index}>{d}</p>
                        ))}
                    </div>
                ) : (
                    <>
                        {showError && (
                            <>
                                {packageID === '' && (
                                    <div className="package-container__error">
                                        <span>!</span>
                                        <p>{Wording.error.missingPackage}</p>
                                    </div>
                                )}
                                {paymentFrequency === '' ||
                                    (!paymentFrequency && (
                                        <div className="package-container__error">
                                            <span>!</span>

                                            <p>
                                                {Wording.error.missingFrequency}
                                            </p>
                                        </div>
                                    ))}
                            </>
                        )}
                    </>
                )} */}
                {packageID !== '' && ranges !== undefined && (
                    <div className="package-container__wintsum">
                        <Typography>
                            Tu as des besoins saisonniers : chauffage en hiver,
                            climatisation en été ?
                        </Typography>
                        <Typography style={{ fontWeight: 500 }}>
                            Module ton forfait été/hiver et profite de la remise
                            forfait toute l’année !
                        </Typography>
                        <div className="package-container__wintsum__toggle">
                            <CustomToggleV2
                                id={`winter-summer-${kind}`}
                                checked={isCustomSeasons}
                                handleCheck={v => {
                                    this.handleToggle(v, ranges);
                                    this.setState({
                                        isCustomSeasons: v.target.checked,
                                    });
                                }}
                            />
                            <Typography>
                                Je module mon forfait été/hiver{' '}
                                {kind === 'EL' ? 'électricité' : 'gaz'}
                            </Typography>
                        </div>
                        <img
                            alt="Men show the winter/summer text with long arm"
                            src={MenLongArm}
                        />
                        {isCustomSeasons && (
                            <div>
                                <div className="information-text">
                                    <div className="consumption-information">
                                        <img
                                            src={informationSVG}
                                            alt="information icon"
                                        />
                                        <p>
                                            Ta consommation énergétique peut
                                            varier d’un mois à l’autre.
                                        </p>
                                    </div>
                                    <p>
                                        C’est pour cela que Wekiwi a développé
                                        l’option été/hiver. En activant cette
                                        option tu as la possibilité de choisir
                                        un forfait différent pour l’été et pour
                                        l’hiver. C’est gratuit et cela te permet
                                        de bénéficier pleinement de la remise
                                        forfait.
                                    </p>
                                    <p className="information-text__bold">
                                        Le forfait été est valable pour les mois
                                        d’avril à octobre, et le forfait hiver
                                        pour les mois de novembre à mars.
                                    </p>
                                </div>
                                <EnergyGauge
                                    initial={initialProgress}
                                    maxValue={gaugeMaxValue}
                                    ranges={ranges}
                                    onChange={v =>
                                        handleChangePackageFields(
                                            [v[0], v[1]],
                                            ''
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default PackageContainer;
