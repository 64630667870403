import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactCodeInput from 'react-code-input';
import Title from '../../components/Typography/Title';
import Typography from '../../components/Typography/Typography';
import { CustomButton } from '../../components';
import wording from '../../utils/wording.json';
import RedCross from '../../assets/icons/ic-red-cross.svg';
import { scrollToRefDelay } from '../../utils/scroll';

const Wording = wording.DataScreen;

function ValidateCodeEmail({
    email = 'ton addresse renseigné.',
    isVerificationFail,
    loading,
    onValidateCode = () => {},
    onResendCode = () => {},
}) {
    const [code, setCode] = useState('');
    const rootRef = useRef(null);

    useEffect(() => {
        scrollToRefDelay(rootRef, 0);
    }, []);

    return (
        <div ref={rootRef} className="validate-email-container">
            <Title
                titleFormatGowun={Wording.validate_modal_title[0]}
                titleFormatLexand={Wording.validate_modal_title[1]}
            />
            <Typography className="subtitle">
                Merci de confirmer ton adresse e-mail en écrivant le code que tu
                as reçu sur {email}
            </Typography>
            <div className="validate-email-container__inputs">
                <ReactCodeInput
                    onChange={v => setCode(v)}
                    className={`validate-email-container__inputs__input${
                        isVerificationFail ? '-error' : ''
                    }`}
                    type="number"
                    fields={6}
                />
            </div>
            {isVerificationFail && (
                <div className="validate-email-container__error">
                    <img alt="red Cross" src={RedCross} />
                    <Typography className="validate-email-container__error__text">
                        {Wording.validate_modal_error_code}
                    </Typography>
                </div>
            )}
            <div className="validate-email-container__buttons">
                <CustomButton
                    onClick={() => onValidateCode(code)}
                    title="Valider"
                    color="orange"
                    loading={loading}
                />
                <CustomButton
                    onClick={onResendCode}
                    title="Renvoyer un e-mail"
                    color="gray"
                    loading={loading}
                />
            </div>
        </div>
    );
}

export default ValidateCodeEmail;
