/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';

// import FooterContainer from '../../containers/FooterContainer';
// import NavigationController from '../NavigationController';
// import Screen from '../screen';

// import Lottie from 'react-lottie';
import {
    Header,
    Footer,
    SubscriptionReset,
    TimeOneGlobalTag,
} from '../../components';
// import resources from '../../utils/resources';
import { getParamsFromDecodedUrl, parseQueryString } from '../../utils/helpers';

import * as actions from './MainScreen.actions';
import { getSponsor, getPartner } from '../SummaryScreen/SummaryScreen.actions';

import { logEvent, logChannel } from '../../utils/firebase';
import { initFacebookPixel_v2 } from '../../utils/tags';
import SectionCollapser from '../SectionCollapser';
// import animationData from '../../assets/loader.json';
import LoadingOverlay from '../../components/LoadingOverlay';

export class MainScreen extends React.Component {
    myProps = {
        handleGoNext: () => {
            // this.setState({ canGoNext: bool });
        },
        handleLoadScreen: screenId => {
            const { nextScreenDispatch } = this.props;
            nextScreenDispatch({ screenId });
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            recovering: false,
            // canGoNext: false,
            // isData: false,
            // isReturnUrl: false,
            // isValidationEmail: false,
        };
    }

    componentDidMount() {
        initFacebookPixel_v2();
        const {
            getFormDispatch,
            mainState: { isRecover, errorRecovery },
        } = this.props;

        const url =
            window.location !== window.parent.location
                ? document.referrer
                : document.location.href;

        const params = new URL(url).searchParams;
        const status = params.get('status');
        const id = params.get('id');
        this.setState({ id, status });

        const decodedParams = getParamsFromDecodedUrl();
        const decodedChannel = decodedParams && decodedParams.channel;

        const channel = params.get('channel');
        if (channel || decodedChannel) {
            logEvent('channel', { name: channel || decodedChannel });
            localStorage.setItem('channel', channel || decodedChannel);
        }

        logChannel(channel || decodedChannel || 'WEB');

        const agent = params.get('agent');
        if (agent) {
            localStorage.setItem('agent', agent);
        }

        if (id) {
            const { getOrderDispatch } = this.props;
            this.setState({ recovering: true });
            getOrderDispatch({ id });
        } else {
            // const test = params.get('returnUrl');
            // if (test === 'true') {
            //     this.setState({ isReturnUrl: true });
            // }
        }
        if (isRecover || errorRecovery !== null) {
            this.setState({ recovering: false });
        }

        const sponsorId = params.get('sponsor');
        const promotionId = params.get('promotion');
        const mywordId = params.get('myword');
        if (sponsorId) {
            const { getSponsorDispatch } = this.props;
            getSponsorDispatch({ code: sponsorId, role: 'SPONSOR' });
        } else if (promotionId) {
            const { getSponsorDispatch } = this.props;
            getSponsorDispatch({ code: promotionId, role: 'PROMOTION' });
        } else if (mywordId) {
            const { getSponsorDispatch } = this.props;
            getSponsorDispatch({ code: mywordId, role: 'MY_WORLD' });
        }

        const partnerReference = params.get('partnerReference');
        if (partnerReference) {
            const { getPartnerDispatch } = this.props;
            getPartnerDispatch({ partnerReference });
        }
        const urlObj = new URL(url);
        if (urlObj.search && urlObj.search.length > 0) {
            const obj = parseQueryString(urlObj.search.substring(1));
            const energyTypes = params.getAll('energyTypes[]');
            const payload = {
                ...obj,
                energyTypes,
                'energyTypes[]': undefined,
            };
            getFormDispatch(payload);
        }

        if (decodedParams && Object.keys(decodedParams).length > 0) {
            getFormDispatch(decodedParams);
        }
    }

    componentDidUpdate() {
        this.onUpdate();
    }

    onUpdate() {
        const {
            mainState: { isRecover, errorRecovery },
        } = this.props;
        const { recovering } = this.state;

        if (recovering && (isRecover || errorRecovery !== null)) {
            this.setState({ recovering: false });
        }
    }

    handleLoadScreen = () => {
        // const { canGoNext, isData } = this.state;
        // if (canGoNext || isData) {
        //     document.body.scrollTop = 0;
        //     document.documentElement.scrollTop = 0;
        //     if (validate) {
        //         validate();
        //     }
        // }
    };

    handleDisabledValidate = disabled => {
        if (disabled !== undefined) {
            disabled();
        }
    };

    handleReturnButton = returnButton => {
        if (returnButton !== undefined) {
            returnButton();
        }
    };

    // handleValidationEmail = statut => {
    //     this.setState({ isValidationEmail: statut });
    // };

    handlePreviousScreen = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        const { previousScreenDispatch } = this.props;
        previousScreenDispatch({});
    };

    handleSaveOrder = saveData => {
        const {
            dataState,
            summaryState,
            userState,
            paymentState,
            saveOrderDispatch,
        } = this.props;
        if (saveData !== undefined) {
            const payload = saveData();
            if (payload) {
                if (payload.mergeProps) {
                    saveOrderDispatch({
                        ...userState,
                        ...dataState,
                        ...summaryState,
                        ...paymentState,
                        ...payload,
                    });
                    return;
                }
                saveOrderDispatch(payload);
                return;
            }
        }
        saveOrderDispatch({
            ...userState,
            ...dataState,
            ...summaryState,
            ...paymentState,
        });
    };



    handleGetContract = saveData => {
        const {
            dataState,
            summaryState,
            userState,
            paymentState,
            getContractDispatch,
        } = this.props;
        if (saveData !== undefined) {
            const payload = saveData();
            if (payload) {
                if (payload.mergeProps) {
                    getContractDispatch({
                        ...userState,
                        ...dataState,
                        ...summaryState,
                        ...paymentState,
                        ...payload,
                    });
                    return;
                }
                getContractDispatch(payload);
                return;
            }
        }
        getContractDispatch({
            ...userState,
            ...dataState,
            ...summaryState,
            ...paymentState,
        });
    };

    render() {
        const {
            mainState: { isRecover, errorRecovery },
            userState: { energyTypes },
            paymentState: { reference },
            summaryState,
        } = this.props;
        const { recovering } = this.state;

        // const { isReturnUrl, isValidationEmail } = this.state;
        // const currentScreen = NavigationController({
        //     type: (screenIds.slice(-1) || [Screen.USER])[0],
        //     ...this.myProps,
        //     isValidationEmail,
        // });

        // // const { title } = currentScreen.type.navigationOptions;

        // // const mainTitle = isValidationEmail
        // //     ? 'Validation adresse email'
        // //     : title;

        // const defaultOptions = {
        //     loop: true,
        //     autoplay: true,
        //     animationData,
        // };
        // if (isReturnUrl) {
        //     return null;
        // }

        const prices = {};
        energyTypes.forEach(energy => {
            if (!summaryState[energy].cyclicBill) {
                prices[energy] = summaryState[energy].chosenPackages.map(
                    p => p.amount
                );
            }
        });
        return (
            <div className="main-screen">
                <TimeOneGlobalTag
                    productId={energyTypes}
                    cartAmount={prices}
                    orderID={reference}
                />
                <Header />
                <div className="main">
                    <LoadingOverlay
                        fullscreen
                        loading={recovering && !errorRecovery && !isRecover}
                    />
                    {/* {mainTitle && (
                        <section className="main__title">
                            <h1>{mainTitle}</h1>
                            <img
                                src={resources['ic-logo-wekiwi']}
                                alt="main__title__kiwi-logo"
                            />
                        </section>
                    )} */}

                    {/* <div className="main__content">{currentScreen}</div> */}
                    {/* {status === 'failure' ? (
                        <FailureSCScreen
                            summaryState={this.props.summaryState}
                        />
                    ) : status === 'cancel' ? (
                        <CancelSCScreen
                            summaryState={this.props.summaryState}
                        />
                    ) : status === 'success' ? (
                        <FinalScreen summaryState={this.props.summaryState} />
                    ) : (
                        <SectionCollapser handleSave={this.handleSaveOrder} />
                    )} */}
                    <SectionCollapser handleSave={this.handleSaveOrder} />

                    {/* {loading && (
                        <div className="main__loader">
                            <Lottie
                                options={defaultOptions}
                                height={100}
                                width={100}
                                isStopped={false}
                            />
                        </div>
                    )} */}

                    {/* <FooterContainer
                        canComeBack={isRecover && screenId === Screen.DATA}
                        {...currentScreen.type.navigationOptions}
                        canGoNext={canGoNext}
                        handlePreviousScreen={this.handlePreviousScreen}
                        handleLoadScreen={this.handleLoadScreen}
                        handleDisabledValidate={this.handleDisabledValidate}
                        handleReturnButton={this.handleReturnButton}
                        showTimeline
                        error={error}
                        handleSave={this.handleSaveOrder}
                        handleValidationEmail={this.handleValidationEmail}
                    /> */}
                </div>

                <SubscriptionReset />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    mainState: state.mainReducer,
    userState: state.userReducer,
    dataState: state.dataReducer,
    packageState: state.packageReducer,
    summaryState: state.summaryReducer,
    paymentState: state.paymentReducer,
});

const mapDispatchToProps = dispatch => ({
    previousScreenDispatch: payload =>
        dispatch(actions.goPreviousScreen(payload)),
    nextScreenDispatch: payload => dispatch(actions.goNextScreen(payload)),
    saveOrderDispatch: payload => dispatch(actions.saveOrder(payload)),
    getOrderDispatch: payload => dispatch(actions.getOrder(payload)),
    getSponsorDispatch: payload => dispatch(getSponsor(payload)),
    getPartnerDispatch: payload => dispatch(getPartner(payload)),
    getFormDispatch: payload => dispatch(actions.getForm(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
