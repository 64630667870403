import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Arrow from '../../assets/icons/ic-small-arrow.png';

const CustomButton = ({
    title,
    color,
    disabledBackgroundColor = 'white',
    onClick,
    arrowLeft,
    arrowRight,
    disabled,
    onMouseEnter,
    lowPadding = false,
    className,
    loading = false,
}) => (
    <button
        className={`custom-button custom-button--${color} ${
            lowPadding
                ? 'custom-button-low-padding'
                : 'custom-button-normal-padding'
        } ${className || ''}`}
        style={
            disabled || loading
                ? { backgroundColor: disabledBackgroundColor }
                : {}
        }
        type="button"
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        disabled={disabled || loading}
    >
        {arrowLeft && <i className="custom-button__arrowLeft" />}
        {title}
        {loading && (
            <FontAwesomeIcon
                icon={faCircleNotch}
                spin
                size="lg"
                style={{
                    color: 'rgb(81 81 81)',
                    marginLeft: arrowRight ? '0.5rem' : '0.8rem',
                    marginRight: !arrowRight && '-1.5rem',
                }}
            />
        )}
        {arrowRight && (
            <img
                src={Arrow}
                alt="icon"
                className="custom-button__imgArrowRight"
            />
        )}
    </button>
);

export default CustomButton;
