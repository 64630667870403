import React from 'react';
import OptionElement from '../../OptionElement';

const EnvironmentalOptions = ({
    summaryState,
    energyTypes,
    fields,
    handleToggleAdditionalOptions,
}) => {
    const energy = energyTypes.find(_ => _ === 'EL') ? 'EL' : 'NG';

    const additionalOptions = fields[energy].additionalOptions;
    const { offer } = summaryState[energy];
    const additionalRatesList =
        (offer &&
            offer.product &&
            offer.product.additionalRates &&
            offer.product.additionalRates.filter(a => a.type === 'OPTION')) ||
        [];
    return (
        <div>
            {additionalRatesList && additionalRatesList.length !== 0 && (
                <OptionElement
                    options={additionalRatesList}
                    currentAdditionalOptions={additionalOptions}
                    handleToggleAdditionalOptions={
                        handleToggleAdditionalOptions
                    }
                />
            )}
        </div>
    );
};

export default EnvironmentalOptions;
