import React from 'react';
import {
    FontFamilyVariant,
    FontSizeVariant,
    FontWeightVariant,
    TypoVariant,
} from '../../constants/enums';
import './_Typography.scss';

const FontSize = Object.freeze({
    [FontSizeVariant.XTRA_SMALL]: 'fsize-xtra_small',
    [FontSizeVariant.SMALL]: 'fsize-small',
    [FontSizeVariant.NORMAL]: 'fsize-normal',
    [FontSizeVariant.TITLE]: 'fsize-title',
    [FontSizeVariant.SUPRA]: 'fsize-supra',
});

const FontWeight = Object.freeze({
    [FontWeightVariant.NORMAL]: 'fwheight-normal',
    [FontWeightVariant[500]]: 'fwheight-500',
    [FontWeightVariant[700]]: 'fwheight-700',
});

const FontFamily = Object.freeze({
    [FontFamilyVariant.GOWUN]: 'family-gowun',
    [FontFamilyVariant.LEXEND]: 'family-lexend',
});

/**
 *
 * @param variant define the typographie render element
 * @param rest all props passed into render element
 * @param className added to existing className
 * @param fontFamily font family (use Object in constant FontFamily.*)
 * @param fontSize added font size (use Object in constant FontSize.*)
 * @returns typo
 */
function Typography({
    variant,
    children,
    fontFamily,
    fontWeight,
    fontSize,
    className = '',
    ...rest
}) {
    const fontSizeClassName = FontSize[fontSize] || FontSize.NORMAL;
    const fontFamilyClassName = FontFamily[fontFamily] || FontFamily.LEXEND;
    const fontWeightClassName = FontWeight[fontWeight] || FontWeight.NORMAL;

    const classes = [
        'base-typo',
        fontSizeClassName,
        fontFamilyClassName,
        fontWeightClassName,
        className,
    ];
    const fullClassName = classes.join(' ');

    switch (variant) {
        case TypoVariant.PARAGRAPH:
            return (
                <p {...rest} className={fullClassName}>
                    {children}
                </p>
            );
        case TypoVariant.SPAN:
            return (
                <span {...rest} className={fullClassName}>
                    {children}
                </span>
            );
        // No styling made for H* element
        case TypoVariant.H1:
            return (
                <h1 {...rest} className={fullClassName}>
                    {children}
                </h1>
            );
        case TypoVariant.H2:
            return (
                <h2 {...rest} className={fullClassName}>
                    {children}
                </h2>
            );
        case TypoVariant.H3:
            return (
                <h3 {...rest} className={fullClassName}>
                    {children}
                </h3>
            );
        case TypoVariant.H4:
            return (
                <h4 {...rest} className={fullClassName}>
                    {children}
                </h4>
            );
        case TypoVariant.H5:
            return (
                <h5 {...rest} className={fullClassName}>
                    {children}
                </h5>
            );
        case TypoVariant.H6:
            return (
                <h6 {...rest} className={fullClassName}>
                    {children}
                </h6>
            );
        default:
            return (
                <p {...rest} className={fullClassName}>
                    {children}
                </p>
            );
    }
}

export default Typography;
