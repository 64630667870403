import ReactPixel from 'react-facebook-pixel';
import Config from './config';

function gtagReportConversion() {
    if (Config.ENV !== 'PREPRODUCTION') {
        window.gtag('event', 'conversion', {
            send_to: 'AW-687511518/hJu0CLS7nbgBEN6v6scC',
        });
    }
    return false;
}

function facebookPixel() {
    if (Config.ENV !== 'PREPRODUCTION') {
        ReactPixel.fbq('track', 'Purchase', {});
    }
}

function initFacebookPixel_v2() {
    if (Config.ENV !== 'PREPRODUCTION') {
        ReactPixel.init(
            Config.FACEBOOK_PIXEL_ID_V2,
            {},
            { debug: true, autoConfig: false }
        );
        ReactPixel.pageView();
    }
}

export { gtagReportConversion, facebookPixel, initFacebookPixel_v2 };
