import {
    PAYMENT_MODAL_LOAD,
    PAYMENT_MODAL_VALIDATE,
    PAYMENT_MODAL_VALIDATE_SUCCESS,
    PAYMENT_MODAL_VALIDATE_FAILURE,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE,
    FINAL_SUMMARY_SCREEN_SET_REFERENCE,
    FIRST_PAYMENT_CB_FAILURE,
    VALIDATE_DIRECT_DEBIT_PAYMENT,
    MAIN_SCREEN_GET_ORDER,
    MAIN_SCREEN_SAVE_ORDER_SUCCESS,
    FAILURE_CB_MESSAGE,
} from '../../screens/types';

export const initialState = {
    reference: '',
    externalReference: '',
    iframe: null,
    paymentType: '',
    status: '',
    firstPaymentCBStatus: '',
    stripe: null,
    isReferenceRemoved: false,
    currentOrder: undefined,
    isDirectDebitSuccess: false,
    createdBy: undefined,
    failureCBMessage: null,
    situation: '',
    firstPaymentSlimCollectStatus: '',
    typePayment: '',
};

function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case MAIN_SCREEN_GET_ORDER: {
            return {
                ...state,
                ...initialState,
            };
        }
        case PAYMENT_MODAL_LOAD: {
            
            const regex = /<iframe.*?src="(.*?)"/;
            const res = regex.exec(action.payload.iframe)
            let iframe;
            if(res){
                iframe = res ? res[1] : null;
            }else {
                iframe = action.payload.iframe
            }
        

            const {
                orderStatus,
                firstPaymentCBStatus,
                firstPaymentSlimCollectStatus,
                orderNumber,
                paymentType,
                orderExternalReference,
                stripe,
                situation,
            } = action.payload;
            return {
                ...state,
                currentOrder: action.payload.order
                    ? action.payload.order
                    : undefined,
                iframe,
                situation,
                stripe: stripe || null,
                reference: orderNumber,
                externalReference: orderExternalReference,
                paymentType: paymentType && paymentType,
                status: orderStatus && orderStatus,
                firstPaymentCBStatus:
                    firstPaymentCBStatus && firstPaymentCBStatus,
                firstPaymentSlimCollectStatus:
                    firstPaymentSlimCollectStatus && firstPaymentSlimCollectStatus,
                typePayment : action.payload.order ? action.payload.order.customer.typePayment : undefined,
            };
        }

        case FIRST_PAYMENT_CB_FAILURE:
        case PAYMENT_MODAL_VALIDATE:
            return {
                ...state,
                iframe: null,
                situation: null,
                paymentType: '',
                stripe: null,
            };

        case VALIDATE_DIRECT_DEBIT_PAYMENT: {
            return {
                ...state,
                firstPaymentCBStatus: action.payload.firstPaymentCBStatus,
                firstPaymentSlimCollectStatus: action.payload.firstPaymentSlimCollectStatus,
                isDirectDebitSuccess: true,
            };
        }

        case PAYMENT_MODAL_VALIDATE_SUCCESS:
            const { orderStatus } = action.payload;
            return {
                ...state,
                status: orderStatus && orderStatus,
                iframe: null,
                situation: null,
                stripe: null,
            };
        case FINAL_SUMMARY_SCREEN_VALIDATE_FAILURE: {
            return {
                ...state,
                isReferenceRemoved: true,
            };
        }
        case FINAL_SUMMARY_SCREEN_SET_REFERENCE:
            return {
                ...state,
                isReferenceRemoved: false,
            };

        case FAILURE_CB_MESSAGE: {
            const { message } = action.payload;
            return {
                ...state,
                failureCBMessage: message,
            };
        }

        case PAYMENT_MODAL_VALIDATE_FAILURE: {
            const {
                reference,
                externalReference,
                isDirectDebitSuccess,
                currentOrder,
            } = state;
            return {
                ...initialState,
                reference,
                externalReference,
                isDirectDebitSuccess,
                currentOrder,
            };
        }

        case MAIN_SCREEN_GET_ORDER_SUCCESS: {
            const { order } = action.payload;
            return {
                ...state,
                reference: order.orderReference,
                status: order.orderStatus,
                createdBy: order.createdBy,
                timeUnblock: order.timeUnblock,
                retryCB: order.retryCB,
                iframe: null,
                situation: null,
                firstPaymentSlimCollectStatus: order.firstPaymentSlimCollectStatus,
                firstPaymentCBStatus: order.firstPaymentCBStatus,
                typePayment: order.customer.typePayment,
                currentOrder: order,
            };
        }

        case MAIN_SCREEN_SAVE_ORDER_SUCCESS: {
            const { orderNumber } = action.payload;
            return {
                ...state,
                reference: orderNumber,
                currentOrder: action.payload.order,
            };
        }
        default:
            return state;
    }
}

export default paymentReducer;
