import React from 'react';
import {
    FontFamilyVariant,
    FontSizeVariant,
    FontWeightVariant,
    TypoVariant,
} from '../../constants/enums';
import Typography from './Typography';

function Title({
    titleFormatGowun,
    titleFormatLexand,
    className,
    icon,
    noGap = false,
    style = {},
}) {
    return (
        <div className={`title-wrapper ${className}`} style={style}>
            <div
                className={`base-title ${className}`}
                style={noGap ? { gap: 0 } : {}}
            >
                <Typography
                    fontFamily={FontFamilyVariant.GOWUN}
                    fontSize={FontSizeVariant.TITLE}
                    fontWeight={FontWeightVariant[700]}
                    variant={TypoVariant.SPAN}
                >
                    {titleFormatGowun}
                </Typography>{' '}
                <Typography
                    fontFamily={FontFamilyVariant.LEXEND}
                    fontWeight={FontWeightVariant[500]}
                    fontSize={FontSizeVariant.TITLE}
                    variant={TypoVariant.SPAN}
                >
                    {titleFormatLexand}
                </Typography>
            </div>
            {icon && (
                <div className="title-icon">
                    <img src={icon} alt="icon" />{' '}
                </div>
            )}
        </div>
    );
}

export default Title;
