import React from 'react';
import { connect } from 'react-redux';
import Screen from '../screen';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import './_CancelSCScreen.scss';
import * as actions from '../FinalSummaryScreen/FinalSummaryScreen.actions';
import * as actionsCB from '../../containers/PaymentModal/PaymentCBModal.actions';
import { CustomButton } from '../../components';
import { scrollToRefDelay } from '../../utils/scroll';
import { multiplicationInstallmentFrequency } from '../../utils/helpers';
import * as paymentActions from '../../containers/PaymentModal/PaymentModal.actions';
import WordingData from '../../utils/wording.json';
import PaymentModal from '../../containers/PaymentModal/PaymentModal';
import resources from '../../utils/resources';
import { TagLogger, log } from '../../utils/logger';
import LoadingOverlay from '../../components/LoadingOverlay';
import config from '../../utils/config';

const Wording = WordingData.FailureSC;
const WordingCancel = WordingData.CancelSC;

export class CancelSCScreen extends React.Component {
    static navigationOptions = {
        nextScreen: Screen.FINALSUMMARY,
        previousScreen: Screen.SUMMARY,
        buttonNextTitle: Wording.nextButton,
        buttonPreviousTitle: Wording.previousButton,
        title: Wording.title,
        step: 13,
        validate: () => {},
        disabled: () => {},
        returnButton: () => {},
        saveData: () => {},
        setStepById: null,
        screenId: Screen.CANCEL_SLIMCOLLECT,
    };

    constructor(props) {
        super(props);
        this.state = {
            isConfirmed: false,
            isCommunicationConfirmed: false,
            showValidationModal: false,
            acceptTerms: false,
            acceptEmail: false,
            iframeOpened: false,
            showPaymentModal: false,
        };
        this.myRef = React.createRef();
        this.onUpdate = this.onUpdate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.energyByType = this.energyByType.bind(this);
        this.searchFrequency = this.searchFrequency.bind(this);
    }

    searchFrequency = (val, type) =>
        Wording.frequency[type].find(el => el.value === val).text || '';

    onUpdate() {
        const { dataState } = this.props;
        const { showValidationModal } = this.state;
        if (dataState.emailValid.isValid && showValidationModal)
            this.setState({ showValidationModal: false });
    }

    componentDidMount() {
        const { isCurrentScreen } = this.props;
        const { showValidationModal } = this.state;
        if (!showValidationModal && isCurrentScreen)
            scrollToRefDelay(this.myRef, 200);
        log({
            tag: TagLogger.RETRY_PAYMENT,
            returnCode: '200',
            logMessage: null,
            serviceCalled: null,
            statusAction: 'SUCCESS',
            triggerButton: 'auto-loading',
        });
    }

    nextScreenValidation = () => true;

    energyByType = energy => {
        switch (energy) {
            case 'EL':
                return 'électricité';
            case 'NG':
                return 'gaz naturel';
            default:
                return '';
        }
    };

    renderOptionInformation = (energy, summaryState) => (
        <ul>
            {summaryState[energy].additionalOptions.map((item, itemIndex) => {
                const labelOption = item;
                const option = summaryState[
                    energy
                ].offer.product.additionalRates.filter(
                    items => items.label === labelOption
                )[0];
                return (
                    <li key={itemIndex}>
                        <span>{`L'option ${Wording.option_package_title[
                            item
                        ].toLowerCase()}`}</span>{' '}
                        {`qui ajoute + ${option.detail.price}${
                            option.detail.unit === '€/MONTH' ? '€/mois' : '€'
                        } ${Wording.option_package[item]}`}
                    </li>
                );
            })}
        </ul>
    );

    renderPackageInformation() {
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;
        return (
            <ul style={{ marginRight: '40px' }}>
                {energyTypes.map((item, itemIndex) => {
                    if (summaryState[item].cyclicBill === true) {
                        return (
                            <li key={itemIndex}>
                                <span>{`Un offre ${this.energyByType(
                                    item
                                )} avec facturation cyclique `}</span>
                                {Wording.cyclicOffer}
                                {this.renderOptionInformation(
                                    item,
                                    summaryState
                                )}
                            </li>
                        );
                    }
                    if (summaryState[item].chosenPackages.length > 1) {
                        const winterData = summaryState[
                            item
                        ].chosenPackages.filter(
                            season => season.timeframe === 'WINTER'
                        )[0];
                        const summerData = summaryState[
                            item
                        ].chosenPackages.filter(
                            season => season.timeframe === 'SUMMER'
                        )[0];

                        return (
                            <li key={itemIndex}>
                                <span>{`Un forfait ${this.energyByType(
                                    item
                                )} été/hiver`}</span>
                                {` de ${
                                    summerData.packageQuantity
                                } kWh (été) à ${summerData.amount}${
                                    summerData.currency
                                }/mois et 
                                    ${
                                        winterData.packageQuantity
                                    } kWh (hiver) à ${winterData.amount}${
                                    winterData.currency
                                }/mois avec une frequence de prélèvement 
                                    ${this.searchFrequency(
                                        summaryState[item].installmentFrequency,
                                        'values'
                                    ).toLowerCase()}.`}
                                {this.renderOptionInformation(
                                    item,
                                    summaryState
                                )}
                            </li>
                        );
                    }
                    return (
                        <li key={itemIndex}>
                            <span>{`Un forfait ${this.energyByType(
                                item
                            )} `}</span>
                            {`de ${
                                summaryState[item].chosenPackages[0]
                                    .packageQuantity
                            } kWh à ${
                                summaryState[item].chosenPackages[0].amount
                            }${
                                summaryState[item].chosenPackages[0].currency
                            }/mois avec une frequence de prélèvement
                                    ${this.searchFrequency(
                                        summaryState[item].installmentFrequency,
                                        'values'
                                    ).toLowerCase()}.`}
                            {this.renderOptionInformation(item, summaryState)}
                        </li>
                    );
                })}
            </ul>
        );
    }

    componentDidUpdate(prevProps) {
        const {
            paymentState,
            paymentReducer: { iframe },
        } = this.props;

        this.onUpdate();

        if (
            paymentState &&
            paymentState.isDirectDebitSuccess &&
            paymentState.firstPaymentCBStatus !== 'PENDING' &&
            paymentState.orderStatus === 'FINALIZED'
        ) {
            const { setStepById } = this.props;
            setStepById('FINAL_SCREEN');
        }

        if (
            iframe &&
            iframe !== prevProps.paymentReducer.iframe &&
            !this.state.iframeOpened
        ) {
            if (iframe.startsWith('<iframe')) {
                window.open(encodeURI(iframe), '_self');
                this.setState({ iframeOpened: true });
            }
        }

        if (
            iframe &&
            iframe !== prevProps.paymentReducer.iframe &&
            this.state.redirectToNewPage
        ) {
            window.open(encodeURI(iframe), '_self');
        }
    }

    callOrderStatus = () => {
        const {
            paymentState: { currentOrder, externalReference, reference },
            validatePaymentScreenDispatch,
        } = this.props;

        const prepareOrder = {
            ...currentOrder,
            externalReference,
            orderReference: reference,
        };

        validatePaymentScreenDispatch({
            reference,
            prepareOrder,
        });
    };

    handleChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    prepareDataToConfirmationModal = () => {
        const {
            userState: { energyTypes },
            summaryState,
        } = this.props;
        const reduce = (acc, current) => {
            const {
                installmentFrequency,
                chosenPackages,
                cyclicBill,
            } = summaryState[current];

            if (!cyclicBill) {
                if (chosenPackages.length > 1) {
                    return [
                        ...acc,
                        {
                            energy: current,
                            type: 'SUMMER_WINTER',
                        },
                    ];
                }
                const sumAmountByPackage = chosenPackages.reduce(
                    (a, c) => parseFloat(c.amount) + a,
                    0
                );
                const sumAmountByPackageFrequency = multiplicationInstallmentFrequency(
                    installmentFrequency,
                    sumAmountByPackage
                );

                return [
                    ...acc,
                    {
                        energy: current,
                        sumAmountByPackage,
                        sumAmountByPackageFrequency,
                        installmentFrequency,
                        type: 'BASE',
                    },
                ];
            }

            return [
                ...acc,
                {
                    energy: current,
                    type: 'CYCLIC_BILL',
                },
            ];
        };

        return energyTypes.reduce(reduce, []);
    };

    isPaymentCB = () => {
        const {
            summaryState: { contracts },
        } = this.props;

        return contracts.reduce(
            (acc, { firstPaymentCB }) =>
                acc || (firstPaymentCB !== undefined ? firstPaymentCB : false),
            false
        );
    };

    handleRecallCB = () => {
        const {
            paymentState: { reference, currentOrder, externalReference },
            recallPaymentCBDispatch,
        } = this.props;
        this.setState({ showPaymentModal: true });
        const newOrder = {
            ...currentOrder,
            externalReference,
            orderReference: reference,
        };
        recallPaymentCBDispatch({ order: { ...newOrder }, whenCollected:  config.ChannelsDoorToDoor.includes(localStorage.getItem("channel")) ? "LATER" : "NOW"});
    };

    handleValidate = async () => {
        const {
            userState,
            dataState,
            summaryState,
            paymentState,
            paymentReducer,
            validateFinalSummaryScreenDispatch,
        } = this.props;

        if (
            dataState &&
            dataState.common &&
            dataState.common.birthdate === 'Invalid Date'
        ) {
            dataState.common.birthdate = '';
        }

        const { iframe } = paymentReducer;

        if (
            paymentState &&
            paymentState.isDirectDebitSuccess &&
            paymentState.firstPaymentCBStatus !== 'PENDING' &&
            paymentState.orderStatus === 'FINALIZED'
        ) {
            const { setStepById } = this.props;
            setStepById('FINAL_SCREEN');
            return Promise.resolve();
        }

        if (iframe && !this.state.iframeOpened) {
            await new Promise(resolve => {
                const newWindow = window.open(iframe, '_blank');

                if (newWindow) {
                    const interval = setInterval(() => {
                        if (newWindow.closed) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 100);
                } else {
                    resolve();
                }
            });
        }

        await validateFinalSummaryScreenDispatch({
            ...userState,
            ...dataState,
            ...summaryState,
            ...paymentState,
            retryCB: false,
        });

        this.setState({
            iframeOpened: false,
            redirectToNewPage: true,
            showPaymentModal: false,
        });
        return Promise.resolve();
    };

    getPriceTotalByMonth = frequency => {
        const foundNumberOfMonth = Wording.frequency.days.find(
            day => day.value === frequency
        );
        return foundNumberOfMonth.text;
    };

    handleFinalized = () => {
        const { setStepById } = this.props;
        setStepById('FINAL_SCREEN');
    };

    render() {
        const {
            mainState: { loading },
            paymentReducer: { iframe, stripe, situation },
        } = this.props;
        const { isConfirmed, isCommunicationConfirmed } = this.state;
        const paymentActivated =
            (isConfirmed && isCommunicationConfirmed) ||
            iframe !== null ||
            stripe !== null ||
            situation !== 'MES';

        return (
            <>
                <div className="">
                    <LoadingOverlay loading={loading} fullscreen />
                    <SectionLayout>
                        <div className="failure-sc-screen">
                            <div>
                                <img
                                    src={resources['ic-erreur']}
                                    alt="ic-erreur"
                                />
                                <div className="failure-sc-screen__failure">
                                    <span>{WordingCancel.SC_cancel}</span>
                                </div>
                                <div className="failure-sc-screen__att">
                                    {Wording.attention}
                                </div>
                                <div className="failure-sc-screen__failure">
                                    {Wording.solution_CB}
                                </div>
                            </div>

                            <div className="btn">
                                <CustomButton
                                    color="gray"
                                    title={Wording.btn_finalized}
                                    onClick={this.handleRecallCB}
                                />
                                <div>
                                    <CustomButton
                                        color="orange"
                                        title={Wording.btn_reload}
                                        onClick={this.handleValidate}
                                    />
                                </div>
                            </div>
                            {this.state.showPaymentModal && paymentActivated ? (
                                <PaymentModal setStepById={() => {}} />
                            ) : null}
                        </div>

                        <div className="failure-sc-screen__footer" />
                    </SectionLayout>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    dataState: state.dataReducer,
    paymentReducer: state.paymentReducer,
    summaryState: state.summaryReducer,
    userState: state.userReducer,
    packageState: state.packageReducer,
    mainState: state.mainReducer,
    paymentState: state.paymentReducer,
});

const mapDispatchToProps = dispatch => ({
    validateFinalSummaryScreenDispatch: payload =>
        dispatch(actions.validateFinalSummaryScreen(payload)),
    validatePaymentScreenDispatch: payload =>
        dispatch(paymentActions.validatePaymentScreen(payload)),
    recallPaymentCBDispatch: payload =>
        dispatch(actionsCB.recallPaymentCB(payload)),
    redirectFinalScreenDispatch: payload =>
        dispatch(actionsCB.redirectFinalScreen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelSCScreen);
