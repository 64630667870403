import IconProfessional from '../assets/icons/ic-professional.svg';
import IconIndividual from '../assets/icons/ic-individual.png';

import IconElecOff from '../assets/icons/ic-elec-off.png';
import IconElecOn from '../assets/icons/ic-elec-on.png';
import IconGasOff from '../assets/icons/ic-gas-off.png';
import IconGasOn from '../assets/icons/ic-gas-on.png';

import IconElecOffSvg from '../assets/icons/ic-elec-off.svg';
import IconElecOnSvg from '../assets/icons/ic-elec-on.svg';
import IconGasOffSvg from '../assets/icons/ic-gas-off.svg';
import IconGasOnSvg from '../assets/icons/ic-gas-on.svg';

import IconElecGasOff from '../assets/icons/ic-elec-gas-off.svg';
import IconElecGasOn from '../assets/icons/ic-elec-gas-on.svg';
import IconApartment from '../assets/icons/ic-apartment.png';
import IconSecondary from '../assets/icons/ic-secondary.png';
import IconCollectivityOn from '../assets/icons/ic-collectivity-on.png';
import IconCollectivityOff from '../assets/icons/ic-collectivity-off.png';
import IconBill from '../assets/icons/ic-bill.svg';
import IconWeiwi from '../assets/icons/ic-wekiwi.svg';
import IconEnvelop from '../assets/icons/ic-envelop.svg';
import IconLogo from '../assets/icons/ic-logo-wekiwi.svg';
import IconLogoText from '../assets/icons/ic-logo.svg';
import IconHeaderLogo from '../assets/icons/ic-header-logo.svg';
import IconBot from '../assets/icons/ic-bot.svg';
import IconElecGreen from '../assets/icons/ic-elec-green.svg';
import IconLinkedin from '../assets/icons/ic-linkedin.svg';
import IconInstagram from '../assets/icons/ic-instagram.svg';
import IconFacebook from '../assets/icons/ic-facebook.svg';
import IconYoutube from '../assets/icons/ic-youtube.svg';
import IconTwitter from '../assets/icons/ic-twitter.svg';
import IconCertification from '../assets/icons/ic-certification.svg';
import IconLogoLogin from '../assets/icons/ic-logo-login.svg';
import IconAvatar from '../assets/icons/ic-avatar.svg';

import IconWkWSwitch from '../assets/icons/banners/ic-wkw-switch.png';
import IconLogoLelynx from '../assets/icons/banners/ic-logoAGLelynx.png';
import IconSelectraRadins from '../assets/icons/banners/ic-selectra-radins.png';
import IconAGSelectra from '../assets/icons/banners/ic-ag-selectra.png';

import IconArrowBack from '../assets/icons/ic-arrow-back.svg';
import IconArrowNext from '../assets/icons/ic-arrow-next.svg';

import IconCopyClipboard from '../assets/icons/ic-copy-clipboard.svg';
import IconDownload from '../assets/icons/ic-download.png';
import IconWarning from '../assets/icons/ic-warning.svg';
import IconWorld from '../assets/icons/ic-world.svg';
import IconTree from '../assets/icons/ic-tree.svg';
import IconGreenEnergy from '../assets/icons/ic-green-energy.svg';
import Iconsucces from '../assets/icons/ic-succes.svg';
import Iconerreur from '../assets/icons/ic-erreur.svg';
import IconValidateTimer from '../assets/icons/ic-logo-timer.svg';
import IconDownloadTimer from '../assets/icons/ic-download-timer.png';
import IconCloseTime from '../assets/icons/ic-close-timer.svg';
import IcSaveScreen from '../assets/icons/ic-save-screen.png';
import IconBusinessWoman from '../assets/icons/ic-businesswoman.png';
import IconKiwi from '../assets/icons/ic-kiwi.png';
import pdfFile from "../assets/documents/Document d'informations.pdf";
import poweredSlimpay from '../assets/icons/ic-powered-Slimpay.png';
import poweredStripe from '../assets/icons/ic-powered-Stripe.png';
import loginIcon from '../assets/icons/login-icon.png';
import confirmDetails from '../assets/icons/confirm-icon.png';
import homeDetails from '../assets/icons/ic-home-details.png';
import validateDetails from '../assets/icons/ic-validate-details.png';


export default {
    'ic-elec-green': IconElecGreen,
    'ic-logo-wekiwi': IconLogo,
    'ic-logo-text': IconLogoText,
    'ic-header-logo': IconHeaderLogo,
    'ic-bot': IconBot,
    'ic-professional': IconProfessional,
    'ic-individual': IconIndividual,
    'ic-logo-timer': IconValidateTimer,
    'ic-download-timer': IconDownloadTimer,
    'ic-elec-off': IconElecOff,
    'ic-elec-on-p': IconElecOn,
    'ic-close-timer': IconCloseTime,
    'ic-save-screen': IcSaveScreen,

    'ic-gas-off-p': IconGasOff,
    'ic-gas-on': IconGasOn,

    'ic-gas-off': IconGasOffSvg,
    'ic-gas-on-s': IconGasOnSvg,
    'ic-elec-on': IconElecOnSvg,
    'ic-elec-off-s': IconElecOffSvg,

    'ic-collectivity-off': IconCollectivityOff,
    'ic-collectivity-on': IconCollectivityOn,

    'ic-elec-gas-on': IconElecGasOn,
    'ic-elec-gas-off': IconElecGasOff,
    'ic-apartment': IconApartment,
    'ic-secondary': IconSecondary,
    'ic-bill': IconBill,
    'ic-cp': IconWeiwi,
    'ic-envelop': IconEnvelop,
    'ic-linkedin': IconLinkedin,
    'ic-instagram': IconInstagram,
    'ic-facebook': IconFacebook,
    'ic-youtube': IconYoutube,
    'ic-twitter': IconTwitter,
    'ic-certification': IconCertification,
    'ic-logo-login': IconLogoLogin,
    'ic-avatar': IconAvatar,
    'ic-arrow-back': IconArrowBack,
    'ic-arrow-next': IconArrowNext,
    'ic-copy-clipboard': IconCopyClipboard,
    'ic-download': IconDownload,
    'ic-warning': IconWarning,
    'ic-world': IconWorld,
    'ic-tree': IconTree,
    'ic-green-energy': IconGreenEnergy,
    'ic-succes': Iconsucces,
    'ic-erreur': Iconerreur,
    'ic-businesswoman': IconBusinessWoman,
    'ic-poweredSlimpay': poweredSlimpay,
    'ic-poweredStripe' : poweredStripe,
    'ic-login-details': loginIcon,
    'ic-confirm-details': confirmDetails,
    'ic-home-details': homeDetails,
    'ic-validate-details': validateDetails,

    'ic-kiwi': IconKiwi,

    'banners/wkw-switch': IconWkWSwitch,
    'banners/lelynx': IconLogoLelynx,
    'banners/ag-selectra-radins': IconSelectraRadins,
    'banners/ag-selectra': IconAGSelectra,
    'pdf/informationDocument': pdfFile,
};
