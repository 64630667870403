export default {
    URL: process.env.REACT_APP_BACKEND_HOST,
    URL_CS: process.env.REACT_APP_CS_BACKEND_HOST,
    URL_GET_SIRET: 'https://api.insee.fr/entreprises/sirene/V3.11/siret',
    ApiKey: process.env.REACT_APP_API_KEY,
    CS_LINK: process.env.REACT_APP_CS_URL,
    ENV: process.env.REACT_APP_ENV,
    URL_SUB: window.location.origin,
    LamdaConfig: {
        region: 'eu-west-3',
        apiVersion: '2015-03-31',
        accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_LAMBDA_SECRET_KEY,
    },
    URL_PASSPHRASE: process.env.REACT_APP_URL_PASSPHRASE,

    FunctionName: {
        getPackages: process.env.REACT_APP_FUNCTION_GET_PACKAGES,
        getOffers: process.env.REACT_APP_FUNCTION_GET_OFFERS,
    },
    EffectiveStartDate: {
        NOW: {
            EL: {
                businessDays: 7,
                calendarDays: 2,
            },
            NG: {
                businessDays: 7,
                calendarDays: 4,
            },
        },
        LATER: {
            EL: {
                businessDays: 7,
                calendarDays: 2,
            },
            NG: {
                businessDays: 7,
                calendarDays: 4,
            },
        },
    },

    DefaultSwitchDueDate: {
        NOW: {
            EL: 0,
            NG: 0,
        },
        LATER: {
            EL: 0,
            NG: 0,
        },
    },

    EffectiveStartDateWithoutPack: {
        NOW: {
            EL: {
                businessDays: 0,
                calendarDays: 1,
            },
            NG: {
                businessDays: 0,
                calendarDays: 1,
            },
        },
        LATER: {
            EL: {
                businessDays: 0,
                calendarDays: 0,
            },
            NG: {
                businessDays: 0,
                calendarDays: 0,
            },
        },
    },

    FirebaseConfig: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },

    Stripe: {
        publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    },

    FACEBOOK_PIXEL_ID_V2: process.env.REACT_APP_FACEBOOK_PIXEL_ID,

    ChannelsFixSwitchLater: [
        /^UBER-SWITCH_VENTEDOMICILE$/,
        /^PIOUI$/,
        /^PIOUI_.+/,
        /^KOMPAR-ENERGIE$/,
        /^KOMPAR-ENERGIE_.+/,
    ],

    ChannelsDoorToDoor: ['C2E', 'DEPANN', 'GROUPE-POWER', 'POWER-DISTRIB'],

    ChannelInformationDocument: ['WEB', 'WEB-ADS'],

    FinalCodeScreen: {
        withGetPackages: true,
    },
};
