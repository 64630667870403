import { of, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import {
    FIRST_PAYMENT_CB_FAILURE,
    REDIRECT_FINAL_SCREEN,
    RECALL_PAYMENT_CB,
    FAILURE_CB_MESSAGE,
} from '../../screens/types';
import { loadPaymentModal } from './PaymentModal.actions';
import { log, TagLogger, TriggerButtonLogger } from '../../utils/logger';

const redirectFinalScreen = () => ({
    type: REDIRECT_FINAL_SCREEN,
});

const firstPaymentCBFailure = payload => ({
    type: FIRST_PAYMENT_CB_FAILURE,
    payload,
});

const recallPaymentCB = payload => ({
    type: RECALL_PAYMENT_CB,
    payload,
});

const failureCBMessage = payload => ({
    type: FAILURE_CB_MESSAGE,
    payload,
});

const mapGetPaymentCB = (action, { apiRequest }) => {
    const payload = { order: action.payload.order, whenCollected: action.payload.whenCollected };

    const option = {
        path: '/paymentCB',
        method: 'post',
        body: {...payload}
    };

    return apiRequest(option).pipe(
        mergeMap(response => {
            if (response.stripe !== undefined) {
                return of(response);
            }
            const message = response.message || response.errorMessage;

            return throwError({ message });
        }),
        catchError(error => of(error))
    );
};

const recallPaymentCBEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(RECALL_PAYMENT_CB),

        switchMap(
            action => mapGetPaymentCB(action, dependency),

            (action, r) => [action, r]
        ),
        switchMap(([action, paymentResponse]) => {
            if (
                paymentResponse.code === 'NO_RESPONSE_FROM_PARTNER' ||
                paymentResponse.message
            ) {
                log({
                    tag: TagLogger.PAYMENT,
                    returnCode: paymentResponse.code,
                    logMessage: paymentResponse.message,
                    serviceCalled: '/paymentCB',
                    statusAction: 'FAILURE',
                    triggerButton: TriggerButtonLogger.RETRY_PAYMENT_BTN,
                });
                return of(
                    firstPaymentCBFailure({
                        error: paymentResponse.message,
                    })
                );
            }

            log({
                tag: TagLogger.PAYMENT,
                returnCode: '200',
                logMessage: paymentResponse,
                serviceCalled: '/paymentCB',
                statusAction: 'SUCCESS',
                triggerButton: TriggerButtonLogger.RETRY_PAYMENT_BTN,
            });
            return of(
                loadPaymentModal({
                    ...paymentResponse,
                    order: action.payload.order,
                    paymentType: 'PAYMENT_CB',
                })
            );
        }),
        catchError(error =>
            of(
                firstPaymentCBFailure({
                    error: error.message,
                })
            )
        )
    );
const paymentCBScreenEpic = combineEpics(recallPaymentCBEpic);

export {
    firstPaymentCBFailure,
    paymentCBScreenEpic,
    mapGetPaymentCB,
    redirectFinalScreen,
    recallPaymentCB,
    failureCBMessage,
};
