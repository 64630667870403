/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// import Config from '../../utils/config';
// import resources from '../../utils/resources';
import WordingConstant from '../../utils/wording.json';
import resources from '../../utils/resources';
// import { getInitialChannel } from '../../utils/helpers';

const Wording = WordingConstant.Header;

const Header = () => (
    <div className="header">
        <div className="header__container">
            <div className="header__logo">
                <a
                    href={Wording.wekiwiLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={resources['ic-logo-text']} alt="icon" />
                </a>
            </div>
            <div className="header__content">
                <div className="header__content__title">{Wording.title}</div>
                <div className="header__content__title1">{Wording.title1}</div>
                <div className="header__content__subTitle">
                    {Wording.subTitle}
                </div>
            </div>
            <div className="header__image">
                <img src={resources['ic-header-logo']} alt="header-logo" />
            </div>
        </div>
    </div>
);

export default Header;
