import React, { useEffect } from 'react';
import './_LabeledInput.scss';
import Typography from '../Typography/Typography';

function LabeledInput({
    label,
    type,
    name,
    onChange,
    value,
    messages = {},
    defaultValue,
    isLocked = false,
    isLockedV2 = false,
    halfSize = false,
    isFilled = false,
    disabled = false,
    isUpperCase = false,
    isCapital = false,
    onBlur,
    onFocus,
}) {
    const setBackground = () => {
        if (isFilled) return 'labeledinput-root__input__filled';
        if (messages.error) return 'labeledinput-root__input__error';
        return '';
    };

    useEffect(() => {
        if (defaultValue) onChange(defaultValue);
    }, []);

    return (
        <div className="labeledinput-root">
            <input
                onChange={e => onChange(e.target.value)}
                className={`labeledinput-root__input ${setBackground()} 
                ${isLockedV2 ? 'custom-input__div__input--lockedV2' : ''}
                ${isLocked ? ' custom-input__div__input--locked' : ''}
                ${halfSize ? ' custom-input__div__input--halfSize' : ''} 
                ${isCapital ? ' isCapital' : ''}
                ${isUpperCase ? ' isUpperCase' : ''}`}
                id={name}
                value={value}
                type={type}
                disabled={disabled}
                placeholder={label}
                defaultValue={defaultValue}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            {!isLockedV2 && (
                <div className="labeledinput-root__labels">
                    <Typography className="labeledinput-root__labels__error">
                        {messages.error}
                    </Typography>
                    <Typography className="labeledinput-root__labels__info">
                        {messages.info}
                    </Typography>
                </div>
            )}
        </div>
    );
}

export default LabeledInput;
