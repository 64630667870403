import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Config from '../../utils/config';
import CustomModal from '../CustomModal';
import WordingConstant from '../../utils/wording.json';
import { handleResetStore, offerSimple } from '../../utils/helpers';
import resources from '../../utils/resources';
import { prepareContract } from '../../screens/FinalSummaryScreen/Contract.actions';

const Wording = WordingConstant.ValidateTimer;
const WordingLinks = WordingConstant.ValidateTimer;

class ValidateTimer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.isSelectra(),
            loadingContract: false,
        };
    }

    handleReset = e => {
        e.preventDefault();
        handleResetStore();
    };

    formatTime = seconds => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours =
            hours > 0 ? `${hours < 10 ? '0' : ''}${hours}:` : '';

        return `${formattedHours}${minutes < 10 ? '0' : ''}${minutes}:${
            remainingSeconds < 10 ? '0' : ''
        }${remainingSeconds}`;
    };

    handleContract = async () => {
        this.setState({ loadingContract: true });
        try {
            if (localStorage.getItem('reduxState')) {
                const payloadContract = prepareContract(
                    JSON.parse(localStorage.getItem('reduxState'))
                );

                if (
                    Array.isArray(payloadContract) &&
                    payloadContract.length > 0
                ) {
                    const promises = payloadContract.map(
                        async (contract, index) => {
                            try {
                                const headers = {
                                    'x-api-key': Config.ApiKey,
                                };

                                const res = await fetch(
                                    `${Config.URL}/getContract`,
                                    {
                                        method: 'POST',
                                        body: JSON.stringify(contract),
                                        headers,
                                    }
                                );

                                if (res.ok) {
                                    const blob = await res.blob();
                                    const fileURL = URL.createObjectURL(blob);
                                    window.open(fileURL, `_blank_${index}`);
                                } else {
                                    console.error(
                                        `La requête a échoué avec le statut ${res.status}`
                                    );
                                }
                            } catch (error) {
                                console.error(
                                    `Erreur lors de la récupération du contrat (index ${index}):`,
                                    error
                                );
                            }
                        }
                    );
                    await Promise.all(promises);
                    this.setState({ loadingContract: false });
                }
            }
        } catch (error) {
            this.setState({ loadingContract: false });
            console.error(
                'Erreur générale lors de la récupération des contrats :',
                error
            );
        }
    };

    isSelectra = () => localStorage.getItem('channel');

    handleFilePdf = async () => {
        try {
            const pdfUrl = '/static/Document d\'informations.pdf';
            const encodedUrl = encodeURI(pdfUrl);
            const correctedUrl = encodedUrl.replace(/(%2F|%20)/g, match => match === '%2F' ? '/' : ' ');
    
            window.open(
                correctedUrl,
                '_blank',
                'noopener noreferrer'
            );
            
       
        } catch (e) {
            console.error('Impossible de récupérer le fichier pdf', e);
        }
    };
    
    renderModal = () => {
        const { isOpen, timeRemaining, payloadContracts, offers } = this.props;
        const { loadingContract } = this.state;
        const formattedTime = this.formatTime(timeRemaining);
        return (
            <CustomModal contentClassName="custom-modal__timer" show={isOpen}>
                <img
                    className="validate-timer-close-button"
                    src={resources['ic-close-timer']}
                    alt="icon"
                    onClick={e => this.handleReset(e)}
                />
                <p>
                    <>
                        <img
                            className="validate-timer-image"
                            src={resources['ic-logo-timer']}
                            alt="icon"
                        />
                        <span className="validate-timer-clock">
                            {formattedTime}
                        </span>
                    </>
                </p>

                <p>
                    <span>{Wording.popup.infos}</span>
                </p>
                <p>{Wording.popup.subInfos}</p>
                <div className="validate-timer__para">
                    <p>
                        Tout simplement pour que tu aies le temps de bien lire
                        ton document d’informations,
                        <span className="validate-timer-description">
                            {' '}
                            ton contrat, les grilles tarifaires,
                        </span>{' '}
                        ainsi que{' '}
                        <span className="validate-timer-description">
                            {' '}
                            nos conditions générales de vente.
                        </span>
                    </p>
                </div>

                <p>
                    {' '}
                    <img
                        className="validate-timer-img-download"
                        src={resources['ic-download-timer']}
                        alt="icon"
                    />
                    {Wording.popup.question}
                </p>

                <div className="validate-timer-link">
                    {WordingLinks.popup.bottomLinks
                        .filter(link => link.text && (link.link || link.api))
                        .map((link, i) => {
                            if (
                                (link.value.includes(
                                        'INFORMATION_DOCUMENT',
                                    )) && offerSimple(offers) === true
                            ) {
                                return null;
                            }
                            if (link.value === 'INFORMATION_DOCUMENT') {
                                return (
                                    <span
                                        className="validate-timer-link"
                                        key={`linkFooterBottom${i}`}
                                        onClick={this.handleFilePdf}
                                        rel="noopener noreferrer"
                                    >
                                        {link.text}
                                    </span>
                                );
                            }
                            if (link.link) {
                                return (
                                    <a
                                        className="validate-timer-link"
                                        key={`linkFooterBottom${i}`}
                                        href={link.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {link.text}
                                    </a>
                                );
                            }
                            if (link.api) {
                                const isSingleContract =
                                    Array.isArray(payloadContracts) &&
                                    payloadContracts.length === 1;
                                const linkText = isSingleContract
                                    ? 'Ton contrat'
                                    : 'Tes contrats';
                                if (loadingContract) {
                                    return (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                spin
                                                size="lg"
                                                style={{
                                                    color: 'rgb(81 81 81)',
                                                }}
                                            />
                                            <span style={{ color: '#6f49ce' }}>
                                                &nbsp;|
                                            </span>
                                        </>
                                    );
                                }
                                return (
                                    <div
                                        className="validate-timer-link__contract"
                                        key={`linkFooterBottom${i}`}
                                        onClick={this.handleContract}
                                    >
                                        {linkText} |
                                    </div>
                                );
                            }
                            return (
                                <React.Fragment key={`linkFooterBottom${i}`} />
                            );
                        })}
                </div>

                <div className="validate-timer__line" />
            </CustomModal>
        );
    };

    render() {
        const { isOpen } = this.props;
        if (!isOpen) {
            return null;
        }
        return <div className="validate-timer">{this.renderModal()}</div>;
    }
}

const mapStateToProps = state => ({
    timeUnblock: state.paymentReducer.timeUnblock,
    payloadContracts: state.summaryReducer.contracts,
    offers: state.summaryReducer.offers,
});

export default connect(mapStateToProps, null)(ValidateTimer);
