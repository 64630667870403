import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import CodeContainerV2 from '../containers/CodeContainer/CodeContainerV2';
import DataScreen from './DataScreen/DataScreen';
import MinimalUserData from './DataScreen/MinimalUserData';
import PackageScreen from './PackageScreen/PackageScreen';
import UserScreen from './UserScreen';
import ValidationDialogScreen from './ValidationDialogScreen/ValidationDialogScreen';
import FailureCBScreen from './FailureCBScreen/FailureCBScreen';
import FailureSCScreen from './FailureSCScreen/FailureSCScreen';
import CancelSCScreen from './CancelSCScreen/CancelSCScreen';
import FinalPaymentScreen from './FinalPaymentScreen/FinalPaymentScreen';

import { CustomButton } from '../components';
import { CodeScreenMode, SectionsSteps } from '../constants/enums';

import { changeCodeMode } from './CodeScreen/CodeScreen.actions';
import FinalSummaryScreen from './FinalSummaryScreen/FinalSummaryScreen';
import SituationScreen from './SituationScreen';
import SummaryScreen from './SummaryScreen/SummaryScreen';
import './_SectionCollapser.scss';
import SaveScreen from './SaveScreen/SaveScreen';
import FinalScreen from './FinalScreen/FinalScreen';

const STEPS = [
    {
        id: SectionsSteps.STEP_ONE_ENERGY_SELECTOR,
        component: UserScreen,
    },
    {
        id: SectionsSteps.STEP_TOW_USER_VALIDATION,
        component: MinimalUserData,
    },
    {
        id: SectionsSteps.STEP_THREE_PDL_PCE_IDENTIFIER,
        component: CodeContainerV2,
    },
    {
        id: SectionsSteps.STEP_FOUR_PACKAGE_SELECTOR,
        component: PackageScreen,
    },
    {
        id: SectionsSteps.STEP_FIVE_BUDGET_ESTIMATION,
        component: SummaryScreen,
    },
    {
        id: SectionsSteps.STEP_SIX_USER_HABITATION,
        component: DataScreen,
    },
    {
        id: SectionsSteps.STEP_SEVEN_USER_SITUATION,
        component: SituationScreen,
    },
    {
        id: SectionsSteps.STEP_HEIGHT_FINAL_RECAP,
        component: FinalSummaryScreen,
    },
    {
        id: SectionsSteps.STEP_NINE_SIGNATURE,
        component: ValidationDialogScreen,
    },
    {
        id: SectionsSteps.STEP_PAYMENT,
        component: FinalPaymentScreen,
    },
    {
        id: SectionsSteps.FAILURE_SCREEN,
        component: FailureCBScreen,
    },
    {
        id: SectionsSteps.CANCELSC_SCREEN,
        component: CancelSCScreen,
    },
    {
        id: SectionsSteps.FAILURESC_SCREEN,
        component: FailureSCScreen,
    },
    {
        id: SectionsSteps.STEP_TEN_SAVE_DRAFT,
        component: SaveScreen,
    },
    {
        id: SectionsSteps.FINAL_SCREEN,
        component: FinalScreen,
    },
];

const getStepById = id => STEPS.find(it => id === it.id);

function SectionCollapser({
    currentCodeMode,
    changeCodeModeDispatch,
    handleSave,
}) {
    const [steps, setSteps] = useState([
        getStepById(SectionsSteps.STEP_ONE_ENERGY_SELECTOR), // STEP_ONE_ENERGY_SELECTOR
    ]);
    const [canGoNext, setCanGoNext] = useState(false);
    const currentStep = steps[steps.length - 1];
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {

        if (
            currentCodeMode !== CodeScreenMode.NONE &&
            currentCodeMode !== CodeScreenMode.END
        )
            changeCodeModeDispatch({ mode: CodeScreenMode.NONE });
            const url =
            window.location !== window.parent.location
                ? document.referrer
                : document.location.href;
            const params = new URL(url).searchParams;
            const status = params.get('status');
            if(status){
                switch(status){
                    case 'failure':
                        setSteps([getStepById(SectionsSteps.FAILURESC_SCREEN)])
                        break;
                    case 'cancel':
                        setSteps([getStepById(SectionsSteps.CANCELSC_SCREEN)])
                        break
                    case 'success':
                        setSteps([getStepById(SectionsSteps.FINAL_SCREEN)])
                        break
                    default:
                        break
                }
            }
    }, []);

    const resetScreenTo = id => () => {
        const identifier = STEPS.findIndex(step => step.id === id);
        if (identifier === -1) return;
        setSteps(old => {
            const tmp = [...old];
            tmp.splice(identifier + 1, old.length - 1);
            return tmp;
        });
    };

    const secureSetCanGoNext = id => state => {
        if (currentStep.id !== id || state === canGoNext) return;
        setCanGoNext(state);
    };

    const setStepById = id => {
        setSteps([getStepById(SectionsSteps[id])]);
    };

    const handleNext = (e, nextStep, force = false, fromStep = -1) => {
        if (fromStep !== -1 && fromStep !== currentStep.id + 1) return;

        if (!force) {
            const validation = currentStep.component.navigationOptions.validate();
            if (!validation) return;
        }

        let nextStepIdentifier;
        if (nextStep) {
            nextStepIdentifier = STEPS[nextStep];
        } else {
            const identifier = STEPS.findIndex(
                step => step.id === currentStep.id
            );
            if (identifier === -1) return;
            nextStepIdentifier = STEPS[identifier + 1];
        }

        setSteps(old => [...old, nextStepIdentifier]);
    };

    return (
        <div className="all-sections">
            {
            steps.map(step => (
                <step.component
                    resetScreenTo={resetScreenTo(step.id)}
                    setCanGoNext={secureSetCanGoNext(step.id)}
                    key={step.id}
                    isCurrentScreen={step.id === currentStep.id}
                    nextScreen={handleNext}
                    handleSave={handleSave}
                    setStepById={setStepById}
                    canGoNext={canGoNext}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                />
            ))}
            <div className="buttons-navigations">
                <CustomButton
                    title="SUIVANT"
                    color="orange"
                    disabled={!canGoNext}
                    onClick={handleNext}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    currentCodeMode: state.codeReducer.currentMode,
});

const mapDispatchToProps = dispatch => ({
    changeCodeModeDispatch: payload => dispatch(changeCodeMode(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionCollapser);
