import React from 'react';
// import CustomToggle from '../CustomToggle';

import { transformLabel } from '../../utils/helpers';
import WordingConstant from '../../utils/wording.json';

const Wording = WordingConstant.SummaryScreen;
const PriceWording = WordingConstant.Price;
const SummaryWording = WordingConstant.SummaryScreen;
const CommonWording = WordingConstant.Common;

const PlanElement = ({
    prices,
    offer,
    // products,
    // currentOptions,
    currentAdditionalOptions,
    // handleToggle,
    // loadingOption,
    // idToggle,
    consumption,
    options,
}) => {
    const details = offer.details || [];
    const totalAmount = details.find(
        d => d.category === SummaryWording.detail.category.totalAmount
    );
    const subscription =
        totalAmount &&
        totalAmount.unitPrices.find(
            p => p.label === PriceWording.subscription.label
        );
    const additionalRates = details.filter(
        d => d.category === SummaryWording.detail.category.additionalRate
    );
    const totalBudget = details.find(
        d => d.category === SummaryWording.detail.category.totalBudget
    );
    const discountRates = details.find(
        d => d.category === SummaryWording.detail.category.discountRate
    );

    const renderPrice = ({ label, price, unit }, index, fixPart) => {
        const displayedUnit = CommonWording.unit[unit];
        const display = transformLabel(label);
        const formattedPrice = fixPart ? parseFloat(price).toFixed(2) : price;
        if (display === '') return null;
        return (
            <p
                style={{ fontWeight: 300, margin: 0, fontSize: '12px' }}
                key={index}
            >
                {display} :{' '}
                <span style={{ fontWeight: 500 }}>
                    {formattedPrice} {displayedUnit}
                </span>
            </p>
        );
    };

    return (
        <div
            className={`plan-element ${
                prices.length === 0 ? `plan-element--cyclicBill` : ''
            }`}
        >
            {prices.length !== 0 && (
                <div
                    className={`plan-element__prices ${
                        prices.length > 1 ? 'plan-element__prices--larger' : ''
                    }`}
                >
                    {prices &&
                        prices.map((p, index) => (
                            <>
                                <div
                                    style={
                                        prices.length > 1
                                            ? {
                                                  marginBottom: '16px',
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  justifyContent: 'center',
                                              }
                                            : { marginBottom: '16px' }
                                    }
                                    key={index}
                                    className="plan-element__prices__label"
                                >
                                    {prices.length > 1 && (
                                        <span
                                            style={{
                                                fontWeight: 300,
                                                fontSize: '13px',
                                            }}
                                            className="plan-element__prices__label__timeframe"
                                        >
                                            {
                                                WordingConstant.Common
                                                    .timeframe[index]
                                            }
                                        </span>
                                    )}
                                    <label
                                        htmlFor="idPrice"
                                        style={
                                            prices.length > 1
                                                ? {
                                                      display: 'block',
                                                      fontSize: '36px',
                                                      lineHeight: '32px',
                                                      fontWeight: 400,
                                                  }
                                                : {
                                                      fontSize: '40px',
                                                      fontWeight: 400,
                                                  }
                                        }
                                        className="plan-element__prices__label__priceDescription"
                                    >
                                        {consumption[index]}
                                        <p
                                            style={
                                                prices.length > 1
                                                    ? { fontSize: '16px' }
                                                    : {
                                                          fontSize: '29px',
                                                          margin: '7px 0 0 6px',
                                                      }
                                            }
                                        >
                                            kWh
                                        </p>
                                    </label>
                                    <span
                                        style={{ fontWeight: 300 }}
                                        className="plan-element__prices__label__month"
                                    >
                                        Prépaiement de <br />
                                    </span>
                                    <span>{p}€/mois</span>
                                </div>
                                {index === 0 && prices.length > 1 && (
                                    <div
                                        style={{
                                            border: '1px solid #000000',
                                            height: '50%',
                                            maxWidth: '0px',
                                            margin: '0px 20px 40px 20px',
                                        }}
                                        className="plan-element__prices__label"
                                    />
                                )}
                            </>
                        ))}
                </div>
            )}
            <div
                className={`plan-element__description ${
                    prices.length === 0
                        ? `plan-element__description--cyclicBill`
                        : ''
                }`}
            >
                <React.Fragment>
                    <div className="plan-element__description__separator" />
                    <div className="plan-element__description__subscription">
                        <p
                            style={{ marginBottom: '10px' }}
                            className="plan-element__description__title"
                        >
                            {Wording.detail.title.fix}
                        </p>
                        {subscription && renderPrice(subscription, 0, true)}
                        <div
                            style={{ marginTop: '10px' }}
                            className="plan-element__description__separator"
                        />
                    </div>
                    <div>
                        <p
                            style={{ marginBottom: '10px' }}
                            className="plan-element__description__title"
                        >
                            {Wording.detail.title.consumption}
                        </p>
                        <p className="plan-element__description__subtitle">
                            {Wording.detail.title.grossPrice}
                        </p>
                        {totalAmount &&
                            totalAmount.unitPrices &&
                            totalAmount.unitPrices
                                .filter(
                                    p =>
                                        p.label !==
                                        PriceWording.subscription.label
                                )
                                .map((e, index) => renderPrice(e, index))}
                        <br className="plan-element__description__margin" />
                        {additionalRates.map(
                            (rate, idx1) =>
                                rate &&
                                rate.unitPrices &&
                                rate.unitPrices.map((e, idx2) =>
                                    renderPrice(e, idx1 + idx2)
                                )
                        )}
                        {totalBudget &&
                            totalBudget.unitPrices &&
                            totalBudget.unitPrices.map((e, index) =>
                                renderPrice(e, index)
                            )}
                        <br className="plan-element__description__margin" />
                        <p className="plan-element__description__subtitle">
                            {Wording.detail.title.discountPrice}
                        </p>
                        {discountRates &&
                            discountRates.unitPrices &&
                            discountRates.unitPrices.map((e, index) =>
                                renderPrice(e, index)
                            )}
                        {options &&
                            currentAdditionalOptions &&
                            currentAdditionalOptions.length > 0 && (
                                <React.Fragment>
                                    <div
                                        style={{ margin: '10px 0px' }}
                                        className="plan-element__description__separator"
                                    />
                                    <p className="plan-element__description__title">
                                        {Wording.detail.title.option}
                                    </p>

                                    {options.map((e, index) => {
                                        if (
                                            currentAdditionalOptions &&
                                            currentAdditionalOptions.includes(
                                                e.label
                                            ) &&
                                            e.detail
                                        ) {
                                            return renderPrice(e.detail, index);
                                        }
                                        return null;
                                    })}
                                </React.Fragment>
                            )}
                    </div>
                </React.Fragment>
            </div>

            {/* {products.length < 2 && (
                <div
                    className={`plan-element__toggle ${
                        prices.length === 0
                            ? `plan-element__toggle--cyclicBill`
                            : ''
                    }`}
                >
                    <div className="plan-element__toggle__productOptions">
                        {products && products.length !== 0 && (
                            <p className="plan-element__toggle__productOptions__title">
                                {Wording.detail.title.fixPrice}
                            </p>
                        )}

                        {products &&
                            products.map((product, index) => (
                                <CustomToggle
                                    key={index}
                                    id={`idFixPrice__${idToggle ||
                                        ''}__${index}`}
                                    text={SummaryWording.fixPrice}
                                    value={product.productCode}
                                    checked={
                                        currentOptions &&
                                        currentOptions.includes(
                                            product.productCode
                                        )
                                    }
                                    handleCheck={handleToggle}
                                />
                            ))}
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default PlanElement;
