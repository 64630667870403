import React from 'react';
import Typography from '../Typography/Typography';
import './_CustomToggleV2.scss';

const CustomToggleV2 = ({
    text,
    value,
    checked,
    id,
    handleCheck,
    isLinked,
    labelSize = '0.85',
}) => (
    <>
        <div className={`custom-toggle-V2 ${isLinked && 'linked'}`}>
            <input
                type="checkbox"
                id={id}
                name=""
                value={value}
                checked={checked}
                onChange={handleCheck}
            />
            <div className="custom-toggle-V2__toggle">
                <label htmlFor={id}>
                    <i />
                </label>
            </div>
            <Typography fontSize={labelSize}>{text}</Typography>
            <div className="custom-toggle-V2__link" />
        </div>
    </>
);

export default CustomToggleV2;
