import { combineEpics, ofType } from 'redux-observable';
import { mergeMap } from 'rxjs/operators';
import { mapGetPackages } from '../PackageScreen/PackageScreen.actions';

import {
    CODE_SCREEN_VALIDATE,
    CODE_SCREEN_VALIDATE_SUCCESS,
    CODE_SCREEN_VALIDATE_FAILURE,
    CODE_SCREEN_RESET,
    FINAL_CODE_SCREEN_VALIDATE,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_FAILURE,
    FINAL_CODE_SCREEN_VALIDATE_V2,
    CODE_SCREEN_MODE_UPDATE,
    RESET_FULL_CODE_SCREEN,
} from '../types';

const resetFullCodeScreen = () => ({
    type: RESET_FULL_CODE_SCREEN,
});

const changeCodeMode = payload => ({
    type: CODE_SCREEN_MODE_UPDATE,
    payload,
});

const validateCodeScreen = payload => ({
    type: CODE_SCREEN_VALIDATE,
    payload,
});

const validateCodeScreenSuccess = payload => ({
    type: CODE_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validateCodeScreenFailure = payload => ({
    type: CODE_SCREEN_VALIDATE_FAILURE,
    payload,
});

const validateFinalCodeScreen = payload => ({
    type: FINAL_CODE_SCREEN_VALIDATE,
    payload,
});

const validateFinalCodeScreenSuccess = payload => ({
    type: FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validateFinalCodeScreen_V2 = payload => ({
    type: FINAL_CODE_SCREEN_VALIDATE_V2,
    payload,
});

const validateFinalCodeScreenFailure = payload => ({
    type: FINAL_CODE_SCREEN_VALIDATE_FAILURE,
    payload,
});

const resetCodeScreen = payload => ({
    type: CODE_SCREEN_RESET,
    payload,
});

const preparePayload = ({ userType, energyTypes, PDL, PCE, firstName, lastName, email }) => {
    const pointOfDeliveryList = energyTypes.map(energy => ({
        energy,
        reference: energy === 'EL' ? PDL : PCE,
    }));
    return {
        mode: 'POINT_OF_DELIVERY',
        customerType: userType,
        pointOfDeliveryList,
        firstName,
        lastName,
        email,
    };
};

const validateCodeScreenEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(CODE_SCREEN_VALIDATE),
        mergeMap(action =>
            mapGetPackages(
                action,
                dependency,
                preparePayload,
                validateCodeScreenSuccess,
                validateCodeScreenFailure
            )
        )
    );

const validateFinalCodeScreenEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(FINAL_CODE_SCREEN_VALIDATE),
        mergeMap(action => {
            const modifiedAction = {
                ...action,
                payload: {
                    ...action.payload,
                    isFinal: true,
                },
            };

            return mapGetPackages(
                modifiedAction,
                dependency,
                preparePayload,
                validateFinalCodeScreenSuccess,
                validateFinalCodeScreenFailure
            );
        })
    );

const codeScreenEpic = combineEpics(
    validateCodeScreenEpic,
    validateFinalCodeScreenEpic
);

export {
    resetFullCodeScreen,
    resetCodeScreen,
    validateCodeScreen,
    validateCodeScreenSuccess,
    validateCodeScreenFailure,
    codeScreenEpic,
    validateFinalCodeScreen,
    validateFinalCodeScreenSuccess,
    validateFinalCodeScreenFailure,
    validateFinalCodeScreen_V2,
    changeCodeMode,
};
