import {
    CODE_SCREEN_VALIDATE_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    SURVEY_SCREEN_LOAD,
    MAIN_SCREEN_GET_ORDER,
    CODE_SCREEN_VALIDATE,
} from '../types';

import * as helpers from '../../utils/helpers';

export const initialState = {
    packages: [],
    prepaymentFrequencies: [],
    autorizedBillingModes: [],
    missingEnergy: [],
};

const recoverState = (
    state,
    { packages, prepaymentFrequencies, autorizedBillingModes, missingEnergy }
) => ({
    ...state,
    prepaymentFrequencies,
    autorizedBillingModes,
    packages,
    missingEnergy,
});

function packageReducer(state = initialState, action) {
    switch (action.type) {
        case CODE_SCREEN_VALIDATE:
        case MAIN_SCREEN_GET_ORDER: {
            return {
                ...initialState,
                ...state,
            };
        }
        case CODE_SCREEN_VALIDATE_SUCCESS: {
            const {
                prepaymentFrequencies,
                autorizedBillingModes,
                packages,
            } = action.payload;
            return {
                ...state,
                packages,
                prepaymentFrequencies,
                autorizedBillingModes,
            };
        }

        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            const {
                prepaymentFrequencies,
                autorizedBillingModes,
                packages,
            } = action.payload;

            if (state.missingEnergy && state.missingEnergy[0] !== undefined) {
                const filterPackages = state.packages.filter(
                    pkg => pkg.energy !== state.missingEnergy[0]
                );
                const modifiedPackages = [...filterPackages, ...packages];

                return {
                    ...state,
                    packages: modifiedPackages,
                    prepaymentFrequencies,
                    autorizedBillingModes,
                };
            }

            return {
                ...state,
                packages,
                prepaymentFrequencies,
                autorizedBillingModes,
            };
        }

        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS: {
            const {
                prepaymentFrequencies,
                autorizedBillingModes,
                packages,
            } = action.payload;
            const modifiedPackages = helpers.mergeArray(
                packages,
                state.packages
            );
            return {
                ...state,
                packages: modifiedPackages,
                prepaymentFrequencies,
                autorizedBillingModes,
            };
        }
        case SURVEY_SCREEN_LOAD: {
            return {
                ...state,
                missingEnergy: action.payload.missingEnergy,
                packages: [...state.packages, ...action.payload.packages],
            };
        }

        case MAIN_SCREEN_GET_ORDER_SUCCESS:
            return recoverState(state, action.payload);
        default:
            return state;
    }
}

export default packageReducer;
