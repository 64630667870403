import React from 'react';
import PropTypes from 'prop-types';

import { CustomInput } from '../../components';
import CustomSelectV2 from '../../components/CustomSelect/CustomSelectV2';
import CustomToggleV2 from '../../components/CustomToggle/CustomToggleV2';
import { camelToUpperCase } from '../../utils/helpers';

const SwitchContainer = ({
    handleShowInput,
    handleChangeInput,
    showInput,
    toggleTitle,
    showBorder,
    thirdParty: { code, message, error, loading },
    select,
}) => (
    <div className="switch-container">
        <div
            className={`switch-container__toggle ${
                showBorder ? 'switch-container__toggle--border' : ''
            }`}
            onClick={handleShowInput}
            role="button"
            tabIndex="0"
            onKeyPress={() => {}}
        >
            <CustomToggleV2
                checked={showInput}
                text={toggleTitle}
                labelSize="1.1"
                handleCheck={() => {}}
            />
        </div>
        {showInput && (
            <div className="switch-container__inputs">
                <CustomSelectV2
                    id="advantageCode"
                    value={select.currentValue}
                    options={select.options}
                    defaultValue={select.defaultValue}
                    handleChange={select.handleChange}
                    placeholder="Sélectionner"
                    customSize="210px"
                />

                <CustomInput
                    text=""
                    value={
                        select.currentValue.value === 'SPONSOR'
                            ? camelToUpperCase(code)
                            : code
                    }
                    pattern={
                        select.currentValue.value === 'SPONSOR'
                            ? '[a-zA-Z0-9]*'
                            : undefined
                    }
                    loading={loading}
                    placeholder={select.currentValue.placeholder}
                    onInputChange={e => handleChangeInput(e, code)}
                    isLocked={!select.currentValue.value}
                />
            </div>
        )}
        {message && (
            <p
                className={`switch-container__message switch-container__message--${
                    error ? 'red' : 'green'
                }`}
            >
                {message}
            </p>
        )}
        {select.currentValue.description && (
            <p className="switch-container__description">
                {select.currentValue.description}
            </p>
        )}
    </div>
);

SwitchContainer.propTypes = {
    handleShowInput: PropTypes.func.isRequired,
    handleChangeInput: PropTypes.func.isRequired,
    toggleTitle: PropTypes.string.isRequired,
    showBorder: PropTypes.bool,
    select: PropTypes.shape({
        currentValue: PropTypes.object,
        defaultValue: PropTypes.object,
        options: PropTypes.array,
        handleChange: PropTypes.func,
    }).isRequired,
};

SwitchContainer.defaultProps = {
    showBorder: false,
};

export default SwitchContainer;
