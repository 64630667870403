import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

/**
 * The static/non-static styles Information for a single event dot on the timeline
 */
const dots = {
    /**
     * The style information for the clickable dates that apper floating over the timeline
     */
    links: {
        position: 'absolute',
        top: 10,
        textAlign: 'center',
    },
    /**
     * The base style information for the event dot that appers exactly on the timeline
     */
    base: {
        position: 'absolute',
        top: -14,
        height: 9,
        width: 9,
        borderRadius: '50%',
        transition: 'background-color 0.3s, border-color 0.3s',
        ':hover': {}, // We need this to track the hover state of this element
    },
    /**
     * future: The style information for the future dot (wrt selected).
     * @param {object} styles User passed styles ( foreground, background etc info
     */
    future: styles => ({
        // backgroundColor: styles.background,
        backgroundColor: styles.foreground,
        // border: `2px solid ${styles.background}`,
        border: `2px solid ${styles.outline}`,
    }),
    /**
     * past: The styles information for the past dot (wrt selected)
     * @param {object} styles User passed styles ( foreground, background etc info
     */
    past: styles => ({
        // backgroundColor: styles.background,
        backgroundColor: styles.foreground,
        border: `2px solid ${styles.foreground}`,
    }),
    /**
     * present: The styles information for the preset dot
     * @param {object} styles User passed styles ( foreground, background etc info
     */
    present: styles => ({
        // backgroundColor: styles.foreground,
        backgroundColor: styles.background,
        border: `2px solid ${styles.foreground}`,
    }),
};

/**
 * The markup for one single dot on the timeline
 *
 * @param {object} props The props passed down
 * @return {StatelessFunctionalReactComponent} The markup for a dot
 */
const TimelineDot = ({
    index,
    selected,
    date,
    distanceFromOrigin,
    labelWidth,
    label,
    onClick,
    styles,
}) => {
    const getDotStyles = (dotType, key) => {
        const hoverStyle = {
            backgroundColor: styles.foreground,
            border: `2px solid ${styles.foreground}`,
        };

        return [
            dots.base,
            { left: labelWidth / 2 - dots.base.width / 2 },
            dots[dotType](styles),
            Radium.getState({}, key, ':hover') ||
            Radium.getState({}, 'dot-dot', ':hover')
                ? hoverStyle
                : undefined,
        ];
    };

    let dotType = 'future';
    if (index < selected) {
        dotType = 'past';
    } else if (index === selected) {
        dotType = 'present';
    }

    return (
        <div
            key={date}
            id={`timeline-dot-${date}`}
            className={`${dotType} dot-label`}
            onClick={() => onClick(index)}
            role="button"
            tabIndex="0"
            onKeyPress={() => {}}
            style={[
                dots.links,
                {
                    left: distanceFromOrigin - labelWidth / 2,
                    width: labelWidth,
                    fontWeight: 'bold',
                    fontSize: 10,
                    outline: 'none',
                    ':hover': {},
                },
            ]}
        >
            <span key="dot-dot" style={getDotStyles(dotType, date)} />
            {label}
        </div>
    );
};

/**
 * propTypes
 * @type {Object}
 */
TimelineDot.propTypes = {
    // The index of the currently selected dot (required to style as old, present, or future event)
    selected: PropTypes.number.isRequired,
    // The index of the present event (used for deciding the styles alongside selected)
    index: PropTypes.number.isRequired,
    // The actual date of the event (used as key and id)
    date: PropTypes.string.isRequired,
    // The onClick handler ( in this case to trigger the fillingMotion of the timeline )
    onClick: PropTypes.func.isRequired,
    // The date of the event (required to display it)
    label: PropTypes.string.isRequired,
    // The width you want the labels to be
    labelWidth: PropTypes.number.isRequired,
    // The numerical value in pixels of the distance from the origin
    distanceFromOrigin: PropTypes.number.isRequired,
    // The styles prefrences of the user
    styles: PropTypes.shape({}).isRequired,
};

export default Radium(TimelineDot);
