import React from 'react';
import Select from 'react-select';
import './_CustomSelectV2.scss';

const customStyles = {
    dropdownIndicator: provided => ({
        ...provided,
        display: 'none', // Pour masquer la flèche de droite
    }),
};
class CustomSelectV2 extends React.Component {
    state = {
        isMenuOpen: false,
        firstRenderValue: false,
    };

    componentDidMount() {
        const { value } = this.props;
        if (value && value.value !== '' && value.value !== undefined) {
            this.setState({ firstRenderValue: value.value });
        }
    }

    render() {
        const {
            value,
            defaultValue,
            handleChange,
            options,
            placeholder,
            error,
            customClassName,
            isLocked,
            isLoading,
            inputValue,
            onFocus,
            onInputChange,
            halfSize,
            customSize,
            onBlur,
            style,
            hideArrow,
        } = this.props;
        const { isMenuOpen, firstRenderValue } = this.state;
        const current = options.find(option => option.value === defaultValue);
        const currentValue =
            firstRenderValue && value && firstRenderValue === value.value
                ? value
                : options.find(option => option.value === value) || current;
        let selectClasses = 'custom-select__selectV2';
        selectClasses += isMenuOpen ? ' custom-select__select--menuOpen' : '';
        selectClasses += halfSize ? ' custom-select__select--halfSize' : '';
        selectClasses += customSize ? ' custom-select__select--customSize' : '';

        return (
            <div
                className={`custom-selectV2 ${error &&
                    'error'} ${customClassName}`}
                style={{ width: customSize, ...style }}
            >
                <Select
                    className={selectClasses}
                    classNamePrefix="react-select"
                    value={currentValue}
                    placeholder={placeholder || (current || {}).label}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    options={options}
                    isDisabled={isLocked}
                    isLoading={isLoading}
                    inputValue={inputValue}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onInputChange={onInputChange}
                    styles={hideArrow && customStyles}
                    onMenuOpen={() => this.setState({ isMenuOpen: true })}
                    onMenuClose={() => this.setState({ isMenuOpen: false })}
                />

                {error && error !== '' && (
                    <p className="custom-select__div__error">{error}</p>
                )}
            </div>
        );
    }
}

export default CustomSelectV2;
