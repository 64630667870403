import React, { Component } from 'react';
import { CustomButton } from '..';
import CustomModal from '../CustomModal';
import WordingConstant from '../../utils/wording.json';
import { handleResetStore } from '../../utils/helpers';

const Wording = WordingConstant.SubscriptionReset;

class SubscriptionReset extends Component {
    state = {
        isOpen: false,
    };

    handelShowModal = bool => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.setState({ isOpen: bool });
    };

    handleReset = () => {
        window.history.pushState({}, '', '/');
        handleResetStore();
    };

    renderButtons = () => (
        <div>
            <CustomButton
                color="gray"
                title={Wording.popup.btn_validate}
                onClick={() => this.handleReset()}
            />
            <CustomButton
                color="purple"
                onClick={() => this.handelShowModal(false)}
                title={Wording.popup.btn_cancel}
            />
        </div>
    );

    renderModal = () => {
        const { isOpen } = this.state;
        return (
            <CustomModal show={isOpen}>
                <p>
                    <span>{Wording.popup.attention}</span>
                </p>

                <div className="subscription-reset__para">
                    <p>{Wording.popup.descriptions}</p>
                </div>
                <p>{Wording.popup.question}</p>

                {this.renderButtons()}
                <div className="subscription-reset__line" />
            </CustomModal>
        );
    };

    render() {
        return (
            <div className="subscription-reset">
                {this.renderModal()}
                <CustomButton
                    color="gray"
                    title={Wording.main_btn}
                    onClick={() => this.handelShowModal(true)}
                />
            </div>
        );
    }
}

export default SubscriptionReset;
