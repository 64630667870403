import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { StripeForm } from '../../components';
import config from '../../utils/config';

const stripeProm = loadStripe(config.Stripe.publicKey);

const StripeContainer = ({ stripeInformations, onFormValidate }) => {
    if (!stripeInformations || !stripeInformations.clientSecret) return <></>;
    const { clientSecret, billName, amount } = stripeInformations;
    return (
        <Elements stripe={stripeProm} options={{ clientSecret }}>
            <StripeForm
                onFormValidate={onFormValidate}
                clientSecret={clientSecret}
                clientName={billName}
                amount={amount}
            />
        </Elements>
    );
};

export default StripeContainer;
