import React from 'react';
import { connect } from 'react-redux';

import StripeContainer from '../StripeFormContainer/StripeFormContainer';
import WordingConstant from '../../utils/wording.json';
import { CustomModal } from '../../components';

import * as CBActions from './PaymentCBModal.actions';
import * as actions from './PaymentModal.actions';

const wording = WordingConstant.PaymentModalCB;

class PaymentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, // Initialiser isLoading à false
        };
    }

    firstLoadingCB = true;

    firstLoading = true;

    componentDidUpdate = prevProps => {
        const { paymentState } = this.props;
        if (paymentState.iframe !== prevProps.paymentState.iframe) {
            this.firstLoading = true;
            this.firstLoadingCB = true;
        }
    };

    handleIframe = () => {
        const {
            paymentState: { iframe, paymentType },
        } = this.props;

        const isIframe = iframe !== null;

        if (paymentType === 'DIRECT_DEBIT' && isIframe) {
            if (!this.firstLoading) this.handleValidateModal();
            this.firstLoading = false;
        }
    };

    handleValidateModal = () => {
        const {
            paymentState: { reference, currentOrder, externalReference, status },
            validatePaymentModalDispatch,
        } = this.props;
     
        const prepareOrder = {
            ...currentOrder,
            externalReference,
            orderReference: reference,
        };
     
        this.setState({ isLoading: true });
     
        const handleTimeout = () => {
            if (status === 'IN_PROGRESS') {
                this.handleValidateModal();
            } else {
                validatePaymentModalDispatch({ reference, prepareOrder });
                this.setState({ isLoading: false });
            }
        };
     
        if (status === 'IN_PROGRESS') {
            setTimeout(handleTimeout, 15000);
        } else {
            handleTimeout();
        }
    };
    

    handleStripePaymentValidate = (isSuccess, errMsg) => {
        const {
            validatePaymentScreenSuccess,
            firstPaymentCBFailure,
            failureCBMessageDispatch,
            setStepById,
        } = this.props;
        if (isSuccess) {
            validatePaymentScreenSuccess({ orderStatus: true });
            setStepById('FINAL_SCREEN');
        } else {
            failureCBMessageDispatch({ message: errMsg });
            firstPaymentCBFailure();
            setStepById('FAILURE_SCREEN');
        }
    };

    render() {
        const {
            paymentState: { iframe, paymentType, stripe },
        } = this.props;
        const isPaymentCB = paymentType === 'PAYMENT_CB';
        const { isLoading } = this.state;

        const handleQuitModal = isPaymentCB
            ? () =>
                  this.handleStripePaymentValidate(
                      false,
                      wording.failureMessage
                  )
            : this.handleValidateModal;

        return (
            <div className="payment-modal">
                <CustomModal
                    show={iframe !== null || stripe !== null}
                    showCloseButton
                    handleClick={handleQuitModal}
                    contentClassName={
                        isPaymentCB && 'custom-modal__content-cb-payment'
                    }
                >
                    {isLoading && <div className="loader">{isLoading}</div>}

                    {!isPaymentCB && iframe && (
                        <>
                            <div
                                style={{
                                    height: '12.5px',
                                    backgroundColor: '#eeeeee',
                                }}
                            />
                            <iframe
                                title="payment-modal"
                                src={iframe}
                                id="payment-modal"
                                style={{
                                    width: '100%',
                                    height: 'calc(100% - 12.5px)',
                                    border: 'none',
                                }}
                                onLoad={this.handleIframe}
                            />
                        </>
                    )}

                    {isPaymentCB && (
                        <StripeContainer
                            stripeInformations={stripe}
                            onFormValidate={this.handleStripePaymentValidate}
                        />
                    )}
                </CustomModal>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    paymentState: state.paymentReducer,
    mainState: state.mainReducer,
});

const mapDispatchToProps = dispatch => ({
    validatePaymentModalDispatch: payload =>
        dispatch(actions.validatePaymentScreen(payload)),
    validatePaymentScreenSuccess: payload =>
        dispatch(actions.validatePaymentScreenSuccess(payload)),
    firstPaymentCBFailure: payload =>
        dispatch(CBActions.firstPaymentCBFailure(payload)),
    failureCBMessageDispatch: payload =>
        dispatch(CBActions.failureCBMessage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
