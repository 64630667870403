const getOptions = (offer, additionalOptions) => {
    if (offer && offer.product && additionalOptions) {
        const chosenProduct = { ...offer.product };
        if (offer.product.additionalRates) {
            chosenProduct.additionalRates = offer.product.additionalRates.map(rate => {
                if (rate.type !== 'OPTION') {
                    return rate;
                }
                const active = additionalOptions.indexOf(rate.label) !== -1;
                return {
                    ...rate,
                    active,
                };
            });
        } else {
            chosenProduct.additionalRates = [];
        }
        return chosenProduct;
    } else {
        console.error("L'objet 'offer', 'offer.product' ou 'additionalOptions' est undefined.");
        return {};
    }
};


const prepareContract = object => {
    if (!object || !object.summaryReducer || !object.summaryReducer.EL) {
        console.error("La propriété 'EL' n'existe pas dans 'summaryReducer'");
        return {};
    }
    const contractData = object.summaryReducer.contracts;
    const summaryData = object.summaryReducer;
    const customerData = object.dataReducer.common;
    const financeData = object.dataReducer.finance;

    // eslint-disable-next-line prefer-const
    let [first, ...rest] = customerData.address.address.split(' ');
    rest = rest.join(' ');
    const address = {
        number: first,
        street: rest,
        postalCode: customerData.address.postalCode,
        townName: customerData.address.city,
    };
    return contractData.map((contract, index) => {
        const energy = contract.energy || 'Indisponible';
        const json = {
            contract: {
                contractNumber: contract.contractNumber || 'Indisponible',
                effectiveStartRange:
                    contract.effectiveStartRange || 'Indisponible',
                effectiveStartDate:
                    contract.effectiveStartDate ||
                    new Date().toISOString().split('T')[0],
                subscriptionDate:
                    contract.subscriptionDate ||
                    new Date().toISOString().split('T')[0],
                dueDate:
                    contract.dueDate || new Date().toISOString().split('T')[0],
                energy: contract.energy || 'Indisponible',
                deliveryPoint: {
                    pointOfDelivery:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.pointOfDelivery) ||
                        'Indisponible',
                    state:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.state) ||
                        'Indisponible',
                    gridRate:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.gridRate) ||
                        'Indisponible',
                    deliveryStatus:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.deliveryStatus) ||
                        'Indisponible',
                    address: {
                        street:
                            (contract.deliveryPoint &&
                                contract.deliveryPoint.address &&
                                contract.deliveryPoint.address.street) ||
                            'Indisponible',
                        postalCode:
                            (contract.deliveryPoint &&
                                contract.deliveryPoint.address &&
                                contract.deliveryPoint.address.postalCode) ||
                            'Indisponible',
                        townName:
                            (contract.deliveryPoint &&
                                contract.deliveryPoint.address &&
                                contract.deliveryPoint.address.townName) ||
                            'Indisponible',
                    },
                    profile:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.profile) ||
                        'Indisponible',
                    meterNumber:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.meterNumber) ||
                        'Indisponible',
                    meterType:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.meterType) ||
                        'Indisponible',
                    smartMeterStatus:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.smartMeterStatus) ||
                        'Indisponible',
                    deliveryPointCategory:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.deliveryPointCategory) ||
                        'Indisponible',
                    readingFrequency:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.readingFrequency) ||
                        'Indisponible',
                    contractualCapacity:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.contractualCapacity) ||
                        0,
                    contractualCapacityUnit:
                        (contract.deliveryPoint &&
                            contract.deliveryPoint.contractualCapacityUnit) ||
                        'Indisponible',
                    process: {
                        type:
                            (contract.deliveryPoint &&
                                contract.deliveryPoint.process &&
                                contract.deliveryPoint.process.type) ||
                            customerData.situation ||
                            'Indisponible',
                        express:
                            (contract.deliveryPoint &&
                                contract.deliveryPoint.process &&
                                contract.deliveryPoint.process.express) ||
                            customerData.dateContract[energy].express ||
                            false,
                    },
                },
                billingModeCode:
                    contract.billingModeCode ||
                    summaryData[energy].billingModeCode ||
                    'Indisponible',
                installmentFrequency:
                    contract.installmentFrequency ||
                    summaryData[energy].installmentFrequency ||
                    'Indisponible',
                thirdParties: contract.thirdParties || [],
                estimates: contract.estimates || [],
                chosenProduct: getOptions(
                    summaryData.offers[index],
                    summaryData[energy].additionalOptions
                ) ||
                    summaryData.offers[index].product || {
                        default:
                            (contract.chosenProduct &&
                                contract.chosenProduct.default) ||
                            false,
                        productCode:
                            (contract.chosenProduct &&
                                contract.chosenProduct.productCode) ||
                            'Indisponible',
                        label:
                            (contract.chosenProduct &&
                                contract.chosenProduct.label) ||
                            'Indisponible',
                        description:
                            (contract.chosenProduct &&
                                contract.chosenProduct.description) ||
                            'Indisponible',
                        productType:
                            (contract.chosenProduct &&
                                contract.chosenProduct.productType) ||
                            'Indisponible',
                        energy:
                            (contract.chosenProduct &&
                                contract.chosenProduct.energy) ||
                            'Indisponible',
                        rateOption:
                            (contract.chosenProduct &&
                                contract.chosenProduct.rateOption) ||
                            'Indisponible',
                        additionalRates:
                            (contract.chosenProduct &&
                                contract.chosenProduct.additionalRates) ||
                            [],
                    },
                chosenPackages:
                    summaryData[energy].chosenPackages ||
                    contractData.chosenPackages ||
                    [],
                rateOption: contract.rateOption || 'Indisponible',
            },

            customer: {
                customerNumber: object.paymentReducer.reference
                    ? object.paymentReducer.reference
                    : 'Indisponible',
                type: 'INDIVIDUAL',
                contact: {
                    civility: customerData.civility,
                    firstName: customerData.name,
                    lastName: customerData.surname,
                    birthdate: customerData.birthdate
                        ? new Date(customerData.birthdate)
                              .toISOString()
                              .split('T')[0]
                        : null,
                    address,
                    birthTown: customerData.birthTown,
                    email: customerData.email,
                    phone: customerData.tel,
                },
                finance: financeData || {
                    accountOwnerFirstName: customerData.name,
                    accountOwnerLastName: customerData.surname,
                    paymentMode: 'DIRECT_DEBIT',
                },
            },
        };
        if (contract.energy === 'NG') {
            json.contract.contractCategory = contract.contractCategory;
        }
        return json;
    });
};

export { prepareContract };
