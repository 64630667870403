import React from 'react';
import PropTypes from 'prop-types';

import EventsBar from './EventsBar';

const HorizontalTimeline = ({
    containerWidth,
    containerHeight,
    values,
    labelWidth,
    linePadding,
    index,
    indexClick,
    styles,
    fillingMotion,
}) => {
    if (!containerWidth) {
        // As long as we do not know the width of our container, do not render anything!
        return false;
    }
    const events = values.map((v, idx) => ({
        distance: 160 * (idx + 1),
        label: v,
        date: v,
    }));
    const totalWidth = events[events.length - 1].distance + linePadding;
    const barPaddingRight = totalWidth - events[events.length - 1].distance;
    const barPaddingLeft = events[0].distance;

    return (
        <EventsBar
            width={containerWidth}
            height={containerHeight}
            events={events}
            totalWidth={totalWidth}
            index={index}
            styles={styles}
            indexClick={indexClick}
            labelWidth={labelWidth}
            fillingMotion={fillingMotion}
            barPaddingRight={barPaddingRight}
            barPaddingLeft={barPaddingLeft}
        />
    );
};

/**
 * The expected properties from the parent
 * @type {Object}
 */
HorizontalTimeline.propTypes = {
    // Selected index
    index: PropTypes.number.isRequired,
    // Array containing the sorted date strings
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    // Function that takes the index of the array as argument
    indexClick: PropTypes.func.isRequired,
    // Padding at the front and back of the line
    linePadding: PropTypes.number,
    // The width of the label
    labelWidth: PropTypes.number,
    styles: PropTypes.shape({}),
    fillingMotion: PropTypes.shape({}),
    slidingMotion: PropTypes.shape({}),
};

/**
 * The values that the properties will take if they are not provided
 * by the user.
 * @type {Object}
 */
HorizontalTimeline.defaultProps = {
    linePadding: 100,
    labelWidth: 80,
    styles: {
        outline: '#dfdfdf',
        background: '#f8f8f8',
        foreground: '#7b9d6f',
    },
    fillingMotion: {
        stiffness: 150,
        damping: 25,
    },
    slidingMotion: {
        stiffness: 150,
        damping: 25,
    },
};

export default HorizontalTimeline;
