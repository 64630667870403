import React, { useEffect, useState } from 'react';

import SummaryElement from '../../components/SummaryElement';
import PlanElement from '../../components/PlanElement';

// import WordingConstant from '../../utils/wording.json';
import CustomSelector from '../../components/CustomSelector/CustomSelector';
import Typography from '../../components/Typography/Typography';
import GasIcon from '../../assets/icons/ic-gas-on.svg';
import ElecIcon from '../../assets/icons/ic-elec-on.svg';
import LoadingOverlay from '../../components/LoadingOverlay';

// const SummaryWording = WordingConstant.SummaryScreen;

const SummaryContainer = ({
    prices,
    currentOptions,
    handleToggle,
    paymentFrequency,
    offer,
    products,
    energy,
    loadingOption,
    loading,
    showSeparator,
    isScheduleWithoutPack,
    consumption,
    // changeOfferDispatch,
    defaultProduct,
}) => {
    const [activateEffect, setActivateEffect] = useState(true);
    const [option, setOption] = useState([]);
    const [id, setId] = useState(1);

    useEffect(() => {
        if (offer.product && activateEffect) {
            const options = [
                {
                    label: 'Je veux un prix indexé',
                    id: 1,
                },
                { label: 'Je veux un prix fixe', id: 2 },
            ];
            if (products.length < 1) {
                if (offer.product.productType === 'VARIABLE') {
                    options[1].disabled = true;
                } else {
                    options[0].disabled = true;
                }
            }
            setOption(options);
            setId(offer.product.productType === 'VARIABLE' ? 1 : 2);
            setActivateEffect(false);
        }
    }, [offer]);

    function prepareOffer(e) {
        if (defaultProduct.length > 0) {
            const base = defaultProduct[0].productType;
            if (e === 1) {
                const product =
                    base === 'VARIABLE'
                        ? defaultProduct.find(
                              item => item.productType === 'VARIABLE'
                          )
                        : products.find(
                              item => item.productType === 'VARIABLE'
                          );
                const event = {
                    target: {
                        value: product.productCode,
                        checked: false,
                    },
                };
                handleToggle(event, energy);
            } else {
                const product =
                    base === 'VARIABLE'
                        ? products.find(item => item.productType === 'FIXED')
                        : defaultProduct.find(
                              item => item.productType === 'FIXED'
                          );
                const event = {
                    target: {
                        value: product.productCode,
                        checked: true,
                    },
                };
                handleToggle(event, energy);
            }
        }
    }

    return (
        <div className="summary-container">
            <div className="summary-container__title-container">
                <div className="summary-container__title-container__icon">
                    <img
                        alt="energy"
                        src={energy === 'EL' ? ElecIcon : GasIcon}
                    />
                </div>
                <Typography style={{ fontSize: '1.3rem' }}>
                    Plutôt <span className="bold">prix fixe</span> ou{' '}
                    <span className="bold">prix indexé ?</span>
                </Typography>
                {/* <Title
                    titleFormatGowun="Plutôt "
                    titleFormatLexand="prix fixe ou prix indexé ?"
                    className="title"
                /> */}
            </div>
            <Typography
                className="description"
                style={{ marginBottom: '26px' }}
            >
                Le prix indexé varie d’un mois à l’autre selon les prix du
                marché.
                <br /> Tandis que le prix fixe est garanti sans évolution
                pendant 12 mois.
            </Typography>
            <CustomSelector
                onSelect={e => prepareOffer(e)}
                options={option}
                defaultId={id}
                className="selector-offer"
            />
            <LoadingOverlay loading={loadingOption || loading} radius="15px">
                <div className="summary-container__element">
                    <PlanElement
                        prices={prices}
                        offer={offer}
                        products={products}
                        handleToggle={handleToggle}
                        idToggle={energy}
                        currentOptions={currentOptions}
                        loadingOption={loadingOption}
                        consumption={consumption}
                    />
                    <SummaryElement
                        offer={offer}
                        paymentFrequency={paymentFrequency}
                        loadingOption={loadingOption}
                        isScheduleWithoutPack={isScheduleWithoutPack}
                    />
                </div>
            </LoadingOverlay>
            {showSeparator && <></>}
        </div>
    );
};

export default SummaryContainer;
