import { combineEpics, ofType } from 'redux-observable';
import { of, forkJoin, throwError } from 'rxjs';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';

import {
    DATA_SCREEN_VALIDATE_SUCCESS,
    DATA_SCREEN_VALIDATE_SUCCESS_FINAL,
    FETCH_CALENDAR_APPOINTMENT,
    FETCH_CALENDAR_APPOINTMENT_SUCCESS,
    FETCH_CALENDAR_APPOINTMENT_FAILURE,
    DATA_SCREEN_SAVE_COMMON,
    MINIMAL_DATA_FORM,
    MINIMAL_DATA_VALIDATE_EMAIL,
    MINIMAL_DATA_RESET_EMAIL,
    SEND_EMAIL_CODE_REQUEST,
    SEND_EMAIL_CODE_REQUEST_FAIL,
    SEND_EMAIL_CODE_REQUEST_SUCCESS,
    VERIFY_EMAIL_CODE_REQUEST,
    VERIFY_EMAIL_CODE_REQUEST_FAIL,
    VERIFY_EMAIL_CODE_REQUEST_SUCCESS,
    LOG_USER_INFORMATION_REQUEST,
    LOG_USER_INFORMATION_FAIL,
    LOG_USER_INFORMATION_SUCCESS,
    GET_LOGGED_USER_INFO_REQUEST,
    GET_LOGGED_USER_INFO_REQUEST_FAIL,
    GET_LOGGED_USER_INFO_REQUEST_SUCCESS,
} from '../types';
import { log } from '../../utils/logger';
import Screen from '../screen';
import { saveOrderSuccess } from '../MainScreen/MainScreen.actions';
import config from '../../utils/config';

const dataScreenSaveCommon = payload => ({
    type: DATA_SCREEN_SAVE_COMMON,
    payload,
});

const validateDataScreenSuccess = payload => ({
    type: DATA_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validateDataScreenSuccessFinal = payload => ({
    type: DATA_SCREEN_VALIDATE_SUCCESS_FINAL,
    payload,
});

const fetchCalendarAppointment = payload => ({
    type: FETCH_CALENDAR_APPOINTMENT,
    payload,
});

const fetchCalendarAppointmentSuccess = payload => ({
    type: FETCH_CALENDAR_APPOINTMENT_SUCCESS,
    payload,
});

const fetchCalendarAppointmentFailure = payload => ({
    type: FETCH_CALENDAR_APPOINTMENT_FAILURE,
    payload,
});

const storeMinimumUserData = payload => ({
    type: MINIMAL_DATA_FORM,
    payload,
});

const resetMinimumUserDataEmail = payload => ({
    type: MINIMAL_DATA_RESET_EMAIL,
    payload,
});

const validateMinimumUserDataEmail = payload => ({
    type: MINIMAL_DATA_VALIDATE_EMAIL,
    payload,
});

const sendEmailCodeRequest = payload => ({
    type: SEND_EMAIL_CODE_REQUEST,
    payload,
});

const sendEmailCodeRequestFail = payload => ({
    type: SEND_EMAIL_CODE_REQUEST_FAIL,
    payload,
});

const sendEmailCodeRequestSuccess = payload => ({
    type: SEND_EMAIL_CODE_REQUEST_SUCCESS,
    payload,
});

const verifyEmailCodeRequest = payload => ({
    type: VERIFY_EMAIL_CODE_REQUEST,
    payload,
});

const verifyEmailCodeRequestFail = payload => ({
    type: VERIFY_EMAIL_CODE_REQUEST_FAIL,
    payload,
});

const verifyEmailCodeRequestSuccess = payload => ({
    type: VERIFY_EMAIL_CODE_REQUEST_SUCCESS,
    payload,
});

const logUserInformationRequest = payload => ({
    type: LOG_USER_INFORMATION_REQUEST,
    payload,
});

const logUserInformationRequestFail = payload => ({
    type: LOG_USER_INFORMATION_FAIL,
    payload,
});

const logUserInformationRequestSuccess = payload => ({
    type: LOG_USER_INFORMATION_SUCCESS,
    payload,
});

const getLoggedUserInfoRequest = payload => ({
    type: GET_LOGGED_USER_INFO_REQUEST,
    payload,
});

const getLoggedUserInfoRequestFail = payload => ({
    type: GET_LOGGED_USER_INFO_REQUEST_FAIL,
    payload,
});

const getLoggedUserInfoRequestSuccess = payload => ({
    type: GET_LOGGED_USER_INFO_REQUEST_SUCCESS,
    payload,
});

const preparePayload = (payload, energy) => {
    let fromDate;

    if (payload.fromDate !== undefined) {
        fromDate = payload.fromDate;
    } else {
        const dates = new Date().toISOString().split('T');
        if (dates && dates.length > 1) {
            fromDate = dates[0];
        }
    }

    return {
        fromDate,
        energyType: energy,
        customerType: payload.userType,
        processType: 'MOVE_IN',
        deliveryState: payload.contracts[0].deliveryPoint.state || '',
        meterType: payload.contracts[0].deliveryPoint.meterType || '',
        smartMeterStatus:
            payload.contracts[0].deliveryPoint.smartMeterStatus || '',
        deliveryStatus: payload.contracts[0].deliveryPoint.deliveryStatus || '',
    };
};

const mapGetCalendarAndAppointment = (action, energy, { apiRequest }) => {
    const payload = preparePayload(action.payload, energy);
    return apiRequest({
        path: '/getCalendarAppointment',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (Array.isArray(response)) {
                log({
                    tag: Screen.DATA,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getCalendarAppointment',
                    statusAction: 'SUCCESS',
                    triggerButton: 'auto-load',
                });
                return of(response);
            }
            const message = response.message || response.errorMessage;

            log({
                tag: Screen.DATA,
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/getCalendarAppointment',
                statusAction: 'FAILURE',
                triggerButton: 'auto-load',
            });
            return throwError({ message });
        }),
        catchError(error => of(error))
    );
};

const validateCalendarAppointementEpic = (action$, store, dependency) =>
    action$.pipe(
        ofType(FETCH_CALENDAR_APPOINTMENT),
        switchMap(action =>
            forkJoin(
                ...action.payload.energyTypes.map(energy =>
                    mapGetCalendarAndAppointment(action, energy, dependency)
                )
            )
        ),
        switchMap(calendarResponse => {
            // Float does not work with the current jest version
            // const arrFlat = calendarResponse.flat();
            const arrFlat = [].concat(...calendarResponse);
            return of(fetchCalendarAppointmentSuccess(arrFlat));
        }),
        catchError(error =>
            of(
                fetchCalendarAppointmentFailure({
                    error: error.message,
                })
            )
        )
    );

const mapSendEmailCode = (action, { apiRequest }) => {
    const payload = {
        ...action.payload,
        savingMode: 'CHECK_EMAIL',
    };
    return apiRequest({
        path: '/saveOrder',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.orderNumber) {
                log({
                    ...action.payload.logger,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/saveOrder - CHECK_EMAIL',
                    statusAction: 'SUCCESS',
                });
                return of(
                    sendEmailCodeRequestSuccess({
                        isCodeSent: true,
                    }),
                    saveOrderSuccess({
                        orderNumber: response.orderNumber,
                    })
                );
            }
            return throwError({
                message: response.message || response.errorMessage,
            });
        }),
        catchError(error => {
            log({
                ...action.payload.logger,
                returnCode: error.code,
                logMessage: error.message,
                serviceCalled: '/saveOrder - CHECK_EMAIL',
                statusAction: 'FAILURE',
            });
            return of(
                sendEmailCodeRequestFail({
                    isCodeSent: false,
                })
            );
        })
    );
};

const sendEmailCodeEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(SEND_EMAIL_CODE_REQUEST),
        mergeMap(action => mapSendEmailCode(action, dependency))
    );

const mapVerifyEmailCode = (action, { apiRequest }) => {
    const payload = {
        ...action.payload,
        savingMode: 'CHECK_CODE',
    };
    return apiRequest({
        path: '/saveOrder',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.orderNumber) {
                log({
                    ...action.payload.logger,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/saveOrder - CHECK_CODE',
                    statusAction: 'SUCCESS',
                });
                return of(
                    verifyEmailCodeRequestSuccess({
                        isValid: true,
                    })
                );
            }
            return throwError({
                message: response.message || response.errorMessage,
            });
        }),
        catchError(error => {
            log({
                ...action.payload.logger,
                returnCode: error.code,
                logMessage: error.message,
                serviceCalled: '/saveOrder - CHECK_CODE',
                statusAction: 'FAILURE',
            });
            return of(
                verifyEmailCodeRequestFail({
                    isValid: false,
                    error: error.message,
                })
            );
        })
    );
};

const verifyEmailCodeEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(VERIFY_EMAIL_CODE_REQUEST),
        mergeMap(action => mapVerifyEmailCode(action, dependency))
    );

const mapLogUserInformation = (action, { apiRequest }) => {
    const payload = {
        ...action.payload,
        dataOff: undefined,
    };
    return apiRequest({
        path: '/login',
        method: 'post',
        body: payload,
        urlOverride: config.URL_CS,
    }).pipe(
        mergeMap(response => {
            const message = response.message || response.errorMessage;
            const dataOff = action.payload.dataOff;
            const isFailCredential =
                message && message.includes('Incorrect username or password.');
            const isEmailNotFound =
                message && message.includes('User does not exist.');

            // This condition only check if the email address is known by the api
            if (dataOff && dataOff.isOnlyCheck) {
                if (isFailCredential) {
                    if (dataOff.onUserExist) dataOff.onUserExist();
                    return of(
                        logUserInformationRequestSuccess({ isUserExist: true })
                    );
                }
                if (dataOff.onUserNotExist) dataOff.onUserNotExist();
                return of(
                    logUserInformationRequestSuccess({ isUserExist: false }),
                    sendEmailCodeRequest({ order: dataOff.order })
                );
            }
            if (response.accessToken) {
                log({
                    ...action.payload.logger,
                    returnCode: '200',
                    logMessage: 'Log success',
                    serviceCalled: '/login',
                    statusAction: 'SUCCESS',
                });
                return of(
                    logUserInformationRequestSuccess(response),
                    saveOrderSuccess({
                        orderNumber: response.user,
                    })
                );
            }
            if (isFailCredential || isEmailNotFound)
                log({
                    ...action.payload.logger,
                    returnCode: '200',
                    logMessage: 'Fail to log with wrong user credential',
                    serviceCalled: '/login',
                    statusAction: 'SUCCESS',
                });

            if (isFailCredential) {
                return of(
                    logUserInformationRequestSuccess({
                        error: 'Combinaison email et mot de passe invalide.',
                    })
                );
            }
            if (isEmailNotFound) {
                return of(
                    logUserInformationRequestSuccess({
                        error: 'Utilisateur introuvable.',
                    })
                );
            }
            return throwError({
                message,
            });
        }),
        catchError(error => {
            log({
                ...action.payload.logger,
                returnCode: error.code,
                logMessage: error.message,
                serviceCalled: '/login',
                statusAction: 'FAILURE',
            });
            return of(
                logUserInformationRequestFail({
                    error: error.message,
                })
            );
        })
    );
};

const logUserInformationEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(LOG_USER_INFORMATION_REQUEST),
        mergeMap(action => mapLogUserInformation(action, dependency))
    );

const mapGetLoggedUserInfo = (action, { apiRequest }) => {
    const payload = {
        customerNbr: action.payload.customerNbr,
    };
    return apiRequest({
        path: '/getContext',
        method: 'post',
        body: payload,
        urlOverride: config.URL_CS,
        token: action.payload.token,
    }).pipe(
        mergeMap(response => {
            if (response.customer) {
                log({
                    ...action.payload.logger,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getContext - GET_INFO',
                    statusAction: 'SUCCESS',
                });
                return of(
                    getLoggedUserInfoRequestSuccess(response.customer),
                    saveOrderSuccess({
                        orderNumber: response.customer.customerNumber,
                    })
                );
            }
            return throwError({
                message: response.message || response.errorMessage,
            });
        }),
        catchError(error => {
            log({
                ...action.payload.logger,
                returnCode: error.code,
                logMessage: error.message,
                serviceCalled: '/getContext - GET_INFO',
                statusAction: 'FAILURE',
            });
            return of(
                getLoggedUserInfoRequestFail({
                    isCodeSent: false,
                })
            );
        })
    );
};

const getLoggedUserInfoEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(GET_LOGGED_USER_INFO_REQUEST),
        mergeMap(action => mapGetLoggedUserInfo(action, dependency))
    );

const dataScreenEpic = combineEpics(
    validateCalendarAppointementEpic,
    sendEmailCodeEpic,
    verifyEmailCodeEpic,
    logUserInformationEpic,
    getLoggedUserInfoEpic
);

export {
    validateDataScreenSuccess,
    validateDataScreenSuccessFinal,
    fetchCalendarAppointment,
    mapGetCalendarAndAppointment,
    fetchCalendarAppointmentSuccess,
    fetchCalendarAppointmentFailure,
    dataScreenSaveCommon,
    storeMinimumUserData,
    resetMinimumUserDataEmail,
    validateMinimumUserDataEmail,
    sendEmailCodeRequest,
    sendEmailCodeRequestFail,
    sendEmailCodeRequestSuccess,
    verifyEmailCodeRequest,
    verifyEmailCodeRequestFail,
    verifyEmailCodeRequestSuccess,
    logUserInformationRequest,
    logUserInformationRequestFail,
    logUserInformationRequestSuccess,
    getLoggedUserInfoRequest,
    getLoggedUserInfoRequestFail,
    getLoggedUserInfoRequestSuccess,
    dataScreenEpic,
};
