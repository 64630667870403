import React from 'react';

import WordingConstant from '../../utils/wording.json';
import Typography from '../Typography/Typography';
import { FontSizeVariant } from '../../constants/enums';

const Wording = WordingConstant.Common;

const PackageElement = ({
    title,
    price,
    quantityMonthly,
    description,
    chosen,
    recommended,
    onClick,
}) => {
    let className = 'package-element';
    if (recommended) {
        className += ' package-element--recommended';
    }
    if (chosen) {
        className += ' package-element--chosen';
    }
    return (
        <div
            className={className}
            onClick={onClick}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
        >
            {title && <p className="package-element__title">{title}</p>}
            <label htmlFor="idPrice" className="package-element__price">
                {quantityMonthly} kWh
            </label>
            <Typography
                className="package-element__subtitle"
                fontSize={FontSizeVariant.SMALL}
            >
                Prépaiement de <br />
                {price}
                {Wording.priceUnit}
                {Wording.priceCyclic}
            </Typography>
            {description && (
                <div className="package-element__description">
                    {description}
                </div>
            )}
            {recommended && (
                <div className="package-element__recommended">
                    Wekiwi te recommande ce forfait mensuel !
                </div>
            )}
        </div>
    );
};

export default PackageElement;
