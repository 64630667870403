import {
    PACKAGE_SCREEN_VALIDATE_SUCCESS,
    SUMMARY_SCREEN_VALIDATE_SUCCESS,
    SUMMARY_SCREEN_CHANGE_OFFER,
    SUMMARY_SCREEN_CHANGE_OPTION,
    SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS,
    SUMMARY_SCREEN_CHANGE_OPTION_FAILURE,
    SUMMARY_SCREEN_GET_SPONSOR,
    SUMMARY_SCREEN_GET_PARTNER,
    FINAL_SUMMARY_SCREEN_VALIDATE,
    FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS,
    FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES,
    FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES,
    CODE_SCREEN_VALIDATE_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    SURVEY_SCREEN_VALIDATE_SUCCESS,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    SURVEY_SCREEN_LOAD,
    CHECK_CONTRACT_PAYMENT_CB,
    MAIN_SCREEN_GET_ORDER,
    FINAL_CODE_SCREEN_VALIDATE_V2,
} from '../types';
import { removeFirstPaymentCBFromContract } from '../../utils/helpers';
import { Energy } from '../../constants/enums';

// options: list of product.productCode
// additionalOptions: list of additionalRate.label
const defaultValue = {
    offer: {},
    loadingOption: false,
    installmentFrequency: '',
    chosenPackages: [],
    packageID: '',
    cyclicBill: false,
    options: [],
    additionalOptions: [],
    error: null,
    billingModeCode: null,
};

export const initialState = {
    EL: { ...defaultValue },
    NG: { ...defaultValue },
    offers: [],
    products: [],
    contracts: [],
    advantage: {
        code: '',
        role: '',
    },
    partnerReference: undefined,
};

const recoverState = (state, { order, products, offers, packages }) => {
    const { contracts, partnerReference } = order;
    const modifiedState = state;
    contracts.forEach(contract => {
        const adtRates =
            contract.chosenProduct.additionalRates &&
            contract.chosenProduct.additionalRates
                .filter(
                    ({ active, type }) => active === true && type === 'OPTION'
                )
                .map(({ label }) => label);
        modifiedState[contract.energy].additionalOptions = adtRates;
        modifiedState[contract.energy].cyclicBill =
            contract.billingModeCode === 'CYCLICAL_BILLING';

        modifiedState[contract.energy].billingModeCode =
            contract.billingModeCode;
        modifiedState[contract.energy].chosenPackages = contract.chosenPackages;
        modifiedState[contract.energy].installmentFrequency =
            contract.installmentFrequency;
        const offer = offers.find(off => off.energy === contract.energy) || {};
        modifiedState[contract.energy].offer = offer;
        const pkg = packages.find(
            p => p.energy === contract.energy && p.recommended
        );
        modifiedState[contract.energy].packageID = (pkg || {}).id || '';
        modifiedState[contract.energy].options = contract.chosenProduct.default
            ? []
            : [contract.chosenProduct.productCode];
    });

    return {
        ...modifiedState,
        contracts,
        offers,
        products,
        partnerReference,
    };
};

function summaryReducer(state = initialState, action) {
    switch (action.type) {
        case MAIN_SCREEN_GET_ORDER: {
            return {
                ...state,
                ...initialState,
            };
        }
        case PACKAGE_SCREEN_VALIDATE_SUCCESS: {
            const { EL, NG, offers, products } = action.payload;

            return {
                ...state,
                products,
                offers,
                EL: {
                    ...state.EL,
                    ...EL,
                    options: [],
                    additionalOptions: [],
                    error: null,
                },
                NG: {
                    ...state.NG,
                    ...NG,
                    options: [],
                    additionalOptions: [],
                    error: null,
                },
            };
        }
        case SURVEY_SCREEN_LOAD:
        case CODE_SCREEN_VALIDATE_SUCCESS: {
            return {
                ...state,
                contracts: action.payload.contracts,
            };
        }

        case FINAL_CODE_SCREEN_VALIDATE_V2: {
            const { contracts, PCE, PDL } = action.payload;

            const modifiedContracts = contracts.map(ctr => ({
                ...ctr,

                deliveryPoint: {
                    ...ctr.deliveryPoint,
                    pointOfDelivery: ctr.energy === Energy.EL ? PDL : PCE,
                },
            }));

            return {
                ...state,
                contracts: modifiedContracts,
            };
        }
        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS: {
            const { contracts, packages } = action.payload;
            const modifiedContracts = contracts.map(contract => {
                const currentContract = state.contracts.find(
                    c => c.energy === contract.energy
                );

                const notNullEstimate = contract.estimates
                    ? contract.estimates.find(es => es.quantity > 0)
                    : false;

                const estimates = notNullEstimate
                    ? contract.estimates
                    : currentContract.estimates;
                return {
                    ...currentContract,
                    contractCategory: contract.contractCategory,
                    estimates,
                    deliveryPoint: contract.deliveryPoint,
                    rateOption: contract.rateOption,
                };
            });
            const res = {
                EL: { ...state.EL },
                NG: { ...state.NG },
            };
            if (packages) {
                res.EL.packageID =
                    (
                        packages.find(
                            p => p.energy === 'EL' && p.recommended
                        ) || {}
                    ).id || '';
                res.NG.packageID =
                    (
                        packages.find(
                            p => p.energy === 'NG' && p.recommended
                        ) || {}
                    ).id || '';
            }

            return {
                ...state,
                ...res,
                contracts: modifiedContracts,
            };
        }
        case SURVEY_SCREEN_VALIDATE_SUCCESS: {
            const { contracts } = action.payload;
            const modifiedContracts = contracts.map(contract => {
                const current = state.contracts.find(
                    c => c.energy === contract.energy
                );
                if (current === undefined) {
                    return contract;
                }
                const deliveryPoint = { ...current.deliveryPoint };
                if (contract.deliveryPoint) {
                    deliveryPoint.deliveryPointCategory =
                        contract.deliveryPoint.deliveryPointCategory;
                    deliveryPoint.gridRate = contract.deliveryPoint.gridRate;
                    deliveryPoint.profile = contract.deliveryPoint.profile;
                }
                let estimates = current.estimates;
                if (contract.estimates) {
                    estimates = contract.estimates.map(es => {
                        if (es.quantity === 0) {
                            return (
                                current.estimates.find(
                                    e => e.timeframe === es.timeframe
                                ) || es
                            );
                        }
                        return es;
                    });
                }
                return {
                    ...current,
                    deliveryPoint,
                    estimates,
                };
            });

            let controlContracts = [];

            if (
                action.payload.missingEnergy !== undefined &&
                action.payload.missingEnergy[0]
            ) {
                const contract = state.contracts.filter(
                    ctr => ctr.energy !== action.payload.missingEnergy[0]
                );

                controlContracts = [...contract, ...modifiedContracts];
            } else {
                controlContracts = [...modifiedContracts];
            }

            return {
                ...state,
                contracts: controlContracts,
            };
        }
        case SUMMARY_SCREEN_VALIDATE_SUCCESS: {
            const p = state;
            p.EL.error = null;
            p.NG.error = null;
            return {
                ...p,
            };
        }
        case SUMMARY_SCREEN_CHANGE_OFFER: {
            const p = state;
            const { offer, energyType, options } = action.payload;
            p[energyType].offer = offer;
            p[energyType].options = options;
            p[energyType].error = null;
            return {
                ...p,
            };
        }
        case FINAL_SUMMARY_SCREEN_CHANGE_PACKAGES: {
            const p = state;
            const { packages, packageID, energyType } = action.payload;
            p[energyType].chosenPackages = packages;
            p[energyType].packageID = packageID;
            return {
                ...p,
            };
        }
        case FINAL_SUMMARY_SCREEN_CHANGE_ADDITIONAL_RATES: {
            const p = state;
            const { additionalOptions, energyType } = action.payload;
            p[energyType].additionalOptions = additionalOptions;
            return {
                ...p,
            };
        }
        case SUMMARY_SCREEN_CHANGE_OPTION: {
            const p = state;
            const { energyType } = action.payload;
            p[energyType].loadingOption = true;
            p[energyType].error = null;
            return {
                ...p,
            };
        }
        case SUMMARY_SCREEN_CHANGE_OPTION_SUCCESS: {
            const p = state;
            const { energyType, offers } = action.payload;
            p[energyType].loadingOption = false;
            p[energyType].offer = action.payload[energyType].offer;
            p[energyType].options = [
                action.payload[energyType].offer.product.productCode,
            ];
            p[energyType].error = null;
            return {
                ...p,
                offers: [...state.offers, ...offers],
            };
        }
        case SUMMARY_SCREEN_CHANGE_OPTION_FAILURE: {
            const p = state;
            const { energyType, error } = action.payload;
            p[energyType].loadingOption = false;
            p[energyType].options = [];
            p[energyType].error = error;
            return {
                ...p,
            };
        }
        case SUMMARY_SCREEN_GET_SPONSOR: {
            return {
                ...state,
                advantage: {
                    code: action.payload.code,
                    role: action.payload.role,
                },
            };
        }
        case SUMMARY_SCREEN_GET_PARTNER: {
            return {
                ...state,
                partnerReference: action.payload.partnerReference,
            };
        }
        case FINAL_SUMMARY_SCREEN_VALIDATE: {
            return {
                ...state,
            };
        }
        case CHECK_CONTRACT_PAYMENT_CB: {
            const { dateContract, energyTypes } = action.payload;
            let isCB = false;

            let contracts = energyTypes.reduce((acc, current) => {
                const contract = state.contracts.find(
                    el => el.energy === current
                );

                if (!state[current].cyclicBill) {
                    const { firstPaymentCB } = dateContract[current];
                    isCB = false;
                    // firstPaymentCB !== undefined ? firstPaymentCB : false;

                    return isCB
                        ? [...acc, { ...contract, firstPaymentCB }]
                        : [...acc, removeFirstPaymentCBFromContract(contract)];
                }
                return [
                    ...acc,
                    { ...removeFirstPaymentCBFromContract(contract) },
                ];
            }, []);

            if (contracts.length === 2 && isCB) {
                contracts = contracts.map(contract =>
                    contract.billingModeCode !== 'CYCLICAL_BILLING'
                        ? {
                              ...contract,
                              firstPaymentCB: false,
                          }
                        : { ...contract }
                );
            }

            return { ...state, contracts };
        }
        case FINAL_SUMMARY_SCREEN_VALIDATE_SUCCESS: {
            return {
                ...state,
                advantage: action.payload.advantage,
            };
        }

        case MAIN_SCREEN_GET_ORDER_SUCCESS:
            return recoverState(state, action.payload);

        default:
            return state;
    }
}

export default summaryReducer;
