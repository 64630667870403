import { CodeScreenMode } from '../../constants/enums';
import {
    CODE_SCREEN_VALIDATE,
    CODE_SCREEN_VALIDATE_SUCCESS,
    CODE_SCREEN_VALIDATE_FAILURE,
    FINAL_CODE_SCREEN_VALIDATE,
    FINAL_CODE_SCREEN_VALIDATE_SUCCESS,
    FINAL_CODE_SCREEN_VALIDATE_FAILURE,
    CODE_SCREEN_RESET,
    SURVEY_SCREEN_LOAD,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    MAIN_SCREEN_GET_ORDER,
    FINAL_CODE_SCREEN_VALIDATE_V2,
    CODE_SCREEN_MODE_UPDATE,
    RESET_FULL_CODE_SCREEN,
} from '../types';

export const initialState = {
    PDL: '',
    PCE: '',
    hasNoCode: false,
    error: '',
    currentMode: CodeScreenMode.NONE,
};

function codeReducer(state = initialState, action) {
    switch (action.type) {
        case CODE_SCREEN_VALIDATE:
        case FINAL_CODE_SCREEN_VALIDATE:
            return {
                ...state,
            };

        case MAIN_SCREEN_GET_ORDER: {
            return {
                ...state,
                ...initialState,
            };
        }

        case RESET_FULL_CODE_SCREEN: {
            return {
                ...initialState,
            };
        }

        case CODE_SCREEN_RESET:
        case SURVEY_SCREEN_LOAD:
        case CODE_SCREEN_VALIDATE_SUCCESS:
        case FINAL_CODE_SCREEN_VALIDATE_V2:
        case FINAL_CODE_SCREEN_VALIDATE_SUCCESS:
            return {
                ...state,
                PDL: action.payload.PDL,
                PCE: action.payload.PCE,
                hasNoCode:
                    action.payload.PDL === '' && action.payload.PCE === '',
            };
        case CODE_SCREEN_VALIDATE_FAILURE:
        case FINAL_CODE_SCREEN_VALIDATE_FAILURE:
            return {
                ...state,
                error: '',
            };

        case CODE_SCREEN_MODE_UPDATE:
            const { mode } = action.payload;
            return {
                ...state,
                currentMode: mode,
            };

        case MAIN_SCREEN_GET_ORDER_SUCCESS:
            const { order } = action.payload;
            let PDL = '';
            let PCE = '';
            order.contracts.forEach(contract => {
                if (contract.energy === 'EL') {
                    PDL = (contract.deliveryPoint || {}).pointOfDelivery || '';
                } else {
                    PCE = (contract.deliveryPoint || {}).pointOfDelivery || '';
                }
            });
            return {
                ...state,
                PDL,
                PCE,
                hasNoCode: PDL === '' && PCE === '',
            };
        default:
            return state;
    }
}

export default codeReducer;
