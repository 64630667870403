import React from 'react';
import { Helmet } from 'react-helmet';

const GlobalTag = ({ productId, cartAmount, orderID }) => (
    <Helmet>
        <script type="text/javascript">
            {`var __dot = [{
                    'productID': '${productId}',
                    'cartAmount': ${JSON.stringify(cartAmount)},
                    'orderID': '${orderID}'
                }];`}
        </script>

        {/* <!-- TimeOne universal tag - DO NOT MODIFY --> */}
        <script type="text/javascript">
            {`(function(w,d,n,i){var e=d.createElement('script'),
                    s=d.getElementsByTagName('script')[0];e.type='text/javascript';e.async=true;
                    e.src=(d.location.protocol=='http:'?'http':'https')+'://u.logbor.com/p/?i='+i+'&n='+n;
                    s.parentNode.insertBefore(e,s);})(window,document,'__dot','3514');`}
        </script>
        {/* <!-- TimeOne universal tag end --> */}
    </Helmet>
);

export const ConversionTag = ({ transactionId, price, additionalData }) => (
    <Helmet>
        <script type="text/javascript">
            {`var tip = tip || [];
            tip.push(["_setSale","7719","1563688","6b1dd9aa7e9448ab66c7875ca6a64eb8",
                /* To change >> start */
                '${transactionId}',        // transaction ID - required
                '${price}',        // total : does not include tax and shipping - required
                '${additionalData}'   // additional data
                /* To change << end */
            ]);
            (function(e){function t(){var e=document.createElement("script");e.type="text/javascript";e.async=true;e.src=(document.location.protocol=="https:"?"https":"http")+"://tracking.publicidees.com/p/tip/";
                var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}if(document.readyState=="complete"){t()}else{if(e.addEventListener){e.addEventListener("load",t,false)}else if(e.attachEvent){e.attachEvent("onload",t)}}})(window)`}
        </script>
    </Helmet>
);

export default GlobalTag;
