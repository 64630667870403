/* eslint-disable spaced-comment */
import React, { useEffect, useState } from 'react';
import './_CustomSelector.scss';
import CheckIcon from '../../assets/icons/ic-check.svg';

export default function CustomSelector({
    options = [],
    onSelect,
    value,
    defaultId,
    vertical = false,
    onMounSetter = false,
    disabled,
    className,
    gap = '10px',
    style = {},
    notFullWidth = false,
}) {
    const [currentChecked, setCurrentChecked] = useState(defaultId);
    const [optionState, setOptionState] = useState(options);
    const handleCheck = (id, selectedValue) => () => {
        if (onSelect) onSelect(id, selectedValue);
        setCurrentChecked(id);
    };
    const or = vertical ? 'V' : 'H';
    const controlledValue = value !== undefined ? value : currentChecked;

    useEffect(() => {
        if (onMounSetter && onSelect)
            onSelect(
                defaultId,
                options.find(option => option.id === defaultId).value
            );
    }, []);

    useEffect(() => {
        setOptionState(options);
    }, [options]);

    useEffect(() => {
        setCurrentChecked(defaultId);
    }, [defaultId]);

    return (
        <div
            className={`custom-selector-root-${or} ${className}`}
            style={{ gap, ...style }}
        >
            {optionState.map(it => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: !notFullWidth && '100%',
                        alignItems: 'flex-start',
                    }}
                    key={it.id}
                >
                    <div className={`custom-selector-root-${or}__item`}>
                        <button
                            onClick={handleCheck(it.id, it.value)}
                            type="button"
                            className={`custom-selector-root-${or}__item__input ${
                                controlledValue === it.id ||
                                controlledValue === it.value
                                    ? 'custom-selector-check'
                                    : ''
                            } ${it.disabled ? 'disabled' : ''}`}
                            disabled={disabled || it.disabled}
                        >
                            <img alt="check icon" src={CheckIcon} />
                        </button>
                        <label
                            className={`custom-selector-root-${or}__item__label ${
                                it.disabled ? 'label-disabled' : ''
                            }`}
                            htmlFor={it.id}
                        >
                            {it.label}
                        </label>
                    </div>
                    {it.fee && (
                        <p className="custom-selector-subtitle">{it.fee}</p>
                    )}
                </div>
            ))}
        </div>
    );
}
