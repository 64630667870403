import { Subject, of, from, throwError } from 'rxjs';
import {
    debounceTime,
    switchMap,
    distinctUntilChanged,
    mergeMap,
    catchError,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import Config from './config';
import { filteredArrNoDuplicates } from './helpers';
import { log } from './logger';

class SearchService {
    constructor(tagLogger) {
        this.handleSearch$ = new Subject().pipe(debounceTime(100));
        this.tagLogger = tagLogger;
    }

    unsubscribe = () => {
        this.handleSearch$.unsubscribe();
    };

    search = term => {
        this.handleSearch$.next(term);
    };

    doSearch = term => {
        const settings = {
            url: `${Config.URL}/getTowns`,
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': Config.ApiKey,
            },
            responseType: 'json',
            method: 'post',
            crossDomain: true,
            body: {
                postalCode: term,
            },
        };
        const promise = ajax(settings).pipe(
            catchError(({ message, code }) => {
                log({
                    tag: this.tagLogger,
                    returnCode: code,
                    logMessage: message,
                    serviceCalled: '/getTowns',
                    statusAction: 'FAILURE',
                    triggerButton: 'postal-code-input',
                });
                return throwError({ error: message });
            }),
            mergeMap(response => {
                if (response.response) {
                    response.response = filteredArrNoDuplicates(
                        response.response,
                        'townName'
                    );

                    response.response.sort((a, b) => {
                        if (a.townName < b.townName) {
                            return -1;
                        }
                        if (a.townName > b.townName) {
                            return 1;
                        }
                        return 0;
                    });

                    log({
                        tag: this.tagLogger,
                        returnCode: '200',
                        logMessage: null,
                        serviceCalled: '/getTowns',
                        statusAction: 'SUCCESS',
                        triggerButton: 'postal-code-input',
                    });

                    return of(
                        response.response.map(res => ({
                            code: res.postalCode,
                            city: res.townName,
                            netArea: res.netArea,
                        }))
                    );
                }
                return of([]);
            })
        );
        return from(promise);
    };

    getResults() {
        return this.handleSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(term => (term ? this.doSearch(term) : of([]))),
            catchError(() => of([]))
        );
    }
}

export default SearchService;
