import React from 'react';
import { ImageElement } from '../../components';

import resources from '../../utils/resources';
import { areListEqual, sendImage } from '../../utils/helpers';

import gasLogoOn from '../../assets/icons/ic-gas-on.svg';
import elcLogoOn from '../../assets/icons/ic-elec-on.svg';
import gasLogoOff from '../../assets/icons/ic-gas-off.svg';
import elcLogoOff from '../../assets/icons/ic-elec-off.svg';

const bothLogo = [
    { on: gasLogoOn, off: gasLogoOff },
    { on: elcLogoOn, off: elcLogoOff },
];

const Custom = ({ text, isChecked }) => (
    <div
        className=""
        style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            margin: '0px',
            gap: '4px',
        }}
    >
        {text.map((txt, i) => (
            <div
                key={txt}
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: '130px',
                    }}
                >
                    <img
                        className="image-element__container__image"
                        src={isChecked ? bothLogo[i].on : bothLogo[i].off}
                        alt="icon"
                    />
                    <p
                        className="image-element__container__text"
                        style={{ margin: 0, padding: 0, marginLeft: '10px' }}
                    >
                        {txt}
                    </p>
                </div>
            </div>
        ))}
    </div>
);

const UserContainer = ({ imageList, choiceType, onClick }) => (
    <div className="user-container">
        {imageList &&
            imageList.map(
                ({ text, value, imageSrc, imageSrcOn, isCustom }, index) => (
                    <ImageElement
                        key={index}
                        isChecked={
                            typeof choiceType === 'string'
                                ? choiceType === value
                                : areListEqual(choiceType, value)
                        }
                        comp={
                            isCustom ? (
                                <Custom
                                    text={text}
                                    isChecked={
                                        typeof choiceType === 'string'
                                            ? choiceType === value
                                            : areListEqual(choiceType, value)
                                    }
                                />
                            ) : (
                                undefined
                            )
                        }
                        text={text}
                        imageSrc={
                            resources[
                                sendImage(
                                    choiceType,
                                    value,
                                    imageSrcOn,
                                    imageSrc
                                )
                            ]
                        }
                        onClick={() => onClick(value)}
                    />
                )
            )}
    </div>
);

export default UserContainer;
