import {
    USER_SCREEN_VALIDATE_SUCCESS,
    USER_SCREEN_RESET_RECOVER,
} from '../types';

const validateUserScreenSuccess = payload => ({
    type: USER_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const resetRecover = payload => ({
    type: USER_SCREEN_RESET_RECOVER,
    payload,
});

export { validateUserScreenSuccess, resetRecover };
