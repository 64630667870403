import React from 'react';
import { Motion, spring } from 'react-motion';
import PropTypes from 'prop-types';

import Events from './Events';
import EventsLine from './EventsLine';

const EventsBar = ({
    index,
    events,
    totalWidth,
    barPaddingLeft,
    barPaddingRight,
    width,
    height,
    position,
    fillingMotion,
    slidingMotion,
    indexClick,
    labelWidth,
    styles,
}) => {
    const filledValue = events[index].distance - barPaddingLeft;
    const eventLineWidth = totalWidth - barPaddingLeft - barPaddingRight;

    return (
        <div
            style={{
                width,
                height,
            }}
        >
            <div
                className="events-wrapper"
                style={{
                    position: 'relative',
                    height: '100%',
                    margin: '0',
                    overflow: 'hidden',
                }}
            >
                <Motion
                    style={{
                        X: spring(position, slidingMotion),
                    }}
                >
                    {({ X }) => (
                        <div
                            className="events"
                            style={{
                                position: 'absolute',
                                top: 12,
                                left: -10,
                                height: 2,
                                width: totalWidth,
                                WebkitTransform: `translate3d(${X}, 0, 0)px`,
                                transform: `translate3d(${X}px, 0, 0)`,
                            }}
                        >
                            <EventsLine
                                left={barPaddingLeft}
                                width={eventLineWidth}
                                fillingMotion={fillingMotion}
                                backgroundColor={styles.outline}
                                dashed
                            />
                            <EventsLine
                                left={barPaddingLeft}
                                width={filledValue}
                                fillingMotion={fillingMotion}
                                backgroundColor={styles.foreground}
                                dashed={false}
                            />
                            <Events
                                events={events}
                                selectedIndex={index}
                                styles={styles}
                                handleDateClick={indexClick}
                                labelWidth={labelWidth}
                            />
                        </div>
                    )}
                </Motion>
            </div>
        </div>
    );
};

EventsBar.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(
        PropTypes.shape({
            distance: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
        })
    ).isRequired,
    totalWidth: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    styles: PropTypes.shape({}).isRequired,
    indexClick: PropTypes.func.isRequired,
    labelWidth: PropTypes.number.isRequired,
    fillingMotion: PropTypes.shape({}).isRequired,
    barPaddingRight: PropTypes.number.isRequired,
    barPaddingLeft: PropTypes.number.isRequired,
};

export default EventsBar;
