import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';

import { codeScreenEpic } from './screens/CodeScreen/CodeScreen.actions';
import { surveyScreenEpic } from './screens/SurveyScreen/SurveyScreen.actions';
import { packageScreenEpic } from './screens/PackageScreen/PackageScreen.actions';
import { summaryScreenEpic } from './screens/SummaryScreen/SummaryScreen.actions';
import { dataScreenEpic } from './screens/DataScreen/DataScreen.actions';
import { finalSummaryScreenEpic } from './screens/FinalSummaryScreen/FinalSummaryScreen.actions';
import { finalPaymentScreenEpic } from './screens/FinalPaymentScreen/FinalPaymentScreen.action';
import { paymentModalEpic } from './containers/PaymentModal/PaymentModal.actions';
import { paymentCBScreenEpic } from './containers/PaymentModal/PaymentCBModal.actions';
import { mainScreenEpic } from './screens/MainScreen/MainScreen.actions';

import mainReducer from './screens/MainScreen/MainScreen.reducer';
import userReducer from './screens/UserScreen/UserScreen.reducer';
import codeReducer from './screens/CodeScreen/CodeScreen.reducer';
import surveyReducer from './screens/SurveyScreen/SurveyScreen.reducer';
import packageReducer from './screens/PackageScreen/PackageScreen.reducer';
import summaryReducer from './screens/SummaryScreen/SummaryScreen.reducer';
import dataReducer from './screens/DataScreen/DataScreen.reducer';
import paymentReducer from './containers/PaymentModal/PaymentModal.reducer';

export const rootEpic = combineEpics(
    codeScreenEpic,
    surveyScreenEpic,
    packageScreenEpic,
    summaryScreenEpic,
    dataScreenEpic,
    finalSummaryScreenEpic,
    finalPaymentScreenEpic,
    paymentModalEpic,
    mainScreenEpic,
    paymentCBScreenEpic
);

export const rootReducer = combineReducers({
    mainReducer,
    userReducer,
    codeReducer,
    surveyReducer,
    packageReducer,
    dataReducer,
    summaryReducer,
    paymentReducer,
});
