import React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import moment from 'moment';

import MomentLocaleUtils, {
    parseDate,
    formatDate,
} from 'react-day-picker/moment';
import 'moment/locale/fr';

const currentYear = new Date().getFullYear();

const FROM_MONTH = 120;
const TO_MONTH = 18;
const DEFAULT = currentYear - 1975;
export const fromMonth_ = new Date(currentYear - FROM_MONTH, 0);
export const toMonth_ = new Date(currentYear - TO_MONTH, 11);

export const renderValueDate = date => {
    const pDate = new Date(date);
    return pDate.getFullYear() === currentYear - FROM_MONTH
        ? new Date(currentYear - DEFAULT, pDate.getMonth(), pDate.getDate())
        : pDate;
};

export const YearMonthForm = ({ date, onChange }) => {
    moment.locale('fr');
    const months = moment.months();
    const years = [];
    for (
        let i = fromMonth_.getFullYear();
        i <= toMonth_.getFullYear();
        i += 1
    ) {
        years.push(i);
    }

    years.shift();

    const handleChange_ = function(e) {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    return (
        <form className="DayPicker-Caption">
            <select
                name="month"
                onChange={handleChange_}
                value={date.getMonth()}
            >
                {months.map((month, i) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select
                name="year"
                onChange={handleChange_}
                value={date.getFullYear()}
            >
                {years.map(year => (
                    <option key={year} value={year.toString()}>
                        {year}
                    </option>
                ))}
            </select>
        </form>
    );
};

const modifiersStyles = {
    highlighted: {
        color: 'white',
        backgroundColor: '#6f49ce',
    },

    outside: { color: '#8954ba', backgroundColor: 'white' },
};

class CustomDate extends React.Component {
    constructor(props) {
        super(props);
        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.state = {
            month_: this.renderStateFromMonth(),
        };
    }

    handleYearMonthChange(month) {
        this.setState({ month_: month });
    }

    renderStateFromMonth = () =>
        fromMonth_.getFullYear() === currentYear - 80 // 1939
            ? new Date(currentYear - DEFAULT, 1, 1)
            : fromMonth_;

    renderDate = (date, value) =>
        value === '' && date.getFullYear() === currentYear - FROM_MONTH
            ? new Date(currentYear - DEFAULT, 0, 1)
            : date;

    render() {
        const {
            onDateChange,
            value,
            maxDate,
            text,
            isInterrogationVisible,
            onToggleModal,
            error,
            styleV2 = false,
        } = this.props;
        const { month_ } = this.state;

        return (
            <div className={`custom-date ${styleV2 && 'date-styleV2'}`}>
                {text && !styleV2 && (
                    <label htmlFor={`id${text}`}>
                        {text}
                        {isInterrogationVisible && (
                            <span
                                className="custom-input__layer"
                                onClick={onToggleModal}
                                onKeyDown={onToggleModal}
                                role="button"
                                tabIndex="0"
                            >
                                ?
                            </span>
                        )}
                    </label>
                )}

                <div
                    className={`custom-date__container ${styleV2 &&
                        'date-styleV2'}`}
                >
                    <DayPickerInput
                        onDayChange={onDateChange}
                        placeholder={styleV2 ? text : 'JJ/MM/AAAA'}
                        format="DD/MM/YYYY"
                        value={
                            value !== '' ? renderValueDate(value) : undefined
                        }
                        parseDate={parseDate}
                        formatDate={formatDate}
                        keepFocus={false}
                        dayPickerProps={{
                            modifiers: {
                                highlighted: new Date(value),
                            },
                            modifiersStyles,
                            locale: 'fr',
                            month: month_,
                            fromMonth: fromMonth_,
                            toMonth: toMonth_,

                            localeUtils: MomentLocaleUtils,
                            disabledDays: {
                                after: new Date(maxDate || ''),
                            },

                            captionElement: ({ date }) => (
                                <YearMonthForm
                                    date={this.renderDate(date, value)}
                                    locale="fr"
                                    onChange={this.handleYearMonthChange}
                                />
                            ),
                        }}
                    />
                    {error && error !== '' && (
                        <p className="custom-input__div__error">{error}</p>
                    )}
                </div>
            </div>
        );
    }
}

export default CustomDate;
