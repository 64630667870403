import React from 'react';

const EnergyDetail = ({ sections }) => (
    <div className="energy-detail">
        {sections &&
            sections.map(({ details }, i) => (
                <div
                    className="energy-detail__section"
                    key={`energyDetail${i}`}
                >
                    {details.map(({ title, value }, index) => {
                        if (value)
                            return (
                                <p
                                    key={index}
                                    className="energy-detail__item__title"
                                >
                                    {title}{' '}
                                    <span className="energy-detail__item__value">
                                        {value}
                                    </span>
                                </p>
                            );
                        return null;
                    })}
                </div>
            ))}
    </div>
);

export default EnergyDetail;
