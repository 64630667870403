export const BillingMode = Object.freeze({
    PAYMENT_SCHEDULE: 'PAYMENT_SCHEDULE',
    CYCLICAL_BILLING: 'CYCLICAL_BILLING',
    PAYMENT_SCHEDULE_WITHOUT_PACK: 'PAYMENT_SCHEDULE_WITHOUT_PACK',
});

export const Energy = Object.freeze({
    EL: 'EL',
    NG: 'NG',
});

export const SwitchSituation = Object.freeze({
    NOW: 'NOW',
    LATER: 'LATER',
});

export const SituationTypes = Object.freeze({
    MOVE_IN: 'MOVE_IN',
    SWITCH: 'SWITCH',
});

export const FontSizeVariant = Object.freeze({
    XTRA_SMALL: 'XTRA_SMALL',
    SMALL: 'SMALL',
    NORMAL: 'NORMAL',
    TITLE: 'TITLE',
    SUPRA: 'SUPRA',
});

export const FontWeightVariant = Object.freeze({
    NORMAL: 'NORMAL',
    '500': '500',
    '700': '700',
});

export const FontFamilyVariant = Object.freeze({
    GOWUN: 'GOWUN',
    LEXEND: 'LEXEND',
});

export const TypoVariant = Object.freeze({
    PARAGRAPH: 'PARAGRAPH',
    SPAN: 'SPAN',
    H1: 'H1',
    H2: 'H2',
    H3: 'H3',
    H4: 'H4',
    H5: 'H5',
    H6: 'H6',
});

export const SectionsSteps = Object.freeze({
    STEP_ONE_ENERGY_SELECTOR: 0,
    STEP_TOW_USER_VALIDATION: 1,
    STEP_THREE_PDL_PCE_IDENTIFIER: 2,
    STEP_FOUR_PACKAGE_SELECTOR: 3,
    STEP_FIVE_BUDGET_ESTIMATION: 4,
    STEP_SIX_USER_HABITATION: 5,
    STEP_SEVEN_USER_SITUATION: 6,
    STEP_HEIGHT_FINAL_RECAP: 7,
    STEP_NINE_SIGNATURE: 8,
    STEP_TEN_SAVE_DRAFT: 9,
    FAILURE_SCREEN: 10,
    FINAL_SCREEN: 11,
    FAILURESC_SCREEN: 12,
    CANCELSC_SCREEN: 13,
    STEP_PAYMENT: 14,
});

export const CodeScreenMode = Object.freeze({
    NONE: 'NONE',
    OFFERS: 'OFFERS',
    SIMULATION: 'SIMULATION',
    END: 'END',
});
